import { graphConfig } from 'app/config/authConfig'

import { type Users } from '../model/contracts/graph/graph'

import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

export class GraphApi {
  async getUserPhoto (userId: string) {
    const token = await authService.getClientToken()

    return await msalFetch()
      .get(`${graphConfig.graphUsersEndpoint}/${userId}/photo/$value`, token.accessToken)
      .then(handleErrors)
  }

  async findUsers (value: string) {
    const token = await authService.getClientToken()

    const headers = new Headers()
    headers.append('ConsistencyLevel', 'eventual')

    return await msalFetch()
      .get(
        `${graphConfig.graphUsersEndpoint}?$search="displayName:${value}" OR "mail:${value}"&$orderby=displayName`,
        token.accessToken,
        undefined,
        headers,
      )
      .then(handleErrors)
      .then<Users>(async (res) => await res.json())
  }
}

const graphApi = new GraphApi()

export default graphApi

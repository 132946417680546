import {
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import { type RootState } from '../store'

export interface UnitsFilterState {
  status: 'idle' | 'loading' | 'failed'
  unitFilter?: string
  mfg: boolean
  error?: SerializedError
}

const initialState: UnitsFilterState = {
  status: 'idle',
  mfg: false,
}

export const unitsFilterSlice = createSlice({
  name: 'units-list-filters',
  initialState,
  reducers: {
    setUnitFilter: (state, action: PayloadAction<string | undefined>) => {
      state.unitFilter = action.payload
    },
    clearAllFilters: (state) => {
      state.unitFilter = undefined
    },
    setMfgSearch: (state, action: PayloadAction<boolean>) => {
      state.mfg = action.payload
    },
  },
})

export const {
  setUnitFilter, clearAllFilters, setMfgSearch,
} = unitsFilterSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUnitsFilters = (state: RootState) =>
  state.units.list.filters

export default unitsFilterSlice.reducer

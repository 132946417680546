export const mfgArticleSearch = 'Search articles by MFG or Production name'
export const commonArticleSearch = 'Search articles by Element ID, Production ID or OEM Article'
export const mfgSyntaxHelperText =
               'Search MFG is on\n' +
               'Use ";" as separator\nSyntax:\n' +
               '"%" matches a sequence of characters\n' +
               '"_" matches a single character\n' +
               '"[]" matches a single character in brackets\n' +
               '"[-]" matches a single character in bracket rangen\n' +
               '"[^]" matches a single character not present in brackets\n' +
               '"\\" escape character'
export const commonSyntaxHelperText =
               'Search articles is on\n' +
               'Use ";" and "space" as separators'

import { Button, CircularProgress } from '@mui/material'
import { type Theme } from '@mui/material/styles/createTheme'
import { Box, type SxProps } from '@mui/system'
import { type FC, useEffect, useState } from 'react'

import { baseTheme } from 'app/config/theme/baseTheme'
import {
  getReport,
  selectUnitsFilters,
  selectUnitsReport,
  useAppDispatch,
  useAppSelector,
} from 'app/store'

import { LocalStorageEndDate, LocalStorageStartDate } from 'shared/lib/localStorageVariables'
import { useFeatureSlicedDebug } from 'shared/lib/useFeatureSlicedDebug'
import { useLocalStorage } from 'shared/lib/useLocalStorage'
import { DownloadFileIcon } from 'shared/ui/Icons/DownloadFileIcon'

interface DownloadReportProps {
  id: string
  name?: string
  sx?: SxProps<Theme>
}

export const DownloadReport: FC<DownloadReportProps> = ({
  id,
  name,
  sx,
}) => {
  const {
    rootAttributes,
  } = useFeatureSlicedDebug(`feature/DownloadReport/${name}`)

  const [blob, setBlob] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const filtersState = useAppSelector(selectUnitsFilters)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (blob) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = blob
      a.download = `${name}.xlsx`
      a.click()
      document.body.removeChild(a)
      setBlob(undefined)
    }
  }, [blob])

  const [localStorageStartDate] = useLocalStorage(LocalStorageStartDate, '')
  const [localStorageEndDate] = useLocalStorage(LocalStorageEndDate, '')

  const handleDownloadReport = async () => {
    setLoading(true)
    const blobStr = await dispatch(getReport({
      filter: filtersState.unitFilter ?? '',
      type: id,
      startDate: localStorageStartDate,
      endDate: localStorageEndDate,
    })).unwrap().catch(() => {
      setLoading(false)
    }).finally(() => {
      setLoading(false)
    })

    if (blobStr) {
      setBlob(blobStr)
    }
  }

  return (
    <Box
      {...rootAttributes}
      sx={{
        [baseTheme.breakpoints.down(baseTheme.breakpoints.values.md)]: { width: '100%' },
        [baseTheme.breakpoints.up(baseTheme.breakpoints.values.md)]: { width: 'inherit' },
      }}
    >
      <Button
        fullWidth
        data-testid="download-report"
        variant="outlined"
        disabled={loading}
        startIcon={
          loading
            ? (
              <CircularProgress size={16}/>
            )
            : (
              <DownloadFileIcon/>
            )
        }
        onClick={handleDownloadReport}
        sx={{
          height: '40px',
          ...sx,
        }}
      >
        {name}
      </Button>
    </Box>
  )
}

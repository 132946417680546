import { z } from "zod";

const ApplicationInformationResponse = z
  .object({ currentBuild: z.string().nullable() })
  .partial();
const SchemeDto = z
  .object({
    id: z.number().int(),
    name: z.string().nullable(),
  })
  .partial();
const EquipmentTypeDto = z
  .object({
    id: z.number().int(),
    value: z.string().nullable(),
  })
  .partial();
const AddEquipmentTypeRequest = z
  .object({ value: z.string().nullable() })
  .partial();
const UpdateEquipmentTypeRequest = z
  .object({ value: z.string().nullable() })
  .partial();
const ManufacturingMethodDto = z
  .object({
    id: z.number().int(),
    value: z.string().nullable(),
  })
  .partial();
const AddManufacturingMethodRequest = z
  .object({ value: z.string().nullable() })
  .partial();
const UpdateManufacturingMethodRequest = z
  .object({ value: z.string().nullable() })
  .partial();
const MaterialDto = z
  .object({
    id: z.number().int(),
    value: z.string().nullable(),
  })
  .partial();
const AddMaterialRequest = z.object({ value: z.string().nullable() }).partial();
const UpdateMaterialRequest = z
  .object({ value: z.string().nullable() })
  .partial();
const PrincipleDto = z
  .object({
    id: z.number().int(),
    value: z.string().nullable(),
  })
  .partial();
const AddPrincipleRequest = z
  .object({ value: z.string().nullable() })
  .partial();
const UpdatePrincipleRequest = z
  .object({ value: z.string().nullable() })
  .partial();
const PurposeDto = z
  .object({
    id: z.number().int(),
    value: z.string().nullable(),
  })
  .partial();
const AddPurposeRequest = z.object({ value: z.string().nullable() }).partial();
const UpdatePurposeRequest = z
  .object({ value: z.string().nullable() })
  .partial();
const PurchaseOrder = z
  .object({
    id: z.number().int(),
    number: z.string().nullable(),
  })
  .partial();
const QualityImageItem = z
  .object({
    documentId: z.number().int(),
    token: z.string().nullable(),
    purchaseOrder: PurchaseOrder,
  })
  .partial();
const QualityImageItemPaginatedResponse = z
  .object({
    page: z.number().int(),
    pages: z.number().int(),
    count: z.number().int(),
    items: z.array(QualityImageItem).nullable(),
  })
  .partial();
const ChildUnitDto = z
  .object({
    id: z.number().int(),
    elementId: z.string().nullable(),
    elementName: z.string().nullable(),
    elementNameRus: z.string().nullable(),
    quantity: z.number().int(),
  })
  .partial();
const ImageDto = z
  .object({
    id: z.number().int(),
    name: z.string().nullable(),
  })
  .partial();
const LogisticUnitDto = z
  .object({
    unitId: z.number().int(),
    elementId: z.string().nullable(),
    elementName: z.string().nullable(),
    elementNameRus: z.string().nullable(),
    oemArcticleName: z.string().nullable(),
    position: z.string().nullable(),
    purpose: z.string().nullable(),
    comment: z.string().nullable(),
    state: z.string().nullable(),
    equipmentType: EquipmentTypeDto.nullable(),
    content: z.array(ChildUnitDto).nullable(),
    images: z.array(ImageDto).nullable(),
    schemes: z.array(SchemeDto).nullable(),
    manufacturingMethods: z.array(ManufacturingMethodDto).nullable(),
    materials: z.array(MaterialDto).nullable(),
    principles: z.array(PrincipleDto).nullable(),
  })
  .partial();
const GetUnitsRequest = z
  .object({ unitIds: z.array(z.number()).nullable() })
  .partial();
const ChangeUnitStateRequest = z
  .object({ state: z.string().nullable() })
  .partial();
const Error = z
  .object({
    code: z.string().nullable(),
    message: z.string().nullable(),
    stackTrace: z.string().nullable(),
  })
  .partial();
const ChangeUnitStateResponseBody = z
  .object({
    unitId: z.number().int(),
    state: z.string().nullable(),
  })
  .partial();
const ChangeUnitStateResponse = z
  .object({
    success: z.boolean(),
    error: Error,
    body: ChangeUnitStateResponseBody,
  })
  .partial();
const AddEquipmentTypeToUnitRequest = z
  .object({ equipmentTypeId: z.number().int() })
  .partial();
const AddEquipmentTypeToUnitResponse = z
  .object({
    unitId: z.number().int(),
    equipmentTypesIds: z.array(z.number()).nullable(),
  })
  .partial();
const AddManufacturingMethodsToUnitRequest = z
  .object({ manufacturingMethodsIds: z.array(z.number()).nullable() })
  .partial();
const AddManufacturingMethodsToUnitResponse = z
  .object({
    unitId: z.number().int(),
    manufacturingMethodsIds: z.array(z.number()).nullable(),
  })
  .partial();
const AddMaterailsToUnitRequest = z
  .object({ materialIds: z.array(z.number()).nullable() })
  .partial();
const AddMaterialsToUnitResponse = z
  .object({
    unitId: z.number().int(),
    materialsIds: z.array(z.number()).nullable(),
  })
  .partial();
const AddPurposesToUnitRequest = z
  .object({ purpose: z.string().nullable() })
  .partial();
const AddPurposesToUnitResponse = z
  .object({
    unitId: z.number().int(),
    purpose: z.string().nullable(),
  })
  .partial();
const AddPrinciplesToUnitRequest = z
  .object({ principleIds: z.array(z.number()).nullable() })
  .partial();
const AddPrinciplesToUnitResponse = z
  .object({
    unitId: z.number().int(),
    principlesIds: z.array(z.number()).nullable(),
  })
  .partial();
const UnitImagesResponse = z
  .object({
    unitId: z.number().int(),
    images: z.array(ImageDto).nullable(),
  })
  .partial();
const UnitSchemesResponse = z
  .object({
    unitId: z.number().int(),
    schemes: z.array(SchemeDto).nullable(),
  })
  .partial();
const SetPositionToUnitRequest = z
  .object({ position: z.string().nullable() })
  .partial();
const AddPositionToUnitResponse = z
  .object({
    unitId: z.number().int(),
    position: z.string().nullable(),
  })
  .partial();
const SetCommentToUnitRequest = z
  .object({ comment: z.string().nullable() })
  .partial();
const AddCommentToUnitResponse = z
  .object({
    unitId: z.number().int(),
    comment: z.string().nullable(),
  })
  .partial();
const UnitMaterialsReplicationRequest = z
  .object({ replicate: z.boolean() })
  .partial();
const UnitManufacturingMethodsReplicationRequest = z
  .object({ replicate: z.boolean() })
  .partial();
const UnitPurposesReplicationRequest = z
  .object({ replicate: z.boolean() })
  .partial();
const UnitPositionReplicationRequest = z
  .object({ replicate: z.boolean() })
  .partial();
const UnitEquipmentTypesReplicationRequest = z
  .object({ replicate: z.boolean() })
  .partial();
const UnitImagesReplicationRequest = z
  .object({ replicate: z.boolean() })
  .partial();
const UnitSchemesReplicationRequest = z
  .object({ replicate: z.boolean() })
  .partial();
const UnitReplicationRequest = z
  .object({
    unitId: z.number().int(),
    materialsReplicationRequest: UnitMaterialsReplicationRequest,
    manufacturingMethodsRequest: UnitManufacturingMethodsReplicationRequest,
    purposesRequest: UnitPurposesReplicationRequest,
    positionRequest: UnitPositionReplicationRequest,
    equipmentTypesRequest: UnitEquipmentTypesReplicationRequest,
    imagesRequest: UnitImagesReplicationRequest,
    schemesRequest: UnitSchemesReplicationRequest,
  })
  .partial();
const MultiUnitReplicationRequest = z
  .object({
    unitIds: z.array(z.number()).nullable(),
    replicationUnitId: z.number().int(),
    materialsReplicationRequest: UnitMaterialsReplicationRequest,
    manufacturingMethodsRequest: UnitManufacturingMethodsReplicationRequest,
    purposesRequest: UnitPurposesReplicationRequest,
    positionRequest: UnitPositionReplicationRequest,
    equipmentTypesRequest: UnitEquipmentTypesReplicationRequest,
    imagesRequest: UnitImagesReplicationRequest,
    schemesRequest: UnitSchemesReplicationRequest,
  })
  .partial();

export const schemas = {
  ApplicationInformationResponse,
  SchemeDto,
  EquipmentTypeDto,
  AddEquipmentTypeRequest,
  UpdateEquipmentTypeRequest,
  ManufacturingMethodDto,
  AddManufacturingMethodRequest,
  UpdateManufacturingMethodRequest,
  MaterialDto,
  AddMaterialRequest,
  UpdateMaterialRequest,
  PrincipleDto,
  AddPrincipleRequest,
  UpdatePrincipleRequest,
  PurposeDto,
  AddPurposeRequest,
  UpdatePurposeRequest,
  PurchaseOrder,
  QualityImageItem,
  QualityImageItemPaginatedResponse,
  ChildUnitDto,
  ImageDto,
  LogisticUnitDto,
  GetUnitsRequest,
  ChangeUnitStateRequest,
  Error,
  ChangeUnitStateResponseBody,
  ChangeUnitStateResponse,
  AddEquipmentTypeToUnitRequest,
  AddEquipmentTypeToUnitResponse,
  AddManufacturingMethodsToUnitRequest,
  AddManufacturingMethodsToUnitResponse,
  AddMaterailsToUnitRequest,
  AddMaterialsToUnitResponse,
  AddPurposesToUnitRequest,
  AddPurposesToUnitResponse,
  AddPrinciplesToUnitRequest,
  AddPrinciplesToUnitResponse,
  UnitImagesResponse,
  UnitSchemesResponse,
  SetPositionToUnitRequest,
  AddPositionToUnitResponse,
  SetCommentToUnitRequest,
  AddCommentToUnitResponse,
  UnitMaterialsReplicationRequest,
  UnitManufacturingMethodsReplicationRequest,
  UnitPurposesReplicationRequest,
  UnitPositionReplicationRequest,
  UnitEquipmentTypesReplicationRequest,
  UnitImagesReplicationRequest,
  UnitSchemesReplicationRequest,
  UnitReplicationRequest,
  MultiUnitReplicationRequest,
};

import { Icon } from '@mui/material'

import { themeSpacingFromPixels } from '../../../lib/workWithThemeSpacing'

import iconClose from './accordion icon close.svg'
import iconOpen from './accordion icon open.svg'

interface AccordionExpandIconPlusProps {
  isExpanded: boolean
}

export const AccordionExpandIconPlusTechCard = (props: AccordionExpandIconPlusProps): JSX.Element => {

  const icon = props.isExpanded
    ? <img
      alt="accordion open icon"
      src={iconOpen}
    />
    : <img
      alt="accordion close icon"
      src={iconClose}
    />

  return (
    <Icon sx={{
      width: '20px',
      height: '30px',
      margin: props.isExpanded
        ? themeSpacingFromPixels(12, 24, 0, 0)
        : themeSpacingFromPixels(4, 24, 0, 0),
    }}
    >
      {icon}
    </Icon>
  )
}

import { Download } from '@mui/icons-material'
import {
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material'
import {
  type FunctionComponent,
  useState,
} from 'react'

import { UNITS_API_ADDRESS } from 'shared/api/UnitsApi'
import { type DocumentCategory } from 'shared/model/contracts/UnitDto'
import AuthenticatedDownloadLink from 'shared/ui/components/AuthenticatedDownloadLink'

interface Props {
  unitFileId: number
  category: DocumentCategory
  filename?: string
  onError: (error: Error) => void
}

export const DownloadCategory: FunctionComponent<Props> = (props) => {
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownloading = () => {
    setIsDownloading(true)
  }

  const handleDownloaded = () => {
    setIsDownloading(false)
  }

  const handleDownloadError = (error: Error) => {
    setIsDownloading(false)
    props.onError(error)
  }

  return (
    <AuthenticatedDownloadLink
      method="GET"
      url={`${UNITS_API_ADDRESS}/${props.unitFileId}/files/categories/${props.category}`}
      filename={props.filename}
      isDownloading={isDownloading}
      onDownloading={handleDownloading}
      onDownloaded={handleDownloaded}
      onError={handleDownloadError}
    >
      {isDownloading
        ? (
          <CircularProgress
            size={20}
            sx={{ m: 1 }}
          />
        )
        : (
          <Tooltip title="Download">
            <IconButton color="primary">
              <Download />
            </IconButton>
          </Tooltip>
        )}
    </AuthenticatedDownloadLink>
  )
}

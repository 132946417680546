import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import settingsApi from 'shared/api/SettingsApi'
import {
  type AppSettingDto,
  type UpdateSettingDto,
} from 'shared/model/contracts/SettingsDto'

import { type RootState } from '../store'

export interface EditSettingsState {
  status: 'idle' | 'loading' | 'saving' | 'failed'
  settings: Setting[]
  pageError?: SerializedError
  actionError?: SerializedError
}

export interface Setting {
  settingId: number
  name: string
  value: string
  description: string
  grouping: string
}

const initialState: EditSettingsState = {
  status: 'loading',
  settings: [],
}

export const loadEditSettings = createAsyncThunk('edit-settings/load', async () => {
  return await settingsApi.getSettings()
})

export const saveEditSettings = createAsyncThunk('edit-settings/save', async (settings: UpdateSettingDto[]) => {
  return await settingsApi.saveOptions(settings)
})

export const editSettingsSlice = createSlice({
  name: 'edit-settings',
  initialState,
  reducers: {
    showActionError: (state, action: PayloadAction<Error>) => {
      state.actionError = action.payload
    },
    hideActionError: (state) => {
      state.actionError = undefined
    },
    setSettings: (state, action: PayloadAction<AppSettingDto[]>) => {
      state.settings = action.payload?.map((s) => {
        return {
          settingId: s.settingId,
          name: s.name,
          value: s.value,
          description: s.description,
          grouping: s.grouping,
        } as Setting
      })
    },
    setSettingValue: (state, action: PayloadAction<{ id: number, value: string }>) => {
      const setting = state.settings.find((s) => s.settingId === action.payload.id)
      if (setting) {
        setting.value = action.payload.value
      }
    },
    setActionError: (state, action: PayloadAction<Error>) => {
      state.actionError = action.payload
    },
    clearActionError: (state) => {
      state.actionError = undefined
    },
  },
  extraReducers: (build) => {
    build.addCase(loadEditSettings.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(loadEditSettings.fulfilled, (state, action) => {
      state.status = 'idle'
      state.settings = action.payload.map((s) => {
        return {
          settingId: s.settingId,
          name: s.name,
          value: s.value,
          description: s.description,
          grouping: s.grouping,
        } as AppSettingDto
      })
    })

    build.addCase(loadEditSettings.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(saveEditSettings.pending, (state, action) => {
      state.status = 'saving'
    })

    build.addCase(saveEditSettings.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(saveEditSettings.rejected, (state, action) => {
      state.status = 'idle'
      state.actionError = action.error
    })
  },
})

export const {
  showActionError,
  hideActionError,
  setSettings,
  setSettingValue,
  setActionError,
  clearActionError,
} =
  editSettingsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEditSettings = (state: RootState) => state.administration.settings.editSettings

export default editSettingsSlice.reducer

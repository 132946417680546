import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import { type MRT_ColumnDef } from 'material-react-table'
import { useSnackbar } from 'notistack'
import {
  Controller,
  useForm,
} from 'react-hook-form'

import {
  type CatalogNames,
  type CatalogRecord,
} from 'entities/logistics/Catalog'
import {
  CatalogApiMethods,
  CatalogApis,
} from 'entities/logistics/Catalog/model/types'

import { isNoDuplicates } from '../lib/checkDuplicates'
import {
  type CreateRecordElement,
  CreateRecordSchema,
} from '../model/types'

interface CreateModalProps {
  columns: Array<MRT_ColumnDef<CatalogRecord>>
  onClose: () => void
  onSubmit: (data: CreateRecordElement) => void
  open: boolean
  catalogName: CatalogNames
}

export const CreateNewCatalogRecord = ({
  open,
  onClose,
  onSubmit,
  catalogName,
}: CreateModalProps) => {
  const {
    enqueueSnackbar, 
  } = useSnackbar()

  const {
    currentData,
    isSuccess,
  } = CatalogApis[catalogName].endpoints[CatalogApiMethods[catalogName].getMany].useQuery(undefined)

  const {
    handleSubmit,
    control,
    formState: {
      errors, 
    },
    reset,
  } = useForm<{ value: string }>(
    {resolver: zodResolver(CreateRecordSchema)},
  )

  const onInnerSubmit = (data: CreateRecordElement) => {
    if (!isSuccess) {
      enqueueSnackbar('Connection problems', { variant: 'error' })
      return
    }
    if (isNoDuplicates(currentData, data, isSuccess)) {
      onSubmit(data)
      reset()
      onClose()
    } else {
      enqueueSnackbar('Can`t add similar value in catalog', { variant: 'warning' })
    }
  }

  const onInnerClose = () => {
    onClose()
  }

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Add new catalog record</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onInnerSubmit)}>
          <Stack
            sx={{
              paddingTop: '1rem',
              width: '100%',
              minWidth: {
                xs: '300px',
                sm: '360px',
                md: '400px',
              },
              gap: '1rem',
            }}
          >
            <Controller
              name={'value'}
              control={control}
              render={({
                field: {
                  onChange,
                  value,
                },
              }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  label={'Value'}
                  error={Boolean(errors?.value)}
                  helperText={errors?.value
                    ? 'Recuired'
                    : ''}
                />
              )}
            />
            <Button
              color="secondary"
              type="submit"
              variant="contained"
            >
              Create new record
            </Button>
            <Button onClick={onInnerClose}>Cancel</Button>
          </Stack>

        </form>
      </DialogContent>
    </Dialog>
  )
}

import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import unitsApi from 'shared/api/UnitsApi'
import { isEmptyOrSpaces } from 'shared/lib/stringHelper'
import { type PrintDocumentsRequest } from 'shared/model/contracts/print-documents'

import { UnitDto } from '../../../shared/model/contracts/UnitDto'
import { type RootState } from '../store'

const name = 'units-print-dialog'

interface UnitsPrintDialogState {
  open: boolean
  status: 'prepare' | 'sending' | 'completed' | 'error'
  fetchStatus: 'idle' | 'pending' | 'rejected'
  dialogUnitInfo?: DialogUnitInfo
  replaceDesignId: boolean
  replaceDesignIdByProductionId: boolean
  addRequirements: boolean
  requirements: string
  requirementsError: boolean
  replaceLogo: boolean
  outlineDrawings: boolean
  inspectionDrawings: boolean
  designDrawings: boolean
  unitTypes?: string[]
  error?: SerializedError
}

export type DialogUnitInfo = Pick<UnitDto, 'unitFileId' | 'elementId' | 'productionId'>;

const initialState: UnitsPrintDialogState = {
  open: false,
  status: 'prepare',
  fetchStatus: 'pending',
  replaceDesignId: false,
  replaceDesignIdByProductionId: false,
  addRequirements: false,
  requirements: '',
  requirementsError: false,
  replaceLogo: false,
  outlineDrawings: false,
  inspectionDrawings: false,
  designDrawings: false,
}

const getRequirementsError = (value: string) => {
  return isEmptyOrSpaces(value) || value.length > 120
}

export const print = createAsyncThunk(
  `${name}/print`,
  async (payload: { unitId: number, request: PrintDocumentsRequest }) => {
    return await unitsApi.print(payload.unitId, payload.request)
  },
)

export const getUnitTypes = createAsyncThunk(`${name}/printtypes`, async (payload: { unitId: number }) => {
  return await unitsApi.printTypes(payload.unitId)
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    open: (
      _, action: PayloadAction<DialogUnitInfo>) => {
      return {
        ...initialState,
        open: true,
        dialogUnitInfo: action.payload,
      }
    },

    close: (state) => {
      return {
        ...state,
        open: false,
      }
    },

    changeReplaceDesignId: (state, action: PayloadAction<boolean>) => {
      state.replaceDesignId = action.payload
    },

    changeReplaceDesignIdByProductionId: (state, action: PayloadAction<boolean>) => {
      state.replaceDesignIdByProductionId = action.payload
    },

    changeAddRequirements: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        addRequirements: action.payload,
        requirementsError: getRequirementsError(state.requirements),
      }
    },

    changeRequirements: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        requirements: action.payload,
        requirementsError: getRequirementsError(action.payload),
      }
    },

    changeReplaceLogo: (state, action: PayloadAction<boolean>) => {
      state.replaceLogo = action.payload
    },

    changeOutlineDrawings: (state, action: PayloadAction<boolean>) => {
      state.outlineDrawings = action.payload
    },

    changeInspectionDrawings: (state, action: PayloadAction<boolean>) => {
      state.inspectionDrawings = action.payload
    },

    changeDesignDrawings: (state, action: PayloadAction<boolean>) => {
      state.designDrawings = action.payload
    },
  },
  extraReducers: (build) => {
    build.addCase(print.pending, (state) => {
      return {
        ...state,
        status: 'sending',
      }
    })

    build.addCase(print.fulfilled, (state) => {
      return {
        ...state,
        status: 'completed',
      }
    })

    build.addCase(print.rejected, (state, payload) => {
      return {
        ...state,
        status: 'error',
        error: payload.error,
      }
    })

    build.addCase(getUnitTypes.pending, (state, action) => {
      if (state.fetchStatus === 'idle') {
        state.fetchStatus = 'pending'
      }
    })

    build.addCase(getUnitTypes.fulfilled, (state, action) => {
      if (action.payload) {
        state.unitTypes = action.payload
      }
      state.fetchStatus = 'idle'
    })

    build.addCase(getUnitTypes.rejected, (state, payload) => {
      state.fetchStatus = 'rejected'
      state.error = payload.error
    })
  },
})

export const {
  open,
  close,
  changeReplaceDesignId,
  changeReplaceDesignIdByProductionId,
  changeAddRequirements,
  changeRequirements,
  changeReplaceLogo,
  changeOutlineDrawings,
  changeInspectionDrawings,
  changeDesignDrawings,
} = slice.actions
export const selectPrintDialogState = (state: RootState) => state.units.list.printDialog
export default slice.reducer

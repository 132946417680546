import { type UserDto } from '../../model/contracts/administration/Users'
import { API_ADDRESS } from '../api'
import authService from '../AuthService'
import { handleErrors } from '../errorHandler'
import { msalFetch } from '../graph'

const USER_API_ADDRESS = `${API_ADDRESS}/api/administration/users`

export class UsersApi {
  async getUsers () {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(USER_API_ADDRESS, token.accessToken)
      .then(handleErrors)
      .then<UserDto[]>(async (res) => await res.json())
  }

  async updateEnableLogin (userId: string, enableLogin: boolean) {
    const token = await authService.getIdentity()

    await msalFetch()
      .put(`${USER_API_ADDRESS}/${userId}/enable-login`, token.accessToken, { enableLogin })
      .then(handleErrors)
      .then(async () => { await Promise.resolve() })
  }

  async addUser (userId: string) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .post(`${USER_API_ADDRESS}/${userId}`, token.accessToken)
      .then(handleErrors)
      .then<UserDto>(async (res) => await res.json())
  }
}

const usersApi = new UsersApi()

export default usersApi

import { type Theme } from '@mui/material'
import {
  createTheme,
  type ThemeOptions,
} from '@mui/material/styles'

import { themeSpacingFromPixels } from 'shared/lib/workWithThemeSpacing'

import { baseTheme } from './baseTheme'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    extraCaption: React.CSSProperties
    extraSubCaption: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    extraCaption?: React.CSSProperties
    extraSubCaption?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    extraCaption: true
    extraSubCaption: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    tablet: true
    desktop: true
  }

  interface Theme {
    additionalPalette: {
      grey: {
        default: string
        menu: string
        stroke: string
        light: string
      }
    }
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    additionalPalette?: {
      grey?: {
        default?: string
        menu?: string
        stroke?: string
        light?: string
      }
    }
  }
}

export const themeEnlarged: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#7858A6',
      light: '#F5F2F8',
      dark: '#654A8C',
    },
    secondary: {
      main: '#f86a04',
      light: '#F86A0414',
      contrastText: '#fff',
    },
    divider: '#7858A6',
    warning: {
      main: '#D92D20',
      light: '#D92D2014',
    },
    error: { main: '#d83027' },
    info: { main: '#3538cd' },
    background: { default: '#fff' },
  },
  additionalPalette: {
    grey: {
      default: '#1D2939',
      menu: '#344054',
      stroke: '#D0D5DD',
      light: '#F5F5F5',
    },
  },
  shape: { borderRadius: 8 },
  spacing: 8,
  breakpoints: {
    values: {
      xs: 360,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920,
      tablet: 1280,
      desktop: 1440,
    },
  },
}

const typographyOverride: ThemeOptions = {
  typography: {
    fontFamily: 'Inter, "Roboto", "Helvetica", "Arial", sans-serif',
    // заголовки блоков
    h1: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
      },
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
      },
    },
    // заголовки таблиц
    h2: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
      },
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
      },
    },
    body1: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
      },
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
      },
    },
    button: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
      },
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
      },
    },
    caption: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
      },
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '18px',
      },
    },
    extraCaption: {
      fontSize: '48px',
      fontWeight: 600,
      lineHeight: '60px',
      fontFamily: 'Inter, "Roboto", "Helvetica", "Arial", sans-serif',
    },
    extraSubCaption: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '30px',
      fontFamily: 'Inter, "Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
}

const ecBaseTheme: Theme = createTheme(themeEnlarged, typographyOverride)

const componentsOverride: ThemeOptions = {
  components:
    {
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            border: '#D0D5DD 1px solid',
            boxShadow: 'none',
          },
        },
      },

      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            borderRadius: themeSpacingFromPixels(12),
            padding: themeSpacingFromPixels(24, 32, 20, 32),
            height: themeSpacingFromPixels(96),
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: ({
            ownerState,
          }) => ({
            padding: themeSpacingFromPixels(0, 0, 16, 0),
            borderTop: ownerState.isExpanded
              ? `1px solid ${ecBaseTheme.additionalPalette.grey.stroke}`
              : 'none',
          }),
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderColor: ecBaseTheme.additionalPalette.grey.stroke,
            color: ecBaseTheme.additionalPalette.grey.menu,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '18px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: (componentState) => {
            // @ts-ignore
            if (componentState?.as === 'th') {
              return {
                [baseTheme.breakpoints.up('tablet')]: {
                  ...baseTheme.typography.body1,
                  color: '#101828',

                },
                [baseTheme.breakpoints.down('tablet')]: {
                  ...baseTheme.typography.body1,
                  color: '#667085',
                },
              }
            }
            return {
              ...baseTheme.typography.body1,
              color: '#667085',
            }
          },
        },
      },
    },
}

export const ecTheme: Theme = createTheme(ecBaseTheme, componentsOverride)

import { Grid, type Theme, Typography } from '@mui/material'
import { type SxProps } from '@mui/system'
import { type FunctionComponent } from 'react'

interface Props {
  code?: string
  description?: string
  sx?: SxProps<Theme>
  children?: React.ReactNode
}

const ErrorBox: FunctionComponent<Props> = (props) => {
  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={props.sx}
    >
      <Grid item>
        <Typography
          variant="h1"
          align="center"
        >
          {props.code || ''}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h4"
          align="center"
        >
          {props.description || ''}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          align="center"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          Notification sent to administrator
        </Typography>
      </Grid>
      <Grid item>{props.children}</Grid>
    </Grid>
  )
}

export default ErrorBox

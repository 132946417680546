import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Skeleton,
} from '@mui/material'
import _ from 'lodash'
import {
  Fragment,
  type FunctionComponent,
  useEffect,
} from 'react'

import {
  clearActionError,
  type EditRoleRightsState,
  loadRights,
  saveRoleRights,
  selectEditRoleRightsDialog,
  setRoleRights,
  toggleRoleRight,
} from 'app/store/administration/editRoleRightsSlice'
import {
  type EditRoleState,
  selectEditRole,
} from 'app/store/administration/roleSlice'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

import { type RightDto } from 'shared/model/contracts/administration/Rights'
import ErrorBox from 'shared/ui/components/ErrorBox'
import ErrorDialog from 'shared/ui/components/ErrorDialog'

interface Props {
  open: boolean
  onCancel: () => void
  onOk: (rights: RightDto[]) => void
}

const RoleRightsEditor: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()
  const roleState: EditRoleState = useAppSelector(selectEditRole)
  const dialogState: EditRoleRightsState = useAppSelector(selectEditRoleRightsDialog)

  const failed = dialogState.status === 'failed'
  const saving = dialogState.status === 'saving'
  const loading = dialogState.status === 'loading'
  const idle = dialogState.status === 'idle'

  useEffect(() => {
    dispatch(loadRights())
  }, [props.open, dispatch])

  useEffect(() => {
    dispatch(setRoleRights(roleState.rights.map((r) => r.rightId)))
  }, [props.open, dispatch, roleState.rights])

  const handleToggle = (rightId: number) => () => {
    dispatch(toggleRoleRight(rightId))
  }

  const handleSave = () => {
    if (!roleState.roleId) {
      return
    }

    dispatch(saveRoleRights({
      roleId: roleState.roleId,
      rights: dialogState.roleRights,
    })).then(
      (request) => {
        request.meta.requestStatus === 'fulfilled' && props.onOk(request.payload as RightDto[])
      },
    )
  }

  const orderedItesm = _.orderBy(dialogState.systemRights, ['grouping', 'description'])
  const groups = _.groupBy(orderedItesm, 'grouping')

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Edit role rights</DialogTitle>
      <DialogContent>
        {loading && (
          <List>
            {Array.from(Array(5).keys()).map((i) => {
              return (
                <ListItem
                  key={i}
                  divider
                >
                  <Box sx={{ width: '100%' }}>
                    <Skeleton />
                  </Box>
                </ListItem>
              )
            })}
          </List>
        )}
        {failed && dialogState.dialogError && (
          <ErrorBox
            code={dialogState.dialogError.name}
            description={dialogState.dialogError.message}
          >
            <Button
              variant="contained"
              onClick={async () => await dispatch(loadRights())}
            >
              Try again
            </Button>
          </ErrorBox>
        )}
        {(idle || saving) && (
          <List>
            {_.map(groups, (group, name) => (
              <Fragment key={name}>
                <ListSubheader
                  color="primary"
                  disableSticky
                  key={name}
                >
                  {name}
                </ListSubheader>
                {group.map((r) => {
                  const labelId = `checkbox-list-secondary-label-${r.rightId}`
                  return (
                    <ListItem key={r.rightId}>
                      <ListItemText
                        primary={r.description}
                        secondary={r.name}
                        sx={{wordBreak:'break-word'}}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          disabled={saving}
                          checked={dialogState.roleRights.includes(r.rightId)}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onChange={handleToggle(r.rightId)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })}
              </Fragment>
            ))}
          </List>
        )}
        <ErrorDialog
          open={dialogState.actionError !== undefined}
          title={dialogState.actionError?.name || 'Error'}
          text={dialogState.actionError?.message ?? ''}
          onOk={() => dispatch(clearActionError())}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading || saving}
          onClick={props.onCancel}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          disabled={loading || saving || failed}
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          {saving
            ? <CircularProgress size={20} />
            : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RoleRightsEditor

// @ts-ignore
import PhotoSwipeLightbox from 'photoswipe/lightbox'
// eslint-disable-next-line import/order
import {
  type FC,
  useEffect,
  useState,
} from 'react'

import 'photoswipe/style.css'

// eslint-disable-next-line import/order
import {
  Checkbox,
  ImageList,
  Skeleton,
  Stack,
} from '@mui/material'
import wretch from 'wretch'

import { GalleryImage } from 'shared/ui/PhotoSwipeGallery'

// eslint-disable-next-line import/order
import Typography from '@mui/material/Typography'
import { UnitsInitailsDialogState, useAppSelector, selectInitialsDialogState } from '../../../../app/store'

// eslint-disable-next-line import/order

export interface InitialsGalleryImage extends GalleryImage {
  fileName?:string
  version?:number
  checked?:boolean
  id:number
}

interface InitialsGalleryProps {
  galleryID: string
  images: InitialsGalleryImage[]
  columns?:number
  onFileCheck:(fileId:number,value:boolean)=>void
}

const createImage = async (longUrl:any)=>{
  const data = await wretch(longUrl).get().blob()
  const url = URL.createObjectURL(data)
  const img = new Image()
  img.src = url

  return await img
}

export const InitialsGallery: FC<InitialsGalleryProps> = ({
  galleryID,
  images,
  columns,
  onFileCheck,
}) => {

  const [imgSet,setImgSet]=useState<HTMLImageElement[]>([])
  const [success,setSuccess] = useState<boolean|undefined>(undefined)
  const initialsUnitFiles: UnitsInitailsDialogState = useAppSelector(selectInitialsDialogState)
  const open = initialsUnitFiles.open

  useEffect( () => {
    const getData = async () => {
      const t = await Promise.all(
        images.map(async (image) => createImage(image.largeURL)))

      setImgSet(t)
      setSuccess(true)
    }

    getData().catch((err)=>{
      setSuccess(false)
      setImgSet([])
    })
  }, [images])

  useEffect(() => {
    let lightbox: PhotoSwipeLightbox|null = new PhotoSwipeLightbox({
      gallery:`#${galleryID}`,
      childSelector:'.pswp-photo',
      pswpModule: () => import('photoswipe'),
      initialZoomLevel:'fit',
    });
    lightbox?.init();

    return () => {
      lightbox?.destroy();
      lightbox = null;
    };
  }, []);

  if (!imgSet?.length) {
    return <Skeleton
      variant="rectangular"
      width={600}
      height={300}
    />
  }

  return (
    <ImageList
      variant="quilted"
      cols={columns || 4}
      gap={4}
      id={galleryID}
    >
      {!success && <Typography>Error on load</Typography>}
      {success && imgSet?.map((curImage: HTMLImageElement, index) => {
        return (
          <Stack
            key={galleryID + '-' + index}
            direction={'column'}
            alignContent={'center'}
          >

            <a
              className={'pswp-photo'}
              href={curImage.src}
              data-pswp-width={curImage.width}
              data-pswp-height={curImage.height}
              key={galleryID + '-' + index}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={curImage?.currentSrc || ''}

                width={600}
                alt={''}
                loading={'lazy'}
              />
            </a>

            <Stack
              direction={'row'}
              spacing={1}
              alignContent={'center'}
              alignItems={'center'}
            >
              <Checkbox
                onChange={(event)=>{
                  onFileCheck(images[index]?.id,event?.target?.checked)}
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography>{`${images[index]?.fileName}`}</Typography>
            </Stack>
          </Stack>
        )
      })
      }
    </ImageList>
  )

}

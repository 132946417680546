import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import {
  Button,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'

import { logisticsTheme } from 'app/config/theme/logisticsTheme'

const isMultiLine = (line: string | number): boolean => {
  if (!isNaN(Number(line))) {
    return false
  }

  const str = line as string

  return str.includes('\n') || str.includes('\n\r') || str.includes('\r')
}

const splitBySeparator = (line: string): string[] => {
  return line.split(/\r\n|\r|\n/)
}

export function EditableFieldTextData (props: {
  fieldName?: string
  warning?: boolean
  readonly?: boolean,
  dataValue: string | number
  onClick?: () => void
}): JSX.Element {

  return (
    <Stack
      direction="column"
      sx={{
        width: 'auto',
        padding: '12px 0 12px 0',
        wordBreak: 'break-all',
      }}
    >
      <Stack
        direction={'row'}
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
        >
          {props.warning && <ErrorOutlineIcon htmlColor={logisticsTheme.palette.warning.main}/>}
          <Stack direction="column">
            {props.fieldName && <Typography variant="h2">
              {props.fieldName}
            </Typography>}
            {isMultiLine(props.dataValue)
              ?
                <>
                {splitBySeparator(props.dataValue as string).map(l =>
                    <Typography
                    variant="body1"
                    sx={{
                      color: '#667085',
                      wordBreak: 'break-word',
                    }}
                  >
                    {l}
                  </Typography>,
                )}
              </>
              : <Typography
                  variant="body1"
                  sx={{
                  color: '#667085',
                  wordBreak: 'break-word',
                }}
              >
                {props.dataValue}
              </Typography>
            }

          </Stack>
          {!props.readonly && <Button onClick={props?.onClick}>
            <ModeEditOutlinedIcon/>
          </Button>}
        </Stack>

      </Stack>
    </Stack>)
}

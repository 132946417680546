import {
  DialogTitle, Divider, Checkbox, Tooltip, Stack, Grid, Dialog, DialogActions, DialogContent, Collapse,
  Button, Typography, FormControlLabel,
} from '@mui/material'
import { FC, SyntheticEvent } from 'react'

import { useAppDispatch, useAppSelector } from '../../../../../app/store'
import {
  selectReplicateDescriptionsState, ReplicateDescriptionDialogState,
  setManufacturingMethodsRequest, setPurposesRequest, setEquipmentTypesRequest,
  setPositionRequestRequest, setSchemesRequest, setImagesRequest, setMaterialsReplicationRequest,
  setOpen, selectDescriptions,
} from '../../../../../features/logistics/unitDescription/replicateDescription/replicateDescriptionSlice'
import { themeSpacingFromPixels } from '../../../../../shared/lib/workWithThemeSpacing'

const mapper : {
  materialsReplicationRequest: string,
  manufacturingMethodsRequest: string,
  purposesRequest: string,
  positionRequest: string,
  equipmentTypesRequest:string,
  imagesRequest: string,
  schemesRequest: string,
} = {
  materialsReplicationRequest: 'Материалы',
  manufacturingMethodsRequest: 'Способ изготовления',
  purposesRequest: 'Назначение',
  positionRequest: 'Позиция',
  equipmentTypesRequest:'Тип оборудования',
  imagesRequest:'Изображения',
  schemesRequest: 'Схемы',
}

export const ReplicationDialog: FC = () => {
  const dispatch = useAppDispatch()

  const replicateDescriptionsState: ReplicateDescriptionDialogState  = useAppSelector(selectReplicateDescriptionsState)
  const requestEntries = Object.entries(replicateDescriptionsState.request);

  const handleDialogClose = (_: SyntheticEvent , reason: string) => {
    if (reason && (reason === "backdropClick" || reason ===  "escapeKeyDown"))
    {
      dispatch(
        setOpen(false),
      )
    }
  }

  const handleDialogSelect = () => {
    dispatch(
      selectDescriptions(),
    )
    dispatch(
      setOpen(false),
    )
  }

  const handleParameterCheck = (parameter: string, value: boolean) => {
    if(parameter in mapper){
      switch (parameter) {
        case "materialsReplicationRequest":
          dispatch(
            setMaterialsReplicationRequest(value),
          )
          break;
        case "manufacturingMethodsRequest":
          dispatch(
            setManufacturingMethodsRequest(value),
          )
          break;
        case "purposesRequest":
          dispatch(
            setPurposesRequest(value),
          )
          break;
        case "positionRequest":
          dispatch(
            setPositionRequestRequest(value),
          )
          break;
        case "equipmentTypesRequest":
          dispatch(
            setEquipmentTypesRequest(value),
          )
          break;
        case "imagesRequest":
          dispatch(
            setImagesRequest(value),
          )
          break;
        case "schemesRequest":
          dispatch(
            setSchemesRequest(value),
          )
          break;
      }
    }
  }

  const handleCheckAll = ( value: boolean) => {
    dispatch(
      setMaterialsReplicationRequest(value),
    )
    dispatch(
      setManufacturingMethodsRequest(value),
    )
    dispatch(
      setPurposesRequest(value),
    )
    dispatch(
      setPositionRequestRequest(value),
    )
    dispatch(
      setEquipmentTypesRequest(value),
    )
    dispatch(
      setImagesRequest(value),
    )
    dispatch(
      setSchemesRequest(value),
    )
  }

  return (
    <Dialog
      sx={{'& .MuiDialog-paper': {maxWidth:themeSpacingFromPixels(450)}}}
      open={replicateDescriptionsState.open}
      onClose={handleDialogClose}
    >
      <DialogTitle>Выбор параметров для копирования</DialogTitle>
      <Divider/>
      <DialogContent>
        <Collapse in={true}>
          <FormControlLabel
            control={
              <Stack
                direction={'row'}
                spacing={1}
                marginRight={2}
              >
                <Tooltip
                  title="Выбрать все параметры"
                  placement="right"
                >
                  <Checkbox
                    onChange={(event) => {
                      handleCheckAll(event.target.checked)
                    }}
                    checked={requestEntries.filter(([ _,request]) => !request.replicate).length === 0}
                  />
                </Tooltip>
              </Stack>
            }
            label={'Выбрать все параметры'}
          />

          <Grid container>
            {requestEntries.map(([parameter, requestValue]) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={parameter}
                >
                  <FormControlLabel
                    control={
                      <Stack
                        direction={'row'}
                        spacing={1}
                        marginRight={2}
                      >
                        <Checkbox
                          checked={requestValue.replicate}
                          onChange={(event) => {
                            handleParameterCheck(parameter,event.target.checked)
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Stack>
                    }
                    label={`${mapper[parameter as keyof typeof mapper] as string}`}
                  />
                </Grid>
              )
            })}
          </Grid>
          {requestEntries.filter(([_,request]) => request.replicate).length < 1 && (
            <Typography color="error">Выберете один или несколько параметров</Typography>
          )}
        </Collapse>
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Stack
          direction={'row'}
          spacing={1}
        >
          <Button
            color="primary"
            variant="contained"
            disabled={requestEntries.filter(([_,request]) => request.replicate).length < 1}
            onClick={handleDialogSelect}
          >
            Выбрать
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={()=> {
              dispatch(
                setOpen(false),
              )
            }}
          >
            Закрыть
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

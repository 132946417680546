import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import rolesApi from 'shared/api/administration/RolesApi'
import { type UserDto } from 'shared/model/contracts/administration/Users'

import { type RootState } from '../store'

export interface RolesListState {
  status: 'idle' | 'loading' | 'failed'
  roles: RolesListItem[]
  addRoleDialogOpened: boolean
  pageError?: SerializedError
  actionError?: SerializedError
}

export interface RolesListItem {
  status: 'idle' | 'loading' | 'saving' | 'failed'
  roleId: number
  name: string
  users: UserDto[]
}

const initialState: RolesListState = {
  status: 'idle',
  addRoleDialogOpened: false,
  roles: [],
}

export const loadRoles = createAsyncThunk('roles-list/load', async () => {
  return await rolesApi.getRoles()
})

export const createRole = createAsyncThunk('roles-list/create', async () => {
  return await rolesApi.createRole()
})

export const rolesListSlice = createSlice({
  name: 'roles-list',
  initialState,
  reducers: {
    setActionError: (state, action: PayloadAction<Error | undefined>) => {
      state.actionError = action.payload
    },
    showAddRoleDialog: (state) => {
      state.addRoleDialogOpened = true
    },
    hideAddRoleDialog: (state) => {
      state.addRoleDialogOpened = false
    },
  },
  extraReducers: (build) => {
    build.addCase(loadRoles.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(loadRoles.fulfilled, (state, action) => {
      state.status = 'idle'
      state.roles = action.payload.map((r) => {
        return {
          status: 'idle',
          roleId: r.roleId,
          name: r.name,
          users: r.users,
        } as RolesListItem
      })
    })

    build.addCase(loadRoles.rejected, (state, action) => {
      state.status = 'failed'
      state.pageError = action.error
    })

    build.addCase(createRole.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(createRole.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(createRole.rejected, (state, action) => {
      state.status = 'idle'
      state.actionError = action.error
    })
  },
})

export const {
  setActionError,
  showAddRoleDialog,
  hideAddRoleDialog,
} = rolesListSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRolesList = (state: RootState) => state.administration.roles.list

export default rolesListSlice.reducer

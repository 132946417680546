import {
  AZURE_REDIRECT_ADDRESS,
  REACT_APP_AZURE_API,
  REACT_APP_AZURE_AUTHORITY,
  REACT_APP_AZURE_CLIENT_ID,
} from 'shared/api/api'

export const msalConfig = {
  auth: {
    clientId: REACT_APP_AZURE_CLIENT_ID ?? '',
    authority: REACT_APP_AZURE_AUTHORITY ?? '',
    redirectUri: AZURE_REDIRECT_ADDRESS ?? '',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {scopes: ['User.Read', 'User.ReadBasic.All']}

export const apiRequest = {scopes: [REACT_APP_AZURE_API ?? '']}

// // Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphUsersEndpoint: 'https://graph.microsoft.com/v1.0/users',
}

import {
  Icon,
  Stack,
  Typography,
} from '@mui/material'
import { type FC } from 'react'

interface AssemblyIconLogisticsProps {
  color?: 'white' | 'black'
}

const AssemblyIconLogistics: FC<AssemblyIconLogisticsProps> = ({
  color, 
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Icon sx={{
        height: 18,
        display: 'flex',
      }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.66667 13.3333H4.8C5.9201 13.3333 6.48016 13.3333 6.90798 13.1153C7.28431 12.9236 7.59027 12.6176 7.78201 12.2413C8 11.8135 8 11.2534 8 10.1333V5.86667C8 4.74656 8 4.18651 7.78201 3.75869C7.59027 3.38236 7.28431 3.0764 6.90798 2.88466C6.48016 2.66667 5.9201 2.66667 4.8 2.66667H4.66667M4.66667 13.3333C4.66667 14.0697 4.06971 14.6667 3.33333 14.6667C2.59695 14.6667 2 14.0697 2 13.3333C2 12.597 2.59695 12 3.33333 12C4.06971 12 4.66667 12.597 4.66667 13.3333ZM4.66667 2.66667C4.66667 3.40305 4.06971 4 3.33333 4C2.59695 4 2 3.40305 2 2.66667C2 1.93029 2.59695 1.33334 3.33333 1.33334C4.06971 1.33334 4.66667 1.93029 4.66667 2.66667ZM11.3333 8L4.66667 8M11.3333 8C11.3333 8.73638 11.9303 9.33334 12.6667 9.33334C13.403 9.33334 14 8.73638 14 8C14 7.26362 13.403 6.66667 12.6667 6.66667C11.9303 6.66667 11.3333 7.26362 11.3333 8ZM4.66667 8C4.66667 8.73638 4.06971 9.33334 3.33333 9.33334C2.59695 9.33334 2 8.73638 2 8C2 7.26362 2.59695 6.66667 3.33333 6.66667C4.06971 6.66667 4.66667 7.26362 4.66667 8Z"
            stroke={color ?? 'white'}
            strokeWidth="1.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Icon>
      <Typography variant="caption">Assembly unit</Typography>
    </Stack>
  )
}

export default AssemblyIconLogistics

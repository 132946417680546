import { type AuthenticationResult } from '@azure/msal-common'
import ImageIcon from '@mui/icons-material/Image'
import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import FileSaver from 'file-saver'
import { useSnackbar } from 'notistack'
import {
  type FC,
  useEffect,
  useState,
} from 'react'

import {
  changeChecked,
  changeCheckedAll,
  close,
  downloadInitials,
  selectInitialsDialogState,
  setPreviewId,
  setPreviewOpened,
  type UnitsInitailsDialogState,
  useAppDispatch,
} from 'app/store'
import { useAppSelector } from 'app/store/hooks'

import {
  InitialsGallery,
  InitialsGalleryImage,
} from 'features/common/showInitials/ui/InitialsGallery'

import { API_ADDRESS } from 'shared/api/api'
import authService from 'shared/api/AuthService'
import { type InitialsFile } from 'shared/model/contracts/InitialsFile'

import { setLoaded } from '../../../app/store/units/initialsDialogSlice'
import { themeSpacingFromPixels } from '../../../shared/lib/workWithThemeSpacing'

import { ModalImagePreview } from './ModalImagePreview'

const imageMimeTypes = ['image/jpeg','image/png']

const InitialsDialog: FC = () => {
  const dispatch = useAppDispatch()

  const initialsUnitFiles: UnitsInitailsDialogState = useAppSelector(selectInitialsDialogState)
  const open = initialsUnitFiles.open
  const [token, setToken] = useState<AuthenticationResult['accessToken']>()
  const [galleryData, setGalleryData] = useState<InitialsGalleryImage[]>([])

  useEffect(() => {
    const sToken = async () => {
      const t: AuthenticationResult = await authService.getIdentity()
      setToken(t.accessToken)
    }
    void sToken()
  }, [])
  const {
    enqueueSnackbar,
  } = useSnackbar()

  const handleCancelClick = () => {
    dispatch(close())
    dispatch(setLoaded(false))
  }

  useEffect( () => {
    if(open){
      if(!initialsUnitFiles.loaded){
        const imgPaths: string[] = initialsUnitFiles?.fileList?.map((value: InitialsFile): string => {
          return `units/${initialsUnitFiles.unit.unitFileId}/files/initials/${value.id}/file?token=${token}`

        }) || ['']
        const galData: InitialsGalleryImage[]  = initialsUnitFiles
          ?.fileList
          ?.filter((img:InitialsFile)=>imageMimeTypes.includes(img.mimeType))
          .map((initial:InitialsFile): InitialsGalleryImage => {
            return {
              largeURL:`${API_ADDRESS}/api/units/${initialsUnitFiles.unit.unitFileId}/files/initials/${initial.id}/file?token=${token}`,
              fileName:initial.fileName,
              version:initial.version,
              id:initial.id,
              checked:initial.checked,
            }
          }) || []

        setGalleryData(galData)
        dispatch(setLoaded(true))
      }
    } else {
      setGalleryData([])
      dispatch(setLoaded(false))
    }
  }, [open])

  const handleFileCheck = (fileId: number, value: boolean) => {
    dispatch(changeChecked({
      fileId,
      value,
    }))
  }

  const handleDownloadClick = async () => {
    enqueueSnackbar('Initials download started', { variant: 'info' })

    const selectedFiles = initialsUnitFiles.fileList?.filter((f) => f.checked) ?? []
    const content = await dispatch(
      downloadInitials({
        unitId: initialsUnitFiles?.unit?.unitFileId||0,
        files: selectedFiles.map((f) => {
          return {
            fileId: f.id,
            fileName: f.fileName,
            version: f.version,
          }
        }),
      }),
    ).unwrap()

    enqueueSnackbar('Initials download succeed', { variant: 'success' })
    FileSaver.saveAs(content, initialsUnitFiles.unit?.oemArticle
      ? `${initialsUnitFiles.unit.oemArticle} Initials.zip`
      : 'Initials.zip')

  }

  const handlePreview = (fileId: number): void => {
    dispatch(setPreviewId(fileId))
    dispatch(setPreviewOpened(true))
  }

  const handleCheckAll = (value: boolean) => {
    dispatch(changeCheckedAll(value))
  }

  return (
    <Dialog
      sx={{'& .MuiDialog-paper': {maxWidth:themeSpacingFromPixels(1100)}}}
      open={initialsUnitFiles.open}
    >
      <DialogTitle>Initials files</DialogTitle>
      <Divider/>
      <DialogContent>
        <Collapse in={initialsUnitFiles.fetchStatus === 'idle'}>

          <FormControlLabel
            control={
              <Stack
                direction={'row'}
                spacing={1}
                marginRight={2}
              >
                <Tooltip
                  title="Select all files"
                  placement="right"
                >
                  <Checkbox
                    sx={{ marginLeft: 6 }}
                    onChange={(event) => {
                      handleCheckAll(event.target.checked)
                    }}
                  />
                </Tooltip>
              </Stack>
            }
            label={'Select all elements'}
          />
          <InitialsGallery
            galleryID={'initials-gallery'}
            images={galleryData}
            columns={1}
            onFileCheck={handleFileCheck}
          />

          <Grid container>
            {initialsUnitFiles.fileList?.length !== 0 && (
              <Grid
                item
                xs={12}
              >
              </Grid>
            )}
            {(initialsUnitFiles.fileList != null) &&
              initialsUnitFiles.fileList.filter((v:InitialsFile)=> !imageMimeTypes.includes(v.mimeType)).map((file) => (
                <Grid
                  item
                  xs={12}
                  key={file.id}
                >
                  <FormControlLabel
                    control={
                      <Stack
                        direction={'row'}
                        spacing={1}
                        marginRight={2}
                      >
                        <IconButton
                          onClick={() => {
                            handlePreview(file.id)
                          }}
                        >
                          <ImageIcon/>
                        </IconButton>
                        <Checkbox
                          checked={file.checked}
                          onChange={(event) => {
                            handleFileCheck(file.id, event.target.checked)
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Stack>
                    }
                    label={`${file.fileName}`}
                  />
                </Grid>
              ))}
            {initialsUnitFiles.fileList?.length === 0 && (initialsUnitFiles.error == null) && initialsUnitFiles.fetchStatus === 'idle' && (
              <Typography>{'No files found to display'}</Typography>
            )}
          </Grid>
        </Collapse>
        <Collapse in={initialsUnitFiles.fetchStatus === 'pending'}>
          <LinearProgress color="primary"/>
        </Collapse>
        <Collapse in={initialsUnitFiles.error?.message !== undefined}>
          <Typography color="error">
            {initialsUnitFiles.error?.message ?? 'An error occurred while fetching data. Please try again later'}
          </Typography>
        </Collapse>
        {initialsUnitFiles.fetchStatus !== 'pending' && initialsUnitFiles.fileList?.length !== 0 && (
          <Collapse in={!initialsUnitFiles.fileList?.some((f) => f.checked)}>
            <Typography color="error">Select one or more documents</Typography>
          </Collapse>
        )}
      </DialogContent>
      <ModalImagePreview open={initialsUnitFiles.previewOpened}/>
      <Divider/>
      <DialogActions>
        <Stack
          direction={'row'}
          spacing={1}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownloadClick}
            disabled={
              initialsUnitFiles.downloadStatus === 'pending' ||
              initialsUnitFiles.fetchStatus === 'pending' ||
              !initialsUnitFiles.fileList?.some((f) => f.checked)
            }
            endIcon={initialsUnitFiles.downloadStatus === 'pending'
              ? <CircularProgress size={16}/>
              : <></>}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancelClick}
          >
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default InitialsDialog

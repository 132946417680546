import {Button, Dialog, DialogContent} from '@mui/material'
import Uppy from '@uppy/core'
import '@uppy/image-editor/dist/style.min.css';
// @ts-ignore
import ImageEditor from "@uppy/image-editor";
// @ts-ignore
import en_US from "@uppy/locales/lib/en_US";
import {Dashboard} from '@uppy/react'
import XHR, {type XHRUploadOptions} from '@uppy/xhr-upload'
import {useSnackbar} from 'notistack'
// eslint-disable-next-line import/order
import {type FC, useEffect, useId, useMemo} from 'react'

import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'

import wretch from "wretch";

import {useAppDispatch, useAppSelector} from 'app/store'

import {isUpdateImageData, isUpdateSchemesData, technicalCardCacheUpdate} from 'entities/logistics/TechnicalCard'

import authService from 'shared/api/AuthService'

import {
  currentQaUnitDescription,
  isQaUploadOpen,
  qaImageGallerySlice,
  QaSelectedImageData,
  selectedImageTokens,
} from "../../openQAImageGallery";

const authHeaders = async () => {
  const token = await authService.getIdentity()
  return {Authorization: `Bearer ${token.accessToken}`}
}

async function getImageBlob (token:string,enqueueSnackbar:any){
  return await wretch(
    `${process.env.REACT_APP_QA_API}/api/documents/${token}`,
  )
    .get()
    .blob().catch((e: unknown) => {
      enqueueSnackbar('Error on load full image',{
        variant: 'error',
        preventDuplicate:true,
      })
    });
}

// @ts-ignore
export const DropzoneQAEditedPictures: FC = () => {

  const unitDescription = useAppSelector(currentQaUnitDescription)
  const dispatch = useAppDispatch();
  const uppyInstanceID = useId()
  const {
    enqueueSnackbar,
  } = useSnackbar()

  const qaSelectedImageData:QaSelectedImageData[] = useAppSelector(selectedImageTokens)
  const isQaUppyDropzoneOpen = useAppSelector(isQaUploadOpen)

  const xhrInOptions: XHRUploadOptions = useMemo(() =>{
    return {
      endpoint: `${process.env.REACT_APP_API_ADDRESS}/api/logistics/units/${unitDescription?.unitId}/images`,
      method: 'POST',
    }
  },[unitDescription])

  function openUppyEdit () {
    dispatch(qaImageGallerySlice.actions.setIsClosed());
    dispatch(qaImageGallerySlice.actions.setIsUploadOpen())
  }

  function closeUppyEdit (){
    dispatch(qaImageGallerySlice.actions.setIsUploadClose())
  }

  function setOpenUppyEdit (isOpen: boolean) {
    if (isOpen)
    {
      openUppyEdit()
    }
    if (!isOpen)
    {
      closeUppyEdit()
    }
  }

  const uppy = useMemo(() => {
    if (!xhrInOptions?.endpoint) {
      throw new Error('Endpoint is required')
    }

    const uppyInstanse = new Uppy(
      {
        id: uppyInstanceID,
        restrictions: {
          allowedFileTypes: [
            '.jpg',
            '.jpeg',
            '.png',
            '.tif',
            '.tiff',
            '.bmp',
            '.gif',
          ],
          maxFileSize: 5000000,
        },
        locale: {
          strings: {
            ...en_US.strings,
            dropPasteFiles: 'Drop files here or %{browseFiles}, maximum allowed size of file - 5Mb ',
          },
        },
      })
    // @ts-ignore
      .use(ImageEditor, {quality: 0.8})
      .use(XHR, {
        endpoint: xhrInOptions?.endpoint ?? '',
        getResponseData (responseText,response) {
          const parsed = JSON.parse(responseText);
          if (isUpdateImageData(parsed)) {

            technicalCardCacheUpdate(dispatch, parsed.unitId, {images: parsed.images})
          }
          if (isUpdateSchemesData(parsed)) {
            technicalCardCacheUpdate(dispatch, parsed.unitId, {schemes: parsed.schemes})
          }
          else {
          // todo handle parse error
          }
          setOpenUppyEdit(false)
          return response
        },
      })

    if (xhrInOptions) {
      authHeaders().then(curHeaders => {
        // @ts-ignore
        uppyInstanse.getPlugin('XHRUpload').setOptions({
          headers: curHeaders,
          ...xhrInOptions,
        })
      })
    }

    return uppyInstanse
  }, [xhrInOptions])

  useEffect(() => {
    if(!uppy){ return}

    if (!isQaUppyDropzoneOpen) {
      return
    }

    if (!qaSelectedImageData.length){
      return
    }

    const p = Promise.all(
      qaSelectedImageData.map(async selectedImageData => getImageBlob(selectedImageData.token,enqueueSnackbar)),
    ).then( responses =>{
      if(responses){
        responses.forEach((imageData,index) =>{
          if(imageData){
            uppy.addFile({
              type: 'image/jpeg',
              name: `${qaSelectedImageData[index].imageName}.jpg`,
              data: imageData,
              source: 'Local',
              isRemote: false,
            });}
        })
      }
    })
  }, [qaSelectedImageData, uppy, isQaUppyDropzoneOpen]);

  if (!uppy) {
    enqueueSnackbar('Error on creating dropzone for files', {
      variant: 'error',
      preventDuplicate: true,
    })
    return (
      <>
        Error on creating dropzone for files
      </>
    )
  }

  if (uppy) {
    return (
      <Dialog open={isQaUppyDropzoneOpen}>
        <DialogContent>
          <Dashboard
            id={`uppy-qa-${unitDescription?.unitId}`}
            closeModalOnClickOutside={true}
            doneButtonHandler={() => {
              setOpenUppyEdit(false)
            }}
            uppy={uppy}
            plugins={['XHR', 'ImageEditor']}
          /></DialogContent>
        <Button onClick={() => {
          setOpenUppyEdit(false)
        }}
        >Close</Button>
      </Dialog>
    )
  }

}

import {
  type IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser'

import {
  apiRequest,
  loginRequest,
  msalConfig,
} from 'app/config/authConfig'

class AuthService {
  private readonly msalInstance: IPublicClientApplication

  constructor () {
    this.msalInstance = new PublicClientApplication(msalConfig)
  }

  readonly serviceName = 'Microsoft'

  get instance () {
    return this.msalInstance
  }

  async signIn () {
    await this.msalInstance.loginRedirect(loginRequest)
  }

  async signOut () {
    await this.msalInstance.logoutRedirect()
  }

  async getClientToken () {
    const accounts = this.msalInstance.getAllAccounts()
    const account = accounts[0]
    if (account) {
      const request = {
        ...loginRequest,
        account,
      }

      return await this.msalInstance.acquireTokenSilent(request)
    }
    throw new Error('Failed to access')
  }

  async getIdentity () {
    const accounts = this.msalInstance.getAllAccounts()
    const account = accounts[0]
    if (account) {
      const request = {
        ...apiRequest,
        account,
      }
      return await this.msalInstance.acquireTokenSilent(request)
    }
    throw new Error('Failed to access')
  }
}

const authService = new AuthService()

export default authService

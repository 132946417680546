import SearchIcon from '@mui/icons-material/Search'
import { Button } from '@mui/material'
import { type FC } from 'react'

import { useAppDispatch } from 'app/store/hooks'

import { openSearchDialog } from '../model/searchDialogSlice'

interface SearchButtonProps {

}

export const SearchButton: FC<SearchButtonProps> = (props) => {

  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(openSearchDialog())
  }

  return (
    <>
      <Button
        startIcon={<SearchIcon />}
        data-testid="search-units-button"
        variant="contained"
        onClick={handleClick}
      >
        Search articles
      </Button>
    </>
  )
}

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material'
import { type FunctionComponent } from 'react'

import {
  hideEditOptionsDialog,
  selectEditRole,
  setOptions,
  showEditOptionsDialog,
} from 'app/store/administration/roleSlice'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

import RoleOptionsEditor from './RoleOptionsEditor'
import RoleOptionsList from './RoleOptionsList'

const RoleOptionsCard: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole)
  const dispatch = useAppDispatch()

  const renderButtons = () => {
    return (
      roleState.status === 'idle' && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => dispatch(showEditOptionsDialog())}
        >
          Edit
        </Button>
      )
    )
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title="Role Options"
        action={renderButtons()}
      />
      <Divider />
      <CardContent>
        <RoleOptionsList />
      </CardContent>

      {roleState.openEditOptionsDialog && (
        <RoleOptionsEditor
          open={roleState.openEditOptionsDialog}
          onCancel={() => dispatch(hideEditOptionsDialog())}
          onOk={(options) => {
            dispatch(setOptions(options))
            dispatch(hideEditOptionsDialog())
          }}
        />
      )}
    </Card>
  )
}

export default RoleOptionsCard

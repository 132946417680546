import {createSelector, createSlice, Selector} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'

import {UnitTechnicalDescription} from "entities/logistics/TechnicalCard";

import {RootState} from "../../../../app/store";

export type QaSelectedImageData = {
  token:string,
  imageName:string
}

interface QaImageGalleryState {
  isOpen:boolean,
  isUploadOpen:boolean,
  selectedImages:Array<QaSelectedImageData>
  currentDescription:UnitTechnicalDescription | null
}

const initialState: QaImageGalleryState = {
  isOpen:false,
  isUploadOpen:false,
  selectedImages:[],
  currentDescription:null,
}

export const qaImageGallerySlice = createSlice({
  name: 'qaImageGallery',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<UnitTechnicalDescription>) => {
      state.isOpen=true
      state.currentDescription = action.payload
      state.selectedImages = []
    },
    setIsClosed:(state)=>{
      state.isOpen = false
      state.selectedImages = []
      state.currentDescription = null
    },
    setIsClosedOnApply:(state)=>{
      state.isOpen = false
    },
    addTokenToSelectedImages: (state, action: PayloadAction<QaSelectedImageData>) => {
      state.selectedImages.push(action.payload)
    },
    deleteTokenFromSelectedImages: (state, action: PayloadAction<string>) => {
      const tokenToDelete = action.payload
      state.selectedImages = state.selectedImages.filter(i=>i.token!==tokenToDelete)
    },
    setIsUploadOpen: (state) => {
      state.isUploadOpen=true
    },
    setIsUploadClose: (state) => {
      state.isUploadOpen = false
      state.selectedImages = []
      state.currentDescription = null
    },
  },
})

const qaImageGalleryState = (store:RootState)=>store.logistics.qaImageGallery
export const isQaImageGalleryOpen:Selector<RootState,boolean> = createSelector(qaImageGalleryState, s => s.isOpen)
export const isQaUploadOpen:Selector<RootState,boolean> = createSelector(qaImageGalleryState, s => s.isUploadOpen)
export const selectedImageTokens:Selector<RootState,Array<QaSelectedImageData>> = createSelector(qaImageGalleryState, s => s.selectedImages)
export const currentQaUnitDescription:Selector<RootState,UnitTechnicalDescription|null> = createSelector(qaImageGalleryState, s => s.currentDescription)
export const currentGalleryElementId:Selector<RootState,UnitTechnicalDescription['elementId']|undefined> = createSelector(qaImageGalleryState, s=>s.currentDescription?.elementId)

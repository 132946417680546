import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { type FunctionComponent } from 'react'

interface Props {
  open: boolean
  title: string
  text: string
  onOk: () => void
}

const ErrorDialog: FunctionComponent<Props> = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onOk()
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onOk()
          }}
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog

import { Clear } from '@mui/icons-material'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Tooltip,
} from '@mui/material'
import _ from 'lodash'
import { type FunctionComponent } from 'react'

import {
  removeUser,
  selectEditRole,
  UserInRoleItem,
} from 'app/store/administration/roleSlice'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

import GraphAvatar from 'shared/ui/components/GraphAvatar'

import { RolesListItem } from '../../../app/store/administration/rolesListSlice'

function RemoveUser (props: {
  status: UserInRoleItem['status'],
  userId: string,
  roleId?: RolesListItem['roleId']|undefined,
}) {

  const dispatch = useAppDispatch()
  const handleRemoveUser = () => {
    props.roleId && dispatch(removeUser({
      roleId:props.roleId ,
      userId:props.userId,
    }))
  }

  return <Tooltip title="Remove user from role">
    <IconButton
      disabled={props.status === 'saving'}
      onClick={()=>{handleRemoveUser()}}
    >
      <Clear/>
    </IconButton>
  </Tooltip>
}

const RoleUsersList: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole)

  if (roleState.status === 'loading') {
    return (
      <List>
        {Array.from(Array(5).keys()).map((i) => {
          return (
            <ListItem
              key={i}
              divider
            >
              <ListItemAvatar>
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                />
              </ListItemAvatar>
              <Box sx={{ width: '100%' }}>
                <Skeleton />
              </Box>
            </ListItem>
          )
        })}
      </List>
    )
  }

  return (
    <List>
      {_.sortBy(roleState.users, (u:UserInRoleItem) => u.displayName ?? '').map((u) => {
        return (
          <ListItem
            key={u.userId}
            sx={{textBreak:'word-break'}}
            disabled={u.status === 'saving'}
          >
            <ListItemAvatar>
              <GraphAvatar userId={u.userId} />
            </ListItemAvatar>
            <ListItemText
              primary={u.displayName ?? ''}
            />
            <RemoveUser
              roleId={roleState.roleId}
              userId={u.userId}
              status={u.status}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

export default RoleUsersList

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import {
  type FC,
  ReactNode,
  useState,
} from 'react'

import {
  ModuleName,
  useFeatureSlicedDebug,
} from 'shared/lib/useFeatureSlicedDebug'

interface TextAreaWithActionsProps {
  sx?: Record<string, unknown>
  open?:boolean
  onCancel:()=>void
  onOK:(data:string)=>void
  title:string
  content:string
  setCurrentContent?:React.Dispatch<React.SetStateAction<string>>
  actionsSlot?:ReactNode[]
}

export const TextAreaWindowWithActions: FC<TextAreaWithActionsProps> = ({
  sx,
  onOK,
  onCancel,
  open,
  title,
  content,
  setCurrentContent,
  actionsSlot,
}) => {
  const [isOpen, setIsOpen] = useState(open||false)
  const onClose = ()=>{
    onCancel()
    setIsOpen(false)
  }

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('widget/TextAreaWindowWithActions' as ModuleName)
  // @ts-ignore
  return (
    <Box
      {...rootAttributes}
      sx={{...sx}}
    >

      <Dialog
        data-testid="yes-no-dialog"
        onClose={onClose}
        open={isOpen}
        sx={{'& .MuiPaper-root':{width:'800px'}}}
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>

        <DialogContent >
          <DialogContentText
            id="alert-dialog-description"
          >
            <Stack
              direction={'column'}
              gap={1}
            >
              <TextField
                value={content}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentContent && setCurrentContent(event.target.value)
                }}
                multiline={true}
                minRows={10}
              />
              <Stack
                direction={'row'}
              >
                {actionsSlot}
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{onOK(content)}}>
            OK
          </Button>
          <Button onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import {
  Fragment,
  type FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import graphApi from 'shared/api/GraphApi'
import { type GraphUser } from 'shared/model/contracts/graph/graph'
import GraphAvatar from 'shared/ui/components/GraphAvatar'

import { SearchBox } from '../units'

interface Props {
  open: boolean
  alreadyAddedUsersId: string[]
  onCancel: () => void
  onOk: (selectedUserId: string) => void
}

export const SelectUserDialog: FunctionComponent<Props> = (props) => {
  const [filter, setFilter] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [users, setUsers] = useState<GraphUser[]>([])
  const [selected, setSelected] = useState<GraphUser | null>(null)

  const loadUsers = (filter: string) => {
    if (filter === '') {
      setUsers([])
      return
    }

    setLoading(true)

    graphApi
      .findUsers(filter)
      .then((res) => {
        setUsers(res.value)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const loadUsersThrottled = useCallback(
    _.debounce((filter: string) => {
      loadUsers(filter)
    }, 600),
    [],
  )

  useEffect(() => {
    if (!props.open) {
      return
    }

    loadUsersThrottled(filter)
  }, [filter, props.open, loadUsersThrottled])

  useEffect(() => {
    setFilter('')
    setSelected(null)
    setLoading(false)
    setUsers([])
  }, [props.open])

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          {Array.from(Array(3).keys()).map((i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box sx={{ margin: 1 }}>
                <Skeleton variant="circular">
                  <Avatar />
                </Skeleton>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Skeleton width="100%">
                  <Typography>.</Typography>
                </Skeleton>
              </Box>
            </Box>
          ))}
        </>
      )
    }

    return (
      <List>
        {users.map((u) => {
          return (
            <ListItem
              key={u.id}
              dense
              button
              disabled={props.alreadyAddedUsersId.find((exists) => exists === u.id) !== undefined}
              selected={u === selected}
              sx={{ selected: { color: (theme) => theme.palette.action.selected } }}
              onClick={() => {
                setSelected(u)
              }}
            >
              <ListItemAvatar>
                <GraphAvatar userId={u.id} />
              </ListItemAvatar>
              <ListItemText
                primary={u.displayName}
                secondary={u.userPrincipalName}
              />
            </ListItem>
          )
        })}
      </List>
    )
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>
        <Box>
          <Typography gutterBottom>Select user</Typography>
          <SearchBox
            caption="Search user by name or mail"
            filter={filter}
            fullWidth
            onFilterChanged={(filter) => {
              setFilter(filter)
            }}
            onFilter={() => {
            }}
            onClear={() => {
              setFilter('')
            }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button
          onClick={props.onCancel}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          disabled={selected === null}
          onClick={() => {
            selected && props.onOk(selected.id)
          }}
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import {
  DialogTitle, Divider, Stack, Dialog, DialogActions, DialogContent, Collapse,
  Button, Typography, Snackbar,
} from '@mui/material'
import { FC, SyntheticEvent, useState } from 'react'

import { SelectableDescription } from '../../features/logistics/units/searchUnts'
import { themeSpacingFromPixels } from '../../shared/lib/workWithThemeSpacing'

interface Props {
  open: boolean
  description?: SelectableDescription
  replicateDescription: SelectableDescription
  setOpen: (open: boolean) => void
  onConfirm: (unitId: number, description: SelectableDescription)=> void
}

export const ConfirmationSingleDialog: FC<Props> = ({
  open,
  setOpen,
  onConfirm,
  description,
  replicateDescription,
}) => {
  const [openSnack, setSnackOpen] = useState(false)
  const handleDialogClose = (_: SyntheticEvent , reason: string) => {
    if (reason && (reason === "backdropClick" || reason ===  "escapeKeyDown"))
    {
      setOpen(false)
    }
  }

  const handleDialogSelect = () => {
    if(description){
      onConfirm(description.unitId,replicateDescription)
      setSnackOpen(true)
      setOpen(false)
    }
  }

  return (
    <Dialog
      sx={{'& .MuiDialog-paper': {maxWidth:themeSpacingFromPixels(450)}}}
      open={open}
      onClose={handleDialogClose}
    >
      <DialogTitle>Подтверждение действия</DialogTitle>
      <Divider/>
      <DialogContent>
        <Collapse in={true}>
          <Typography color="error">{`Вы уверены что хотите выполнить операцию распространения данных юнита ${replicateDescription?.elementId} на юнит ${description?.elementId}?`}</Typography>
        </Collapse>
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Stack
          direction={'row'}
          spacing={1}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleDialogSelect}
          >
            Подтвердить
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={()=> {
              setOpen(false)
            }}
          >
            Отказаться
          </Button>
        </Stack>
      </DialogActions>
      <Snackbar
        message="Воспроизведение в процессе"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={2000}
        onClose={() => {
          setSnackOpen(false)
        }}
        open={openSnack}
      />
    </Dialog>
  )
}

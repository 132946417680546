import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import { type RootState } from 'app/store/store'

import { selectSearchDialogIsOpen } from './searchDialogSlice'
import { type SelectableDescription } from './types'

const markedDescriptionsAdapter = createEntityAdapter<SelectableDescription>({
  selectId: (description) => description.elementId,
  //sortComparer: (a, b) => a.elementId.localeCompare(b.elementId),
})

export const MarkedDescriptionsSlice = createSlice({
  name: 'markedDescriptions',
  initialState: markedDescriptionsAdapter.getInitialState(),
  reducers: {
    addDescription: markedDescriptionsAdapter.addOne,
    addManyDescriptions: markedDescriptionsAdapter.addMany,
    removeDescription: markedDescriptionsAdapter.removeOne,
    removeAllDescriptions: markedDescriptionsAdapter.removeAll,

  },
})

export const markedDescriptionsSelectors = markedDescriptionsAdapter.getSelectors<RootState>(
  (state: RootState) => state.logistics.markedDescriptions,
)

const selectAllMarkedDescriptions = markedDescriptionsSelectors.selectAll

export const selectDescriptionsOnSearch = createSelector(
  [selectAllMarkedDescriptions, selectSearchDialogIsOpen],
  (description: SelectableDescription[], isSearchOpen: boolean): SelectableDescription[] | undefined => {
    if (!isSearchOpen) {
      return description
    }
    return undefined
  })

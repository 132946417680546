import {
  createAsyncThunk,
  createSlice,
  type SerializedError,
} from '@reduxjs/toolkit'

import unitsApi from 'shared/api/UnitsApi'

import { type RootState } from '../store'

export interface UnitsDrawingsState {
  status: 'idle' | 'loading' | 'failed'
  blob?: string
  error?: SerializedError
}

const initialState: UnitsDrawingsState = {status: 'idle'}

export const downloadDrawings = createAsyncThunk('units-drawings/download', async (filter: string) => {
  return await unitsApi
    .downloadOutlineDrawings(filter)
    .then((res) => {
      if (res.status === 204) {
        throw new Error('No outline drawings')
      }
      return res
    })
    .then(async (res) => await res.blob())
    .then((blob) => blob && window.URL.createObjectURL(blob))
})

export const unitsDrawingsSlice = createSlice({
  name: 'units-drawings',
  initialState,
  reducers: {
    clearDrawingsBlob: (state) => {
      state.blob = undefined
    },
  },
  extraReducers: (build) => {
    build.addCase(downloadDrawings.pending, (state) => {
      state.status = 'loading'
      state.error = undefined
    })

    build.addCase(downloadDrawings.fulfilled, (state, action) => {
      state.status = 'idle'
      state.blob = action.payload
    })

    build.addCase(downloadDrawings.rejected, (state, action) => {
      state.status = 'idle'
      state.error = action.error
    })
  },
})

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUnitsDrawings = (state: RootState) => state.units.list.drawings

export const {
  clearDrawingsBlob,
} = unitsDrawingsSlice.actions

export default unitsDrawingsSlice.reducer

import { Skeleton, Typography } from '@mui/material'
import type React from 'react'
import {  type FunctionComponent, useEffect, useState } from 'react'

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  urlwithtoken: string
}

const ImageViewer: FunctionComponent<Props> = (props) => {
  const [imgSrc, setImgSrc] = useState(props.urlwithtoken || undefined)
  const [error, setError] = useState<string | undefined>(undefined)

  function errorHandler (event: string | Event): void {
    if (event instanceof Event) {
      if (event.type === 'error') {
        setError('An error occurred while loading image')
      }
    }
  }

  useEffect(() => {
    setImgSrc(undefined)
    setError(undefined)

    const img = new Image()
    img.src = props.urlwithtoken
    img.onload = () => {
      setImgSrc(props.urlwithtoken)
    }
    img.onerror = (event) => {
      errorHandler(event)
    }
  }, [props.urlwithtoken])

  return (
    <>
      {!error && !imgSrc && <Skeleton
        width="100%"
        height="100%"
      />}
      {Boolean(error) && <Typography color="error">{error}</Typography>}
      {Boolean(imgSrc) && <img
        {...props}
        src={imgSrc}
        alt=""
      />}
    </>
  )
}

export default ImageViewer

import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import unitsApi from 'shared/api/UnitsApi'
import { type InitialsFile } from 'shared/model/contracts/InitialsFile'
import {SearchUnitDto} from 'shared/model/contracts/UnitDto'

import { type RootState } from '../store'

const name = 'units-initials-dialog'

export interface UnitsInitailsDialogState {
  open: boolean
  fetchStatus: 'idle' | 'pending'
  downloadStatus: 'prepare' | 'pending' | 'idle'
  loaded: boolean
  previewId?: number
  previewOpened: boolean
  unit: InitialsUnitData
  fileList?: InitialsFile[]
  error?: SerializedError
}

const initialState: UnitsInitailsDialogState = {
  open: false,
  fetchStatus: 'pending',
  downloadStatus: 'prepare',
  previewOpened: false,
  loaded: false,
  unit: {
    unitFileId: 0,
    elementId: '',
    oemArticle:'',
  },
  fileList: [],
}

type InitialsUnitData = Pick<SearchUnitDto, 'unitFileId' | 'elementId' | 'oemArticle'>

export const loadInitials = createAsyncThunk(`${name}/initialsContent`, async (payload: { unitId: number }) => {
  return await unitsApi.getInitialsContent(payload.unitId)
})

export const downloadInitials = createAsyncThunk(
  `${name}/downloadInitials`,
  async (payload: { unitId: number, files: Array<{ fileId: number, fileName: string, version: number }> }) => {
    return await unitsApi
      .downloadInitials(payload.unitId, payload.files)
      .then((res) => {
        if (res.status === 204) {
          throw new Error('No initials')
        }
        return res
      })
      .then(async (res) => await res.blob())
  },
)

const slice = createSlice({
  name,
  initialState,
  reducers: {
    open: (state, action: PayloadAction<InitialsUnitData>) => {
      state.unit = action.payload
      state.open = true;
    },

    close: (state) => {
      state.open = false
      state.unit = {
        ...state.unit,
        unitFileId: 0,
        elementId: '',
      }
      state.fileList = []
      state.loaded = false
      state.error = undefined
    },

    changeChecked: (state, action: PayloadAction<{ fileId: number, value: boolean }>) => {
      const file = state.fileList?.find((f) => f.id === action.payload.fileId)
      if (file) {
        file.checked = action.payload.value
      }
    },

    changeCheckedAll: (state, action: PayloadAction<boolean>) => {
      state.fileList?.map((f) => {
        f.checked = action.payload
      })
    },

    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload
    },

    setPreviewId: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        previewId: action.payload,
      }
    },

    setPreviewOpened: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        previewOpened: action.payload,
      }
    },
  },

  extraReducers: (build) => {
    build.addCase(loadInitials.pending, (state) => {
      return {
        ...state,
        fetchStatus: 'pending',
      }
    })

    build.addCase(loadInitials.fulfilled, (state, action) => {
      if (action.payload) {
        if (action.payload.files && action.payload.files.length !== 0) {
          state.fileList = action.payload.files.map((f) => ({
            id: f.id,
            fileName: f.fileName,
            version: f.version,
            relativeFolderPath: f.relativeFolderPath,
            folderId: f.folderId,
            mimeType: f.mimeType,
            checked: false,
          }))
        }

        if (action.payload.error) {
          state.error = new Error(action.payload.error)
        }
      }
      state.fetchStatus = 'idle'
    })

    build.addCase(loadInitials.rejected, (state, payload) => {
      return {
        ...state,
        fetchStatus: 'idle',
        error: payload.error,
      }
    })

    build.addCase(downloadInitials.pending, (state) => {
      return {
        ...state,
        downloadStatus: 'pending',
      }
    })

    build.addCase(downloadInitials.fulfilled, (state) => {
      return {
        ...state,
        downloadStatus: 'idle',
      }
    })

    build.addCase(downloadInitials.rejected, (state, payload) => {
      return {
        ...state,
        downloadStatus: 'idle',
        error: payload.error,
      }
    })
  },
})

export const {
  open,
  close,
  changeChecked,
  changeCheckedAll,
  setPreviewId,
  setPreviewOpened,
  setLoaded,
} = slice.actions
export const selectInitialsDialogState = (state: RootState) => state.units.list.initialsDialog
export default slice.reducer

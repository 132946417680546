import {
  useEffect,
  useState,
} from 'react'

import { useAppSelector } from 'app/store'

import {
  descriptionFieldChecksSelectors,
  DocumentFieldCheck,
} from 'features/logistics/downloadTechnicalDescriptions'

import {
  TechnicalCardApproveState,
  technicalCardsApi,
  UnitTechnicalDescription,
} from 'entities/logistics/TechnicalCard'

import { KdStatus } from '../model/contracts/UnitDto'

export function useGetUnitApproveState (unitID: UnitTechnicalDescription['unitId']): TechnicalCardApproveState | undefined {

  const {
    data,
    isSuccess,
  } = technicalCardsApi.useGetUnitDescriptionsQuery([unitID])
  const [unitState, setUnitState] = useState<TechnicalCardApproveState | undefined>(undefined)

  useEffect(() => {
    if (data?.length && data[0]?.state) {
      // @ts-ignore
      const t = TechnicalCardApproveState[data[0]?.state]
      setUnitState(t)
    }
  }, [isSuccess, data])
  return unitState
}

export function useIsUnitHasEmptyNecessaryFields (unitId: UnitTechnicalDescription['unitId']) {
  const errorInFields: DocumentFieldCheck | undefined = useAppSelector(state => descriptionFieldChecksSelectors.selectById(state, unitId || 0))
  const [e, setE] = useState<string[]>([])

  useEffect(() => {
    if (errorInFields) {
      const errors = Object
        .keys(errorInFields?.fieldCheckCorrect)
        ?.reduce((acc: string[], key) => {
          // @ts-ignore
          if (!errorInFields?.fieldCheckCorrect[key]) {
            acc.push(key)
          }
          return acc
        }, [])

      setE(errors)
    }
  }, [errorInFields])

  return !!e?.length
}

import {
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { type RootState } from 'app/store/store'

import { type UnitTechnicalDescription } from 'entities/logistics/TechnicalCard'

export enum CheckFields {
  materials = 'materials',
  purpose = 'purpose',
  manufacturingMethods = 'manufacturingMethods',
  equipmentType = 'equipmentType',
  images = 'images',
}

export type CheckFieldsSet = Record<CheckFields, boolean>

export interface DocumentFieldCheck {
  unitId: UnitTechnicalDescription['unitId']
  fieldCheckCorrect: CheckFieldsSet
}

const descriptionFieldChecksAdapter = createEntityAdapter<DocumentFieldCheck>({
  selectId: (documentCheck) => documentCheck
    ? documentCheck?.unitId
      ? documentCheck?.unitId
      : 0
    :0,
  sortComparer: (a, b) => {
    const a1:number = a
      ? a.unitId
        ? a.unitId
        : 0
      :0
    const b1:number = b
      ? b.unitId
        ? b.unitId
        : 0
      :0
    return a1 - b1
  },
})

export const DescriptionFieldChecksSlice = createSlice({
  name: 'descriptionFieldChecks',
  initialState: descriptionFieldChecksAdapter.getInitialState(),
  reducers: {
    addMany (state, action: { payload: UnitTechnicalDescription[] | undefined, type: string }) {
      const unitsData: UnitTechnicalDescription[] | undefined = action.payload

      if (unitsData) {
        const checkedUnitsData: DocumentFieldCheck[] = unitsData.map((i) => ({
          unitId: i.unitId,
          fieldCheckCorrect: checkUnitDescriptionFields(i),
        }))
        descriptionFieldChecksAdapter.upsertMany(
          state,
          checkedUnitsData)
      }
    },
    addOne (state, action: { payload: UnitTechnicalDescription | undefined, type: string }) {

      const unitData: UnitTechnicalDescription | undefined = action.payload

      if (unitData) {
        const checkedData: DocumentFieldCheck = {
          unitId: unitData.unitId,
          fieldCheckCorrect: checkUnitDescriptionFields(unitData),
        }
        descriptionFieldChecksAdapter.addOne(
          state,
          checkedData)
      }
    },
    removeAll: descriptionFieldChecksAdapter.removeAll,
    removeOne: descriptionFieldChecksAdapter.removeOne,
  },
})

export const descriptionFieldChecksSelectors = descriptionFieldChecksAdapter.getSelectors((state: RootState) => state.logistics.descriptionFieldChecks)

function checkUnitDescriptionFields ({
  images,
  manufacturingMethods,
  equipmentType,
  purpose,
  materials,
}: UnitTechnicalDescription): CheckFieldsSet {
  const checkResult: CheckFieldsSet = {
    [CheckFields.images]: false,
    [CheckFields.manufacturingMethods]: false,
    [CheckFields.equipmentType]: false,
    [CheckFields.purpose]: false,
    [CheckFields.materials]: false,
  }

  if (equipmentType) {
    checkResult.equipmentType = true
  }

  if (purpose) {
    checkResult.purpose = true
  }

  if (images?.length) {
    checkResult.images = true
  }

  if (manufacturingMethods?.length) {
    checkResult.manufacturingMethods = true
  }

  if (materials?.length) {
    checkResult.materials = true
  }

  return checkResult
}

import { useMsal } from '@azure/msal-react'
import {
  Switch,
  Tooltip,
} from '@mui/material'
import {
  Fragment,
  type FunctionComponent,
} from 'react'

import {
  updateEnableLogin,
  type UserListItem,
} from 'app/store/administration/usersListSlice'
import { useAppDispatch } from 'app/store/hooks'

interface Props {
  user: UserListItem
}

const EnableLoginSwitch: FunctionComponent<Props> = (props) => {
  const {
    accounts, 
  } = useMsal()
  const accountId = accounts[0] && accounts[0].localAccountId

  const dispatch = useAppDispatch()

  const handleChange = (value: boolean) => {
    dispatch(updateEnableLogin({
      userId: props.user.userId,
      enableLogin: value,
    }))
  }

  if (props.user.userId === accountId) {
    return <></>
  }

  return (
    <Tooltip title={props.user.enableLogin
      ? 'Disable login'
      : 'Enable login'}
    >
      <Switch
        checked={props.user.enableLogin}
        disabled={props.user.status === 'saving'}
        onChange={(e) => {
          handleChange(e.target.checked)
        }}
      />
    </Tooltip>
  )
}

export default EnableLoginSwitch

import MoreVertIcon from '@mui/icons-material/MoreVert'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import type React from 'react'
import { type FC, useState } from 'react'

import { type ImageAction } from '../model/types'

interface ImageActionMenuProps {
  imageActions?: ImageAction[]
}

export const ImageActionMenu: FC<ImageActionMenuProps> = ({
  imageActions, 
}) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id='image-button'
        aria-controls={open
          ? 'image-menu'
          : undefined}
        aria-haspopup="true"
        aria-expanded={open
          ? 'true'
          : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon/>
      </Button>
      <Menu
        id="image-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'image-button'}}
      >
        { imageActions &&
          imageActions.map((menuAction) => (
            <MenuItem
              key={menuAction.actionName}
              onClick={menuAction.action}
            >{menuAction.actionName}</MenuItem>
          ))
        }
      </Menu>
    </div>)

}

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  type FC,
  ReactNode, useMemo,
} from 'react'

import { logisticsTheme } from 'app/config/theme/logisticsTheme'

import { useIsUnitHasEmptyNecessaryFields } from 'shared/lib/logistics'

import {
  TechnicalCardApproveState,
  UnitTechnicalDescription,
} from '../model/types'

type ApproveStatusProps = {
  unit: UnitTechnicalDescription
  isExpanded: boolean
}

enum ApproveStatusType {
  approve = 'approve',
  disapprove = 'disapprove',
  needInfo = 'needInfo',
}

type StatusOptions = {
  text:string,
  color:string
}

const ApproveStatusMessages:Record<ApproveStatusType,StatusOptions>={
  [ApproveStatusType.approve]:{
    text:'Утверждено',
    color:'green',
  },
  [ApproveStatusType.disapprove]:{
    text:'На проверке',
    color:'orange',
  },
  [ApproveStatusType.needInfo]:{
    text:'Не заполнено',
    color:'red',
  },
}
const TCstatusesToApprovedStatus : Record<TechnicalCardApproveState,Partial<ApproveStatusType>> = {
  [TechnicalCardApproveState.Approved]:ApproveStatusType.approve,
  [TechnicalCardApproveState.Draft]:ApproveStatusType.disapprove,
  [TechnicalCardApproveState.Rejected]:ApproveStatusType.disapprove,
}

const statusIcons: Record<ApproveStatusType | string, ReactNode> = {
  [ApproveStatusType.approve]: <CheckCircleOutlineIcon
    fontSize={'small'}
    color={'success'}
  />,
  [ApproveStatusType.disapprove]: <DoDisturbIcon
    fontSize={'small'}
    color={'error'}
  />,
  [ApproveStatusType.needInfo]: <QuestionMarkIcon
    fontSize={'small'}
    color={'warning'}
  />,
  ['' + ApproveStatusType.approve + 'expanded']: <CheckCircleOutlineIcon
    fontSize={'small'}
    htmlColor={logisticsTheme.palette.secondary.contrastText}
  />,
  ['' + ApproveStatusType.disapprove + 'expanded']: <DoDisturbIcon
    fontSize={'small'}
    htmlColor={logisticsTheme.palette.secondary.contrastText}
  />,
  ['' + ApproveStatusType.needInfo + 'expanded']: <QuestionMarkIcon
    fontSize={'small'}
    htmlColor={logisticsTheme.palette.secondary.contrastText}
  />,
}

function getStatusMessageData (unit:UnitTechnicalDescription,isHasErrorFields:boolean): {
  currentStatus:string,
  currentMessage:string,
  currentMessageColor:string,
} {

  let currentStatus:ApproveStatusType = ApproveStatusType.needInfo
  let currentMessage = ''
  let currentMessageColor = 'white'

  if (isHasErrorFields){
    currentStatus = ApproveStatusType.needInfo
    currentMessageColor = ApproveStatusMessages[currentStatus].color
    currentMessage = ApproveStatusMessages.needInfo.text
  }else {
    if (unit?.state) {
      // @ts-expect-error
      currentStatus = TCstatusesToApprovedStatus[unit.state]
      currentMessage = ApproveStatusMessages[currentStatus].text
      currentMessageColor = ApproveStatusMessages[currentStatus].color
    }
  }
  return {
    currentStatus,
    currentMessage,
    currentMessageColor,
  }
}

export const ApproveStatus: FC<ApproveStatusProps> = ({
  unit, isExpanded,
}) => {
  const isHasErrorFields = useIsUnitHasEmptyNecessaryFields(unit.unitId)
  const statusMessage = useMemo(()=> getStatusMessageData(unit,isHasErrorFields), [unit,isHasErrorFields])

  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'center'}
      sx={{
        color: isExpanded ?
          'white'
          : statusMessage.currentMessageColor ,
      }}
    >
      {isExpanded
        ? statusIcons['' + statusMessage.currentStatus + 'expanded']
        : statusIcons[statusMessage.currentStatus]
      }
      <Typography
        variant="caption"
      >
        {statusMessage.currentMessage}
      </Typography>
    </Stack>
  )
}

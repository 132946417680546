import {
  type FC,
  type ReactNode,
} from 'react'
import { NavLink } from 'react-router-dom'

interface StyledNavLinkProps {
  to: string
  children: ReactNode
  onClick?: () => void
  style?: React.CSSProperties
}

export const StyledNavLink: FC<StyledNavLinkProps> = ({
  to,
  children,
  onClick,
  style,
}) => {
  return <NavLink
    onClick={onClick}
    style={{
      display: 'flex',
      flex: 'auto',
      ...style,
    }}
    to={to}
    end
  >
    {children}
  </NavLink>
}

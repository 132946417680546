import { type InitailsFileDto } from '../model/contracts/InitialsFile'
import {
  type PrintDocumentsRequest,
  type PrintUnitsDocumentsRequest,
} from '../model/contracts/print-documents'
import { ReportDto } from '../model/contracts/ReportDto'
import { type UnitSearchResponse } from '../model/contracts/UnitDto'

import { API_ADDRESS } from './api'
import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

export const UNITS_API_ADDRESS = `${API_ADDRESS}/api/units`

export interface LoadInitialsResponse {
  files: InitailsFileDto[]
  error: string
}

export class UnitsApi {
  async getUnits (
    filter: string, page: number, mfg: boolean, startDate?: string, endDate?: string, pageSize?: number,
    sortOrder?: string) {
    const result = await authService.getIdentity()
    if (result) {
      return await msalFetch()
        .post(`${UNITS_API_ADDRESS}`, result.accessToken, {
          filter,
          page,
          pageSize,
          startDate,
          endDate,
          sortOrder,
          mfg,
        })
        .then(handleErrors)
        .then<UnitSearchResponse>(async (res) => await res.json())
    }
  }

  async getReports () {
    const result = await authService.getIdentity()
    if (result) {
      return await msalFetch()
        .get(`${UNITS_API_ADDRESS}/report`, result.accessToken)
        .then(handleErrors)
        .then<ReportDto[]>(async (res) => await res.json())
    }
  }

  async downloadReport (filter: string, type: string, startDate?: string, endDate?: string) {
    const result = await authService.getIdentity()
    if (result) {
      return await msalFetch()
        .post(`${UNITS_API_ADDRESS}/report`, result.accessToken, {
          filter,
          type,
          startDate,
          endDate,
        })
        .then(handleErrors)
        .then(async (res) => await res.blob())
    }
  }

  async downloadInspectionCards (filter: string) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .post(`${UNITS_API_ADDRESS}/files/inspectioncards`, result.accessToken, { filter })
      .then(handleErrors)
  }

  async downloadOutlineDrawings (filter: string) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .post(`${UNITS_API_ADDRESS}/files/drawings/outline`, result.accessToken, { filter })
      .then(handleErrors)
  }

  async print (unitId: number, request: PrintDocumentsRequest) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .post(`${UNITS_API_ADDRESS}/${unitId}/print`, result.accessToken, request)
      .then(handleErrors)
  }

  async printdrawings (request: PrintUnitsDocumentsRequest) {
    const result = await authService.getIdentity()
    return await msalFetch().post(`${UNITS_API_ADDRESS}/print`, result.accessToken, request).then(handleErrors)
  }

  async printTypes (unitId: number) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .get(`${UNITS_API_ADDRESS}/${unitId}/printtypes`, result.accessToken)
      .then(handleErrors)
      .then<string[]>(async (res) => await res.json())
  }

  async getInitialsContent (unitId: number) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .get(`${UNITS_API_ADDRESS}/${unitId}/files/initials`, result.accessToken)
      .then(handleErrors)
      .then<LoadInitialsResponse>(async (res) => await res.json())
  }

  async downloadInitials (unitId: number, files: Array<{ fileId: number, fileName: string, version: number }>) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .post(`${UNITS_API_ADDRESS}/${unitId}/files/initials/download`, result.accessToken, { files })
      .then(handleErrors)
  }
}

const unitsApi = new UnitsApi()

export default unitsApi

import { type CatalogRecord } from 'entities/logistics/Catalog'

import { type CreateRecordElement } from '../model/types'

export const isNoDuplicates = (
  currentData: CatalogRecord[],
  checkedElementData: CreateRecordElement | CatalogRecord,
  isSuccess: boolean,
): boolean => {
  if (isSuccess) {

    const foundIndex = currentData.find((data: CatalogRecord) => {

      const valueSimilar = data.value.toLowerCase().trim() === checkedElementData.value.toLowerCase().trim()
      let idSimilar = false
      if (isCatalogRecord(checkedElementData)) {
        idSimilar = data.id === checkedElementData.id
      }
      return valueSimilar && !idSimilar
    })

    return typeof foundIndex === 'undefined'
  }
  return false
}

function isCatalogRecord (value: CatalogRecord | CreateRecordElement): value is CatalogRecord {
  return (value as CatalogRecord).id !== undefined && (value as CatalogRecord).value !== undefined
}

import { Box } from '@mui/material'
import {
  type FC,
  ReactNode,
  useState, useEffect,
} from 'react'

import { useAppSelector } from 'app/store/hooks'

import { SelectPurposes } from 'features/logistics/selectPurposes'
import { type SelectableDescription } from 'features/logistics/units/searchUnts'
import { selectDescriptionsOnSearch } from 'features/logistics/units/searchUnts/model/selectableDescriptions'

import { AvailableUnitCatalogFields } from 'entities/logistics/Catalog/model/types'
import {
  type UnitTechnicalDescription,
  useGetUnitDescriptionsQuery,
} from 'entities/logistics/TechnicalCard'

import { TextAreaWindowWithActions } from '../TextAreaWindowWithActions'

import { EditableFieldTextData } from './EditableFieldTextData'

interface EditableAreaTextFieldWithActionsProps {
  fieldName: string
  readonly?: boolean
  unitId: UnitTechnicalDescription['unitId']
  unitFieldName: AvailableUnitCatalogFields | 'purpose'
  warning?: boolean
  title?: string
  changeDataHandler: (data: any) => void
  actionsSlot?: ReactNode[]
}

export const EditableAreaTextFieldWithActions: FC<EditableAreaTextFieldWithActionsProps> = ({
  fieldName,
  unitId,
  readonly,
  unitFieldName,
  warning = false,
  changeDataHandler,
  title = '',
}) => {

  const markedUnits: SelectableDescription[] | undefined = useAppSelector(selectDescriptionsOnSearch)
  const markedUnitsId = markedUnits?.map(unit => unit.unitId) || []
  // @ts-ignore
  const curUnit: UnitTechnicalDescription = useGetUnitDescriptionsQuery(markedUnitsId, {
    skip: !markedUnits,
    // @ts-ignore
    selectFromResult: ({
      data,
    }): UnitTechnicalDescription | undefined => {
      if (!data) {
        return undefined
      }
      return data?.find(description => description.unitId === unitId)
    },
  })

  const [currentContent, setCurrentContent] = useState<string>(String(curUnit[unitFieldName] || ''))

  useEffect(() => {
    setCurrentContent(String(curUnit[unitFieldName] || ''))
  }, [curUnit[unitFieldName]])

  const [onEditing, setOnEditing] = useState(false)

  return (
    <>
      <EditableFieldTextData
        fieldName={fieldName}
        warning={warning}
        readonly={readonly}
        onClick={
          () => {
            setOnEditing(true)
          }
        }
        dataValue={String(curUnit[unitFieldName] || '')}
      />
      {onEditing &&
        <Box sx={{ width: 'auto' }}>
          <TextAreaWindowWithActions
            open={onEditing}
            onCancel={() => {
              setOnEditing(false)
            }}
            onOK={(data: string | undefined) => {
              changeDataHandler(data)
              setOnEditing(false)
            }}
            title={title}
            content={currentContent}
            setCurrentContent={setCurrentContent}
            actionsSlot={[
              <SelectPurposes
                unitId={curUnit?.unitId}
                currentContent={currentContent}
                setCurrentContent={setCurrentContent}
              />,
            ]}
          />
        </Box>
      }
    </>
  )
}

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material'
import { type FunctionComponent } from 'react'

import {
  hideEditRightsDialog,
  selectEditRole,
  setRights,
  showEditRightsDialog,
} from 'app/store/administration/roleSlice'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

import RoleRightsEditor from './RoleRightsEditor'
import RoleRightsList from './RoleRightsList'

const RoleRightsCard: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole)
  const dispatch = useAppDispatch()

  const renderButtons = () => {
    return (
      roleState.status === 'idle' && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => dispatch(showEditRightsDialog())}
        >
          Edit
        </Button>
      )
    )
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title="Role rights"
        action={renderButtons()}
      />
      <Divider />
      <CardContent>
        <RoleRightsList />
      </CardContent>

      {roleState.openEditRightsDialog && (
        <RoleRightsEditor
          open={roleState.openEditRightsDialog}
          onCancel={() => dispatch(hideEditRightsDialog())}
          onOk={(rights) => {
            dispatch(setRights(rights))
            dispatch(hideEditRightsDialog())
          }}
        />
      )}
    </Card>
  )
}

export default RoleRightsCard

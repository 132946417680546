import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import fuzzysort from 'fuzzysort'
import type React from 'react'
import {
  type FC,
  useState,
  useEffect,
  type ChangeEvent,
} from 'react'
import { useDebounce } from 'usehooks-ts'

import { ecTheme } from 'app/config/theme/ecTheme'

import { type CatalogRecord } from 'entities/logistics/Catalog'

import { themeSpacingFromPixels } from '../../../lib/workWithThemeSpacing'

export interface SelectableItem {
  isSelected: boolean
  record: CatalogRecord
}

interface SelectMultipleItemsProps {
  title?: string
  selectedValue: CatalogRecord | undefined
  onSaveAction: (data: CatalogRecord | undefined) => void
  catalogValues: CatalogRecord[] | undefined
  showId?: boolean
}

interface HighlightedCatalogRecord {
  id: number
  value: string | Element
}

export const SelectSingleItem: FC<SelectMultipleItemsProps> = (
  {
    catalogValues,
    selectedValue,
    onSaveAction,
    title,
  }) => {

  const [isOpen, setIsOpen] = useState(true)
  const [dialogItems, setDialogItems] = useState<CatalogRecord[] | HighlightedCatalogRecord[]>(catalogValues ?? [])
  const [radioSelectedValue, setRadioSelectedValue] = useState(selectedValue?.id)
  const [searchString, setSearchString] = useState<string>('')
  const debouncedSearchString = useDebounce<string>(searchString, 500)

  function getCurrentSelectedValue () {
    return dialogItems?.find(i => i.id === radioSelectedValue)
  }

  const handleSave = (): void => {
    const t = getCurrentSelectedValue() as CatalogRecord
    onSaveAction(t)
    setIsOpen(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRadioSelectedValue(Number((event.target as HTMLInputElement).value))
  }

  const handleCancel = (): void => {
    onSaveAction(selectedValue)
    setIsOpen(false)
  }

  const handleSearchStringChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }
  const startFilter = (filterString: string): void => {
    if (!filterString) {
      setDialogItems(catalogValues ?? [])
      return
    }

    const foundValues: Fuzzysort.KeyResults<CatalogRecord> = fuzzysort.go<CatalogRecord>(filterString,
      catalogValues ?? [], { key: 'value' })
    const filteredValues = foundValues
      .map(curreV => {
        const highlightValue = fuzzysort.highlight(curreV, (match, index) => {
          return <Typography
            key={index}
            display="inline"
            component="span"
            sx={{color: ecTheme.palette.error.main}}
          >{match}</Typography>
        })
        return {
          id: curreV.obj.id,
          value: highlightValue || '',
        }
      })
    // @ts-ignore
    setDialogItems(filteredValues)
  }

  useEffect(() => {
    startFilter(debouncedSearchString)
  }, [debouncedSearchString])

  return (
    <Dialog
      scroll={'paper'}
      open={isOpen}
      onClose={() => {
        onSaveAction(selectedValue)
        setIsOpen(false)
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Search"
          value={searchString}
          onChange={handleSearchStringChange}
          autoFocus={true}
          sx={{marginTop: themeSpacingFromPixels(8)}}
        />
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={radioSelectedValue}
            onChange={handleChange}
          >
            {dialogItems?.map((item: CatalogRecord | HighlightedCatalogRecord) => {
              const v = item.value as string
              return <FormControlLabel
                key={item.id}
                value={item.id}
                control={<Radio/>}
                label={v}
              />
            },
            )}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          sx={{flex: 'auto'}}
        >
          <Button
            startIcon={<ClearOutlinedIcon/>}
            size="small"
            variant="outlined"
            onClick={handleCancel}
          >
            <Typography variant="body1">Cancel</Typography>
          </Button>
          <Button
            startIcon={<SaveOutlinedIcon/>}
            size="small"
            variant="contained"
            onClick={handleSave}
          >
            <Typography variant="body1">Save</Typography>
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

SelectSingleItem.defaultProps = {
  title: 'Chose items',
  showId: false,
}

import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import unitsApi from 'shared/api/UnitsApi'
import { type PrintUnitsDocumentsRequest } from 'shared/model/contracts/print-documents'

import { type RootState } from '../store'

const name = 'units-print-outline-dialog'

interface UnitsPrintOutlineDialogState {
  error?: SerializedError
  fetchStatus: 'idle' | 'pending' | 'rejected'
  open: boolean
  outlineDrawings: boolean
  replaceDesignId: boolean
  replaceDesignIdByProductionId: boolean
  replaceLogo: boolean
  status: 'prepare' | 'sending' | 'completed' | 'error'
}

export const initialState: UnitsPrintOutlineDialogState = {
  open: false,
  status: 'prepare',
  fetchStatus: 'idle',
  replaceDesignId: false,
  replaceDesignIdByProductionId: false,
  replaceLogo: false,
  outlineDrawings: false,
}

export const printdrawings = createAsyncThunk(
  `${name}/print`,
  async (payload: { request: PrintUnitsDocumentsRequest }) => {
    return await unitsApi.printdrawings(payload.request)
  },
)

const slice = createSlice({
  name,
  initialState,
  reducers: {
    open: (_) => {
      return {
        ...initialState,
        open: true,
      }
    },

    close: (state) => {
      return {
        ...state,
        open: false,
      }
    },

    changeReplaceDesignId: (state, action: PayloadAction<boolean>) => {
      state.replaceDesignId = action.payload
    },

    changeReplaceDesignIdByProductionId: (state, action: PayloadAction<boolean>) => {
      state.replaceDesignIdByProductionId = action.payload
    },

    changeReplaceLogo: (state, action: PayloadAction<boolean>) => {
      state.replaceLogo = action.payload
    },

    changeOutlineDrawings: (state, action: PayloadAction<boolean>) => {
      state.outlineDrawings = action.payload
    },

  },
  extraReducers: (build) => {
    build.addCase(printdrawings.pending, (state) => {
      return {
        ...state,
        status: 'sending',
      }
    })

    build.addCase(printdrawings.fulfilled, (state) => {
      return {
        ...state,
        status: 'completed',
      }
    })

    build.addCase(printdrawings.rejected, (state, payload) => {
      return {
        ...state,
        status: 'error',
        error: payload.error,
      }
    })
  },
})

export const {
  open,
  close,
  changeReplaceDesignId,
  changeReplaceDesignIdByProductionId,
  changeReplaceLogo,
  changeOutlineDrawings,
} = slice.actions
export const selectPrintOutlineDialogState = (state: RootState) => state.units.list.printOutlineDialog
export const selectPrintFetchStatus = (state: RootState) => state.units.list.printOutlineDialog.fetchStatus
export default slice.reducer

import {
  type CSSProperties,
  type HTMLAttributes,
} from 'react'

import { isHighlightOn } from 'app/config/fsdConfig'

import { kebabize } from './toKebabCase'

type CustomCSSProperties = {
  '--fsd-background-color': string
  '--fsd-color': string
} & CSSProperties

type CustomHTMLAttributes<T> = {
  'data-fsd'?: string
  'data-testid'?: string
} & HTMLAttributes<T>

enum Layer {
  widget = 'widget',
  feature = 'feature',
  entity = 'entity',
  shared = 'shared',
}

export type ModuleName = `${Layer}/${string}`

const colorMap: Record<Layer, string> = {
  widget: '#fa0ee9',
  feature: '#14a200',
  entity: '#2573e5',
  shared: '#ef4b0f',
} as const

export function useFeatureSlicedDebug<T extends HTMLElement = HTMLDivElement> (
  name: ModuleName,
) {
  const isDebugMode = isHighlightOn
  const rootAttributes: CustomHTMLAttributes<T> = {}
  const [layer] = name.split('/') as [Layer]

  rootAttributes['data-testid'] = kebabize(name).replace('/', '-')

  if (isDebugMode) {
    rootAttributes['data-fsd'] = name
    rootAttributes.style = {
      '--fsd-color': colorMap[layer],
      '--fsd-background-color': `${colorMap[layer]}10`,
    } as CustomCSSProperties
  }

  return {rootAttributes}
}

import { Clear } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import SendIcon from '@mui/icons-material/Send'
import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material'
import {
  type SxProps,
  type Theme, Box,
} from '@mui/system'
import type React from 'react'
import {
  type FunctionComponent,
  type ReactNode,
} from 'react'

import { baseTheme } from '../../../app/config/theme/baseTheme'
import { ecTheme } from '../../../app/config/theme/ecTheme'
import { UnitsFilterState, useAppSelector, selectUnitsFilters } from '../../../app/store'
import { themeSpacingFromPixels } from '../../../shared/lib/workWithThemeSpacing'
import { mfgSyntaxHelperText, commonSyntaxHelperText } from '../../../widgets/UnitSearchWidgetConstants'

interface SearchBoxProps {
  caption?: string
  helperText?: string
  placeholder?: string
  filter?: string
  width?: number
  fullWidth?: boolean
  endAdornment?: ReactNode
  size?: 'small' | 'medium'
  sx?: SxProps<Theme>
  autoFocus?: boolean
  onFilterChanged: (filter: string) => void
  onFilter: () => void
  onFocus?: () => void
  onBlur?: () => void
  onClick?: () => void
  onClear?: () => void
}

export const SearchBox: FunctionComponent<SearchBoxProps> = (props) => {
  const filtersState: UnitsFilterState = useAppSelector(selectUnitsFilters)
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      props.onFilter()
    }
  }

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.onFilterChanged(event.target.value)
  }

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onFocus && props.onFocus()
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur && props.onBlur()
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    props.onClick && props.onClick()
  }

  const handleClickClear = () => {
    props.onClear && props.onClear()
  }

  return (

    <TextField
      sx={{
        [baseTheme.breakpoints.down(baseTheme.breakpoints.values.md)]: { width: '100%' },
        [baseTheme.breakpoints.up(baseTheme.breakpoints.values.md)]: { width: 'inherit' },
        '& .MuiOutlinedInput-input': { padding: themeSpacingFromPixels(10, 0) },
      }}
      fullWidth={props.fullWidth}
      label={props.caption ?? ''}
      helperText={props.helperText ?? ''}
      placeholder={props.placeholder ?? ''}
      size={props.size}
      value={props.filter ?? ''}
      autoFocus={props.autoFocus}
      onChange={handleSearchTextChange}
      InputProps={{
        startAdornment: (
          <Box
            mr={1}
          >
            <Tooltip

              title={
                <div style={{ whiteSpace: 'pre-line' }}>{
                  filtersState.mfg
                    ? mfgSyntaxHelperText
                    : commonSyntaxHelperText
                }</div>

              }
            >
              <SearchIcon color="action"/>
            </Tooltip>
          </Box>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {(props.filter?.length ?? 0) > 0 && (
              <Tooltip title="Clear">
                <IconButton onClick={handleClickClear}>
                  <Clear/>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Search">
              <IconButton
                color="secondary"
                disabled={props.filter?.length === 0}
                onClick={() => {
                  props.onFilter()
                }}
              >
                <SendIcon
                  htmlColor={props.filter?.length === 0
                    ? ecTheme.additionalPalette.grey.stroke
                    : ecTheme.palette.primary.main}
                />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      onKeyPress={handleKeyPress}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleClick}
    />
  )
}

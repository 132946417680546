import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import {
  type FC,
  useState,
} from 'react'

import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

import {
  clearSearchRequest,
  closeSearchDialog,
  markedDescriptionsSelectors,
  MarkedDescriptionsSlice,
  SearchDescriptionsBar,
  type SelectableDescription,
  SelectMultipleDescriptions,
  selectSearchDialogIsOpen,
} from 'features/logistics/units/searchUnts'

export const SearchDescriptionsDialog: FC = () => {

  const isOpenSearch: boolean = useAppSelector(selectSearchDialogIsOpen)
  const selectedValues: SelectableDescription[] = useAppSelector(markedDescriptionsSelectors.selectAll)
  const [markedStateOnOpen] = useState<SelectableDescription[]>(selectedValues.map(i => structuredClone(i)))

  const dispatch = useAppDispatch()

  const handleCloseButton = () => {
    dispatch(clearSearchRequest())
    dispatch(closeSearchDialog())

    dispatch(MarkedDescriptionsSlice.actions.removeAllDescriptions())
    dispatch(MarkedDescriptionsSlice.actions.addManyDescriptions(markedStateOnOpen))
  }

  const handleSave = () => {
    dispatch(clearSearchRequest())
    dispatch(closeSearchDialog())
  }

  return (
    <Dialog
      open={isOpenSearch}
      onClose={handleSave}
      maxWidth="lg"
      sx={{'& .MuiDialog-paper': {borderRadius: '12px'}}
      }
    >
      <DialogContent>
        <Box sx={
          {
            background: 'white',
            maxWidth: '600px',
            minHeight: '494px',

            padding: '32px',
            paddingBottom: '0px',
          }}
        >
          <SearchDescriptionsBar />
          <SelectMultipleDescriptions />
        </Box>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center'}}>
        <Button
          onClick={() => {
            handleCloseButton()
          }}
          data-testid="search-button-cancel"
        >
          Cancel
        </Button>
        {(selectedValues.length > 0) && <Button
          data-testid="search-button-show"
          variant="contained"
          onClick={handleSave}
        >
          Show
        </Button>}

      </DialogActions>
    </Dialog>
  )
}

import {Box, ImageListItem, ImageListItemBar} from '@mui/material'
import Skeleton from "@mui/material/Skeleton";
import {type FC, useState} from 'react'

import {
  ModuleName,
  useFeatureSlicedDebug,
} from 'shared/lib/useFeatureSlicedDebug'

interface QaImageProps {
  previewUrl: string
  title:string,
  documentID?:string
  qaActions?:Array<React.ReactNode>
  sx?: Record<string, unknown>
}

export const QaImageListItem: FC<QaImageProps> = ({
  sx,
  title  ,
  previewUrl,
  qaActions,
}) => {
  const {
    rootAttributes,
  } = useFeatureSlicedDebug('entity/QaImageListItem' as ModuleName)

  const [isImageLoad, setIsImageLoad] = useState<boolean>(false);

  return (
    <Box sx={{ ...sx }}>
      <ImageListItem>
        {!isImageLoad && <Skeleton
          variant='rectangular'
          height={200}
          width={150}
        />}
        <img
          src={previewUrl}
          alt={title}
          onLoad={()=>{
            setIsImageLoad(true)
          }}
        />
        <ImageListItemBar
          title={title}
          actionIcon={qaActions
            ? qaActions
            : <></>}
        />
      </ImageListItem>
    </Box>
  )
}

QaImageListItem.displayName = 'QaImageListItem'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  type FC,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { RhmTextField } from '../ReactHookFormMuiComponents/RhmTextField'

import { EditableFieldTextData } from './EditableFieldTextData'

interface EditableTextNumberFieldProps {
  value: number
  readonly?: boolean
  changeDataHandler: (data: unknown) => void
}

const formDataSchema = z.object({value: z.coerce.number()})

type FormData = z.infer<typeof formDataSchema>

export const EditableTextNumberField: FC<EditableTextNumberFieldProps> = ({
  value,
  readonly,
  changeDataHandler,
}) => {
  const [isEditable, setIsEditable] = useState(false)

  const {
    handleSubmit,
    control,
    formState: {
      errors,
    },
    resetField,
  } = useForm<FormData>(
    {
      defaultValues: {value},
      resolver: zodResolver(formDataSchema),
    },
  )

  const onSubmitData = (data: FormData) => {
    changeDataHandler(data.value)
    setIsEditable(false)
  }

  if (!isEditable) {
    return (
      <EditableFieldTextData
        dataValue={value}
        readonly={readonly}
        onClick={() => {
          setIsEditable(true)
        }}
      />
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <RhmTextField
        name={'value'}
        control={control}
        value={value}
        resetField={resetField}
        setIsEditable={setIsEditable}
      />
      <p>{errors.value?.message && 'Position can by only number'}</p>
    </form>
  )
}

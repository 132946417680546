import { type RightDto } from '../../model/contracts/administration/Rights'
import { API_ADDRESS } from '../api'
import authService from '../AuthService'
import { handleErrors } from '../errorHandler'
import { msalFetch } from '../graph'

const RIGHTS_API_ADDRESS = `${API_ADDRESS}/api/administration/rights`

export class RightsApi {
  async getRights () {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(RIGHTS_API_ADDRESS, token.accessToken)
      .then(handleErrors)
      .then<RightDto[]>(async (res) => await res.json())
  }
}

const rightsApi = new RightsApi()

export default rightsApi

import z from 'zod'

import {
  equipmentTypesApi,
  EquipmentTypesApiMethods,
} from './slices/equipmentTypesApi'
import {
  manufacturingMethodsApi,
  ManufacturingMethodsApiMethods,
} from './slices/manufacturingMethodsApi'
import {
  materialsApi,
  MaterialsApiMethods,
} from './slices/materialsApi'
import {
  purposesApi,
  PurposesApiMethods,
} from './slices/purposesApi'

const CatalogRecordSchema = z.object({
  id: z.number().gt(0),
  value: z.string().min(1),
})
export type CatalogRecord = z.infer<typeof CatalogRecordSchema>

export enum CatalogNames {
  materials            = 'materials',
  purposes             = 'purposes',
  manufacturingMethods = 'manufacturingMethods',
  equipmentType        = 'equipmentType',
}

// @ts-ignore
export const CatalogApis: Record<string | number | symbol, any> = {
  [CatalogNames.materials]: materialsApi,
  [CatalogNames.purposes]: purposesApi,
  [CatalogNames.manufacturingMethods]: manufacturingMethodsApi,
  [CatalogNames.equipmentType]: equipmentTypesApi,

} as const
// @ts-ignore
export const CatalogApiMethods: Record<string | number | symbol, any> = {
  [CatalogNames.materials]: MaterialsApiMethods,
  [CatalogNames.purposes]: PurposesApiMethods,
  [CatalogNames.manufacturingMethods]: ManufacturingMethodsApiMethods,
  [CatalogNames.equipmentType]: EquipmentTypesApiMethods,
} as const

export type AvailableUnitCatalogFields = Exclude<CatalogNames, 'purposes'>

export type ApiNames = keyof typeof CatalogApis

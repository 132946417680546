import { AccountCircle } from '@mui/icons-material'
import {
  Grid,
  Typography,
} from '@mui/material'
import { type FunctionComponent } from 'react'

const NoUserPermissionsView: FunctionComponent = () => {
  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100vh' }}
    >
      <Grid
        item
        container
        direction="row"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <AccountCircle
            color="secondary"
            fontSize="large"
          />
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            align="center"
            color="GrayText"
          >
            You have no access to this page
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NoUserPermissionsView

import UpgradeIcon from '@mui/icons-material/Upgrade';
import { IconButton, type SxProps, type Theme, Tooltip } from '@mui/material'
import { type FC } from 'react'

import { useAppDispatch } from '../../app/store'
import { themeSpacingFromPixels } from '../../shared/lib/workWithThemeSpacing'

import { replicateDescriptionSlice, setOpen } from './unitDescription/replicateDescription/replicateDescriptionSlice'

interface Props {
  unitId: number
  elementId: string
  sx?: SxProps<Theme>
}

export const CopyDescriptionButton: FC<Props> = ({
  unitId,
  elementId,
  sx: sxOverwrite,
}) => {
  const dispatch = useAppDispatch()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    dispatch(setOpen(true))
    dispatch(replicateDescriptionSlice.actions.addDescription({
      unitId: unitId,
      elementId: elementId,
    }))
  }

  return (
    <>
      <Tooltip
        arrow
        placement="bottom"
        title={'Копировать в хранилище'}
      >
        <IconButton
          sx={{
            ml: 0.5,
            height: themeSpacingFromPixels(28),
            ...sxOverwrite,

          }}
          aria-label="replicate description"
          onClick={(e) => {
            handleClick(e)
          }}
          edge="start"
        >
          <UpgradeIcon sx={{
            pointerEvents: 'auto',
            width: '20px',
            height: '30px',
            ...sxOverwrite,
          }}
          />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default CopyDescriptionButton

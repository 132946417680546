import z from 'zod'

export const errorBodySchema = z.object({
  code: z.string().regex(/^[a-zA-Z]{3}\d*/),
  message: z.string().nullable(),
})

export type errorBody = z.infer<typeof errorBodySchema>

export const answerBody = z.record(z.string(), z.unknown())

export const httpAPIResponseScheme = z.object({
  success: z.boolean(),
  body: z.union([errorBodySchema, answerBody]),
})

export type HttpAPIResponse = z.infer<typeof httpAPIResponseScheme>

export type HttpAPIResponseTyped<T> = Omit<HttpAPIResponse, 'body'> & {
  body: T | errorBody
}

import {
  Box,
  Stack,
} from '@mui/material'
import { type FC } from 'react'

import { useAppSelector } from 'app/store/hooks'

import {
  TechnicalCardApproveState,
  technicalCardsApi,
  type UnitTechnicalDescription,
  useGetUnitDescriptionsQuery,
} from 'entities/logistics/TechnicalCard'

import { EditableTextStringField } from 'shared/ui/components/EditableFields'

import { useGetUnitApproveState } from '../../../../../shared/lib/logistics'
import { type SelectableDescription } from '../../../units/searchUnts'
import { selectDescriptionsOnSearch } from '../../../units/searchUnts/model/selectableDescriptions'

interface setPositionProps {
  unitId: UnitTechnicalDescription['unitId']
  loading?: boolean
}

export const SetPosition: FC<setPositionProps> = ({
  unitId,
  loading,
}) => {

  const markedUnits: SelectableDescription[] | undefined = useAppSelector(selectDescriptionsOnSearch)
  const markedUnitsId = markedUnits?.map(unit => unit.unitId) || []
  const [updateUnitPosition] = technicalCardsApi.useUpdatePositionUnitMutation()

  const currentApproveState = useGetUnitApproveState(unitId)
  const isReadOnlyFields = !currentApproveState || currentApproveState===TechnicalCardApproveState.Approved

  const {
    position,
  } = useGetUnitDescriptionsQuery(markedUnitsId, {
    skip: !markedUnits,
    selectFromResult: ({
      data,
    }) => ({position: data?.find((description: UnitTechnicalDescription) => description?.unitId === unitId)?.position || ''}),
  })

  const onSubmit = (data: { valueData: string | null }) => {
    updateUnitPosition({
      unitId,
      position: data?.valueData || '',
    })
  }
  return (
    <>
      <Box>
        <Stack>
          <EditableTextStringField
            value={position}
            readonly={isReadOnlyFields || loading}
            changeDataHandler={onSubmit}
            fieldName={'Position'}
          />
        </Stack>
      </Box>
    </>
  )
}

SetPosition.defaultProps = {unitId: 0}

SetPosition.displayName = 'SetPosition'

import {
  AvatarGroup,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  Tooltip,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import {
  type FunctionComponent,
  useEffect,
} from 'react'
import { useNavigate } from 'react-router-dom'

import {
  createRole,
  loadRoles,
  RolesListItem,
  RolesListState,
  selectRolesList,
  setActionError,
} from 'app/store/administration/rolesListSlice'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'
import { checkPermission } from 'app/store/user/userSlice'

import { PermissionsAdministrationEdit } from 'shared/model/contracts/permissions'
import ErrorBox from 'shared/ui/components/ErrorBox'
import ErrorDialog from 'shared/ui/components/ErrorDialog'
import GraphAvatar from 'shared/ui/components/GraphAvatar'

import { setBackUrl } from '../../../app/store/administration/roleSlice'
import { UserDto } from '../../../shared/model/contracts/administration/Users'
import LoadingTableBody from '../../components/LoadingTableBody'

function AddRole () {
  const canAdmin = useAppSelector((root) => checkPermission(root, PermissionsAdministrationEdit))
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleAddRole = () => {
    dispatch(createRole())
      .unwrap()
      .then((role) => {
        navigate(`/administration/roles/${role.roleId}`)
      })
      .catch(() => {
      })
  }

  return <>
    {canAdmin && (
      <Button
        variant="outlined"
        onClick={handleAddRole}
      >
        Add role
      </Button>
    )}
  </>
}

function RolesHeader () {
  return <Box
    mt={4}
    mb={4}
  >
    <Grid
      container
      direction="row"
      spacing={1}
      justifyContent={'space-between'}
    >
      <Grid
        item
      >
        <Typography variant="h4">Roles</Typography>
      </Grid>
      <Grid item>
        <AddRole/>
      </Grid>
    </Grid>
  </Box>
}

const RolesList: FunctionComponent = () => {

  const dispatch = useAppDispatch()
  const rolesListState: RolesListState = useAppSelector(selectRolesList)

  const navigate = useNavigate()
  const rolesListStateSortedByName = _.sortBy(rolesListState.roles, (r: RolesListItem) => r.name)
  const usersSortedByName = (r: RolesListItem) => _.sortBy(r.users, (u: UserDto) => u.displayName)

  const handleClickRole = (roleId: number) => {
    dispatch(setBackUrl('/administration/roles'))
    navigate(`/administration/roles/${roleId}`)
  }
  const tableSkeleton = <Table>
    <TableBody>
      <LoadingTableBody
        rows={5}
        columns={2}
      />
    </TableBody>
  </Table>

  return <Box mt={4}>
    {rolesListState.status === 'loading' && tableSkeleton}
    {rolesListState.status === 'idle' && (
      <List>
        {rolesListStateSortedByName.map((rolesListItem: RolesListItem) => {
          return (
            <ListItem
              key={rolesListItem.roleId}
              divider
              onClick={() => {
                handleClickRole(rolesListItem.roleId)
              }}
              sx={{ flexWrap: 'wrap' }}
            >
              <ListItemText primary={rolesListItem.name}></ListItemText>
              <Tooltip
                title={
                  <ul>
                    {usersSortedByName(rolesListItem)?.map((currentUser) => (
                      <li key={currentUser.userId}>
                        {currentUser.displayName}
                      </li>
                    ))}
                  </ul>
                }
              >
                <AvatarGroup
                  max={8}
                  sx={{ flexWrap: 'wrap' }}
                >
                  {usersSortedByName(rolesListItem)?.map((currentUser) => (
                    <GraphAvatar
                      key={currentUser.userId}
                      userId={currentUser.userId}
                    />
                  ))}
                </AvatarGroup>
              </Tooltip>
            </ListItem>
          )
        })}
      </List>
    )}
    {rolesListState.status === 'failed' && rolesListState?.pageError && (
      <ErrorBox
        code={rolesListState.pageError.name}
        description={rolesListState.pageError.message}
        sx={{ minHeight: '90vh' }}
      >
        <Button
          variant="contained"
          onClick={async () => await dispatch(loadRoles())}
        >
          Try again
        </Button>
      </ErrorBox>
    )}
  </Box>
}

export const RolesListPage: FunctionComponent = () => {

  const dispatch = useAppDispatch()
  const rolesListState: RolesListState = useAppSelector(selectRolesList)

  useEffect(() => {
    dispatch(loadRoles())
  }, [dispatch])

  return (
    <Container maxWidth="md">
      <RolesHeader/>
      <RolesList/>
      <ErrorDialog
        open={rolesListState.actionError !== undefined}
        title={rolesListState.actionError?.name || 'Error'}
        text={rolesListState.actionError?.message ?? ''}
        onOk={() => dispatch(setActionError())}
      />
    </Container>
  )
}

import UnfoldLessSharpIcon from '@mui/icons-material/UnfoldLessSharp'
import UnfoldMoreSharpIcon from '@mui/icons-material/UnfoldMoreSharp'
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { type FC } from 'react'

interface Props {
  expanded: boolean
  setExpandAll: (expanded: boolean) => void
  collapseText?: string
  expandText?: string
}

export const CollapseTooltipButton: FC<Props> = ({
  expanded,
  setExpandAll,
  collapseText,
  expandText,
}) => {

  return (
    <Tooltip
      arrow
      placement="bottom"
      title={expanded
        ? collapseText ?? 'Collapse all'
        : expandText ?? 'Expand All'}
    >
      <IconButton onClick={() => {
        setExpandAll(!expanded)
      }}
      >
        {expanded
          ? <UnfoldLessSharpIcon />
          : <UnfoldMoreSharpIcon />}
      </IconButton>
    </Tooltip>
  )
}

import { Skeleton, TableCell, TableRow } from '@mui/material'
import _ from 'lodash'
import { Fragment, type FunctionComponent } from 'react'

interface Props {
  rows: number
  columns: number
}

const LoadingTableBody: FunctionComponent<Props> = (props) => {
  return (
    <>
      {_.range(props.rows).map((r, rowIndex) => (
        <TableRow key={rowIndex}>
          {_.range(props.columns).map((c, columnIndex) => (
            <TableCell key={columnIndex}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

export default LoadingTableBody

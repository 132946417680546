import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import z from 'zod'

import { setAuthTokenToHeaders } from 'shared/api/wretch/authorization'

const ManufacturingMethodSchema = z.object({
  id: z.number(),
  value: z.string(),
})

export type ManufacturingMethod = z.infer<typeof ManufacturingMethodSchema>

const ManufacturingMethodRequestScheme = z.array(ManufacturingMethodSchema).optional()

export enum ManufacturingMethodsApiMethods {
  getMany = 'getManufacturingMethods',
  getOne = 'getManufacturingMethod',
  update = 'updateManufacturingMethod',
  create = 'createManufacturingMethod',
  delete = 'deleteManufacturingMethod',
}

export const manufacturingMethodsApi = createApi({
  reducerPath: 'manufacturingMethodsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ADDRESS}/api/logistics`,
    prepareHeaders: async (headers: Headers) => await setAuthTokenToHeaders(headers),
  }),
  tagTypes: ['ManufacturingMethod'],
  endpoints: (builder) => ({
    getManufacturingMethods: builder.query<ManufacturingMethod[], undefined>({
      query: () => 'manufacturing-methods',
      transformResponse: (response: ManufacturingMethod[]) => {
        ManufacturingMethodRequestScheme.parse(response)
        return response
      },
      providesTags: (result) =>
        typeof result !== 'undefined'
          ? [
            ...result.map(({
              id, 
            }) => ({
              type: 'ManufacturingMethod',
              id,
            } as const)),
            {
              type: 'ManufacturingMethod',
              id: 'LIST',
            },
          ]
          : [{
            type: 'ManufacturingMethod',
            id: 'LIST',
          }],

    }),
    getManufacturingMethod: builder.query<ManufacturingMethod[], number | string>({
      query: (id) => `manufacturing-methods/${id}`,
      providesTags: (result, error, id) => [{
        type: 'ManufacturingMethod',
        id,
      }],
    }),

    createManufacturingMethod: builder.mutation<ManufacturingMethod, Pick<ManufacturingMethod, 'value'>>({
      query: ({
        value, 
      }) => ({
        url: 'manufacturing-methods',
        method: 'POST',
        body: { value },
      }),
    }),
    updateManufacturingMethod: builder.mutation<ManufacturingMethod, ManufacturingMethod>({
      query: ({
        id,
        value,
      }) => ({
        url: `manufacturing-methods/${id}`,
        method: 'PUT',
        body: { value },
      }),
    }),
    deleteManufacturingMethod: builder.mutation<ManufacturingMethod, number>({
      query: (id) => ({
        url: `manufacturing-methods/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetManufacturingMethodsQuery,
  useUpdateManufacturingMethodMutation,
  useDeleteManufacturingMethodMutation,
  useCreateManufacturingMethodMutation,
} = manufacturingMethodsApi

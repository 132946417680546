import {
  createAsyncThunk,
  createSlice,
  type SerializedError,
} from '@reduxjs/toolkit'

import applicationInformationApi from '../../../shared/api/ApplicationInformationApi'
import { type RootState } from '../store'

export interface ApplicationInformationState {
  status: 'pending' | 'idle' | 'failed'
  currentElementConnectApiBuild?: string
  currentElementConnectLogisticsApiBuild?: string
  ecError?: SerializedError
  ecLogisticsError?: SerializedError
}

const initialState: ApplicationInformationState = { status: 'idle' }

export const loadElementConnectApplicationInformation = createAsyncThunk('elementConnectApplicationInformation/load', async () => {
  return await applicationInformationApi.getEcApplicationInfo()
})

export const loadElementConnectApplicationLogisticsInformation = createAsyncThunk('elementConnectApplicationLogisticsInformation/load', async () => {
  return await applicationInformationApi.getLogisticsApplicationInfo()
})

export const applicationInformationSlice = createSlice({
  name: 'applicationInformation',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(loadElementConnectApplicationInformation.pending, (
      state, _) => {
      state.status = 'pending'
    })
    build.addCase(loadElementConnectApplicationLogisticsInformation.pending, (
      state, _) => {
      state.status = 'pending'
    })

    build.addCase(loadElementConnectApplicationInformation.fulfilled, (
      state, action) => {
      state.status = 'idle'
      state.currentElementConnectApiBuild = action.payload.currentBuild
    })

    build.addCase(loadElementConnectApplicationLogisticsInformation.fulfilled, (
      state, action) => {
      state.status = 'idle'
      state.currentElementConnectLogisticsApiBuild = action.payload.currentBuild
    })

    build.addCase(loadElementConnectApplicationInformation.rejected, (
      state, action) => {
      state.status = 'failed'
      state.ecError = action.error
    })

    build.addCase(loadElementConnectApplicationLogisticsInformation.rejected, (
      state, action) => {
      state.status = 'failed'
      state.ecLogisticsError = action.error
    })
  },
})

export const selectApplicationInformation = (state: RootState) => state.applicationInformation

export default applicationInformationSlice.reducer

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material'
import { type FunctionComponent } from 'react'

import {
  hideEditSettingsDialog,
  loadSettings,
  selectSettings,
  setSettings,
  showEditSettingsDialog,
  useAppDispatch,
  useAppSelector,
} from 'app/store'

import SettingsEditor from './SettingsEditor'
import SettingsList from './SettingsList'

const SettingsCard: FunctionComponent = () => {
  const settingsState = useAppSelector(selectSettings)
  const {
    status,
    openEditSettingsDialog,
  } = settingsState
  const dispatch = useAppDispatch()

  const renderButtons = () => {
    return (
      status === 'idle' && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => dispatch(showEditSettingsDialog())}
        >
          Edit
        </Button>
      )
    )
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title="Settings"
        action={renderButtons()}
      />
      <Divider />
      <CardContent>
        <SettingsList />
      </CardContent>

      {openEditSettingsDialog && (
        <SettingsEditor
          open={openEditSettingsDialog}
          onCancel={() => dispatch(hideEditSettingsDialog())}
          onOk={(settings) => {
            dispatch(setSettings(settings))
            dispatch(loadSettings())
            dispatch(hideEditSettingsDialog())
          }}
        />
      )}
    </Card>
  )
}

export default SettingsCard

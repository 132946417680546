import SearchIcon from '@mui/icons-material/Search'
import SendIcon from '@mui/icons-material/Send'
import {
  IconButton,
  InputBase,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import type React from 'react'
import {
  type FC,
  useState,
} from 'react'

import { useAppDispatch } from 'app/store/hooks'

import {
  FetchedDescriptionsSlice,
  loadUnitsForDescription,
} from '../model/searchDataSlice'
import { setSearchRequest } from '../model/searchDialogSlice'

interface SearchDialogBarProps {

}

export const SearchDialogBar: FC<SearchDialogBarProps> = (props) => {

  const [searchString, setSearchString] = useState('')
  const dispatch = useAppDispatch()

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(setSearchRequest(searchString))
    dispatch(FetchedDescriptionsSlice.actions.removeAll())
    dispatch(loadUnitsForDescription({
      filter: searchString,
      page: 1,
      reduced:true,
    }))
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography sx={{
          padding: '8px',
          fondWeight: 600,
          lineHeight: '28px',
          fontSize: '18px',
        }}
        >Select unit</Typography>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          sx={{
            border: '1px solid',
            borderRadius: '8px',
          }}
        >
          <IconButton
            type="button"
            sx={{ p: '10px' }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            data-testid="unit-search-bar-input"
            sx={{
              ml: 1,
              flex: 1,
            }}
            placeholder="search units"
            onChange={(e) => {
              setSearchString(e.target.value)
            }}
          />
          <IconButton
            data-testid="unit-search-bar-submit"
            type="submit"
          >
            <SendIcon />
          </IconButton>
        </Stack>
      </form>
    </>
  )
}

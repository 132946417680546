import {
  MaterialReactTable,
  MRT_Cell,
  MRT_ColumnDef,
} from 'material-react-table'
import {
  FC,
  useMemo,
} from 'react'

import { NavigateToElement } from 'features/logistics/unitDescription/navigateToElement'

import { type Content } from '../model/types'

interface UnitDescriptionContentProps {
  content: Content[]
}

export const UnitDescriptionContent: FC<UnitDescriptionContentProps> = ({
  content,
}) => {
  const columns = useMemo<MRT_ColumnDef<Content>[]>(() => [
    {
      accessorKey: 'elementId',
      header: 'Element ID',
      cell: (props:MRT_Cell<Content>) => (
        <NavigateToElement
          key={`${NavigateToElement.displayName}-${props.row.original.id}`}
          elementId={props.row.original.elementId}
        />
      ),
    },
    {
      accessorKey: 'elementNameRus',
      header: 'Name',
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
    },
  ], []);

  if (!content || content.length === 0) {
    return null;
  }

  return (
    <MaterialReactTable
      enableTopToolbar={false}
      enablePagination={false}
      enableBottomToolbar={false}
      columns={columns}
      data={content}
      muiTablePaperProps={{
        sx: {
          m: 'auto',
          maxWidth: '100%',
        },
      }}
    />
  );
};

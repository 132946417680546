import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { Button } from '@mui/material'
import { saveAs } from 'file-saver'
import { useSnackbar } from 'notistack'
import { type FC } from 'react'
import { type WretchError } from 'wretch'

import { logisticsTheme } from 'app/config/theme/logisticsTheme'
import { useAppSelector } from 'app/store/hooks'

import { markedDescriptionsSelectors, type SelectableDescription } from 'features/logistics/units/searchUnts'

import useSetIsHaveErrorFields from 'entities/logistics/TechnicalCard/model/useSetIsHaveErrorFields'

import { getWretchAuthorizedECApi } from 'shared/api/wretch/authorivedWretch'

import { selectIsEmptyFieldsInDescriptions } from '../model/isEmptyFieldsInDescriptions'

function isHaveAnyUnit (currentUnits: SelectableDescription[]): boolean {
  return Boolean(currentUnits.length)
}

function useCanNotDownloadDocument (currentUnits: SelectableDescription[]): boolean {

  const {
    enqueueSnackbar,
  } = useSnackbar()
  const noUnitsInDocuments = !isHaveAnyUnit(currentUnits)

  if (noUnitsInDocuments) {
    enqueueSnackbar(
      'Should select some units before save document',
      {
        variant: 'warning',
        preventDuplicate: true,
      })
  }

  return noUnitsInDocuments
}

export const DownloadTechnicalDescriptionDocument: FC = () => {

  useSetIsHaveErrorFields()

  const {
    enqueueSnackbar,
  } = useSnackbar()
  const currentUnits: SelectableDescription[] = useAppSelector(markedDescriptionsSelectors.selectAll)
  const currentUnitsIds: number[] = currentUnits.map(item => item.unitId)
  const isErrorInFields: boolean = useAppSelector(selectIsEmptyFieldsInDescriptions)
  const cantDownload = useCanNotDownloadDocument(currentUnits)

  if (isErrorInFields) {
    enqueueSnackbar('Some elements have empty required fields', {
      variant: 'warning',
      preventDuplicate: true,
    })
  }

  const handleClick = async () => {
    if (cantDownload) {
      return
    }

    const wretchEcApi = await getWretchAuthorizedECApi()

    if (wretchEcApi) {
      const doc: Blob = await (wretchEcApi)
        .url('logistics/documents')
        .post(currentUnitsIds)
        .error(500,
          () => {
            enqueueSnackbar('Error on requesting document',
              { variant: 'error' })
          },
        )
        .badRequest((err: WretchError) => {
          console.log(err.status)
        })
        .blob()

      if (doc) {
        saveAs(
          new Blob(
            [doc],
            { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8' }),
          'Техническое описание')
      }
    }
  }

  return (
    <Button
      data-testid="download-technical-descriptions"
      sx={{
        backgroundColor: isErrorInFields
          ?
          logisticsTheme.palette.error.main
          :
          '#7858A61F',
        color: isErrorInFields
          ?
          logisticsTheme.palette.secondary.contrastText
          :
          logisticsTheme.palette.primary.main,
        '&:hover': {
          backgroundColor: isErrorInFields
            ?
            logisticsTheme.palette.error.contrastText
            :
            '#7858A61F',
          color: isErrorInFields
            ?
            logisticsTheme.palette.error.main
            :
            logisticsTheme.palette.primary.main,
        },
      }}
      onClick={handleClick}
      startIcon={isErrorInFields
        ?
        <ErrorOutlineIcon/>
        :
          <SaveOutlinedIcon/>}
      variant="outlined"
    >
      Download document
    </Button>
  )
}

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  type FC,
  useEffect,
  useState,
} from 'react'

import {
  TechnicalDescriptionImage,
  TechnicalDescriptionImageType,
  TechnicalDescriptionScheme,
} from 'entities/logistics/TechnicalCard'

import {
  ModuleName,
  useFeatureSlicedDebug,
} from 'shared/lib/useFeatureSlicedDebug'
import { themeSpacingFromPixels } from 'shared/lib/workWithThemeSpacing'
import { Gallery } from 'shared/ui/PhotoSwipeGallery'
import { TechnicalGalleryImage } from 'shared/ui/PhotoSwipeGallery/model/types'

interface ShowImagesInGalleryProps {
  sx?: Record<string, unknown>
  title:string
  unitId?:number
  images?: TechnicalDescriptionImage[]| TechnicalDescriptionScheme[]
  imagetype:TechnicalDescriptionImageType
}

export const ShowImagesInGallery: FC<ShowImagesInGalleryProps> = ({
  sx,
  title,
  unitId,
  images,
  imagetype,
}) => {

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('feature/ShowImagesInGallery' as ModuleName)

  const [isShowGallery, setIsShowGallery] = useState(false)
  const [imagesSet ,setImagesSet] = useState<TechnicalGalleryImage[]>([])

  useEffect(() => {
    const convertedImages = images?.map((value:TechnicalDescriptionScheme|TechnicalDescriptionImage):TechnicalGalleryImage=>{
      const currentImageType = `${imagetype}s`
      return {
        largeURL: `/${unitId}/${currentImageType}/${value.id}`,
        fileName: value?.name
          ? value.name
          : '',
      }
    }) || []
    setImagesSet(convertedImages)
  }, [images, imagetype, unitId])

  const toggleGallery = ()=>{
    setIsShowGallery((curValue)=>!curValue)
  }

  return (
    <Box
      {...rootAttributes}
      sx={{...sx}}
    >

      <Button
        onClick={toggleGallery}
        disabled={!images?.length}
      >
        Show {String(title).toLowerCase()}
      </Button>

      <Dialog
        sx={{'& .MuiDialog-paper': {maxWidth:themeSpacingFromPixels(1100)}}}
        onClose={
          ()=>{
            setIsShowGallery((curValue)=>!curValue)
          }}
        open={isShowGallery}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent >
          <Gallery
            galleryID={`${title}-${unitId}`}
            images={imagesSet}
            preload={true}
          />
        </DialogContent>
      </Dialog>

    </Box>
  )
}

import wretch from 'wretch'

import authService from '../AuthService'

export const getWretchAuthorizedECApi = async () => {
  const token = await authService.getIdentity()

  return wretch(`${process.env.REACT_APP_API_ADDRESS}/api/`)
    .auth(`Bearer ${token.accessToken}`)
}

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { IconButton } from '@mui/material'
import { type XHRUploadOptions } from '@uppy/xhr-upload'
import {
  type FC,
  useState,
} from 'react'

import { type UnitDto } from 'shared/model/contracts/UnitDto'

import { DropzoneUnitPictures } from './DropzoneUnitPictures'

interface UploadPictureProps {
  unitId: UnitDto['unitFileId']
  isDisabled?:boolean
}

export const UploadPicture: FC<UploadPictureProps> = ({
  unitId,
  isDisabled,
}) => {

  const [isOpenDropzone, setIsOpenDropzone] = useState(false)

  const openDragNDrop = () => {
    setIsOpenDropzone(true)
  }

  const xhrInOptions: XHRUploadOptions = {
    endpoint: `${process.env.REACT_APP_API_ADDRESS}/api/logistics/units/${unitId}/images`,
    method: 'POST',
  }
  // TODO: DropzoneUnitPictures переписать на общий интерфейс
  return (
    <>
      <IconButton
        onClick={openDragNDrop}
        disabled={isDisabled}
      >
        <CloudUploadOutlinedIcon />
      </IconButton>
      <DropzoneUnitPictures
        dropzoneId={`${unitId}-images`}
        XHRInOptions={xhrInOptions}
        isOpen={isOpenDropzone}
        setIsOpen={setIsOpenDropzone}
        invalidateData={() => {
        }}
      />
    </>
  )
}

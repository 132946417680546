import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  TextField,
} from '@mui/material'
import _ from 'lodash'
import {
  Fragment,
  type FunctionComponent,
} from 'react'

import { selectSettings } from 'app/store/administration/settingsSlice'
import { useAppSelector } from 'app/store/hooks'

const SettingsList: FunctionComponent = () => {
  const settingsState = useAppSelector(selectSettings)
  const {
    status,
    settings,
  } = settingsState

  if (status === 'loading') {
    return (
      <List>
        {Array.from(Array(5).keys()).map((i) => {
          return (
            <ListItem
              key={i}
              divider
            >
              <Box sx={{ width: '100%' }}>
                <Skeleton />
              </Box>
            </ListItem>
          )
        })}
      </List>
    )
  }

  const orderedItems = _.orderBy(settings, ['grouping', 'description'])
  const groups = _.groupBy(orderedItems, 'grouping')

  return (
    <List>
      {_.map(groups, (group, name) => (
        <Fragment key={name}>
          <ListSubheader
            color="primary"
            disableSticky
            key={name}
          >
            {name}
          </ListSubheader>
          {group.map((s) => {
            return (
              <ListItem
                key={`setting-list-item-${s.settingId}`}
                sx={{
                  flexWrap:'wrap',
                  alignContent:'center',
                }}
              >
                <ListItemText
                  primary={s.description}
                  secondary={s.name}
                  sx={{wordBreak:'break-аll'}}
                />
                <TextField
                  id={`setting-list-item-textfield-${s.settingId}`}
                  defaultValue={s.value}
                  disabled
                  variant="outlined"
                />
              </ListItem>
            )
          })}
        </Fragment>
      ))}
    </List>
  )
}

export default SettingsList

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'

import { type RootState } from 'app/store'

import authService from 'shared/api/AuthService'
import { handleErrors } from 'shared/api/errorHandler'
import { msalFetch } from 'shared/api/graph'
import { UNITS_API_ADDRESS } from 'shared/api/UnitsApi'
import {
  SearchUnitDto,
  type UnitSearchResponse,
} from 'shared/model/contracts/UnitDto'

import { type SelectableDescription } from './types'

export const loadUnitsForDescription = createAsyncThunk(
  'units-descriptions/load',
  async (request: { filter?: string, page: number, startDate?: string, endDate?: string,reduced?:boolean }) => {
    if (request.filter) {
      const result = await authService.getIdentity()
      const {
        filter,
        startDate,
        endDate,
        reduced = false,
      } = request
      if (result) {
        return await msalFetch()
          .post(`${UNITS_API_ADDRESS}`, result.accessToken, {
            filter,
            page: 1,
            pageSize: 10000,
            reduced,
            startDate,
            endDate,
          })
          .then(handleErrors)
          .then<UnitSearchResponse>(async (res) => await res.json())
      }
    }
  },
)

const fetchedDescriptionsAdapter = createEntityAdapter<SelectableDescription>({
  selectId: (description) => description.elementId,
  // sortComparer: (a, b) => a.elementId.localeCompare(b.elementId),
})

export const FetchedDescriptionsSlice = createSlice({
  name: 'fetchedDescriptions',
  initialState: fetchedDescriptionsAdapter.getInitialState(),
  reducers: {
    addMany: fetchedDescriptionsAdapter.addMany,
    addOne: fetchedDescriptionsAdapter.addOne,
    removeAll: fetchedDescriptionsAdapter.removeAll,
    removeOne: fetchedDescriptionsAdapter.removeOne,
  },
  extraReducers: (build) => {
    build.addCase(loadUnitsForDescription.pending, (state, action) => {

    })
    build.addCase(loadUnitsForDescription.fulfilled, (state, action) => {

      if (action.payload) {
        const unitData = action.payload?.items?.map((unit:SearchUnitDto) => ({
          elementId: unit.elementId,
          unitId: unit.unitFileId,
        }))||[]
        fetchedDescriptionsAdapter.addMany(state, unitData)
      }
    })
    build.addCase(loadUnitsForDescription.rejected, (state, action) => {
    })
  },
})

export const fetchedItemsSelectors = fetchedDescriptionsAdapter.getSelectors((state: RootState) => state.logistics.fetchedDescriptions)

import type { PayloadAction } from '@reduxjs/toolkit'
import {
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import { type RootState } from 'app/store/store'

export interface SearchDialogSliceState {
  isSearchOpen: boolean
  searchRequest?: string
  isSearchEnded: boolean
}

const initialState: SearchDialogSliceState = {
  isSearchOpen: false,
  isSearchEnded: false,
  searchRequest: undefined,
}

export const searchDialogSlice = createSlice({
  name: 'searchDialog',
  initialState,
  reducers: {
    openSearchDialog: (state) => {
      state.isSearchOpen = true
    },
    closeSearchDialog: (state) => {
      state.isSearchOpen = false
    },
    setSearchRequest: (state, action: PayloadAction<string>) => {
      state.searchRequest = action.payload
    },
    clearSearchRequest: (state) => {
      state.searchRequest = undefined
    },
  },
})

export const {
  openSearchDialog,
  closeSearchDialog,
  setSearchRequest,
  clearSearchRequest,
} = searchDialogSlice.actions

const logisticState = (store: RootState) => store.logistics

export const selectSearchDialogData = createSelector(logisticState,
  (s) => s.searchDescriptions)

export const selectSearchDialogIsOpen = createSelector(
  selectSearchDialogData,
  (s: SearchDialogSliceState): boolean => s.isSearchOpen)

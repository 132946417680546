import { type MeDto } from '../model/contracts/MeDto'

import { API_ADDRESS } from './api'
import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

const USER_API_ADDRESS = `${API_ADDRESS}/api/me`

export class MeApi {
  async getUser () {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(USER_API_ADDRESS, token.accessToken)
      .then(handleErrors)
      .then<MeDto>(async (res) => await res.json())
  }
}

const userApi = new MeApi()

export default userApi

import { ArrowBackIos } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import {
  Fragment,
  type FunctionComponent,
  useEffect,
} from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import {
  deleteRole,
  hideActionError,
  hideConfirmDeleteDialog,
  loadRole,
  saveChanges,
  selectEditRole,
  setEditName,
  showConfirmDeleteDialog,
} from 'app/store/administration/roleSlice'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

import { isEmptyOrSpaces } from 'shared/lib/stringHelper'
import ErrorBox from 'shared/ui/components/ErrorBox'
import ErrorDialog from 'shared/ui/components/ErrorDialog'
import YesNoDialog from 'shared/ui/components/YesNoDialog'

import RoleOptionCard from './RoleOptionCard'
import RoleRightsCard from './RoleRightsCard'
import RoleUsersCard from './RoleUsersCard'

const RolePage: FunctionComponent = (props) => {
  const {
    roleId,
  } = useParams() as { roleId: string }

  const navigate = useNavigate()
  const roleState = useAppSelector(selectEditRole)
  const dispatch = useAppDispatch()

  const idle = roleState.status === 'idle'
  const loading = roleState.status === 'loading'
  const saving = roleState.status === 'saving'
  const failed = roleState.status === 'failed'

  const cardError = isEmptyOrSpaces(roleState.editName)

  useEffect(() => {
    dispatch(loadRole(roleId))
  }, [roleId, dispatch])

  const handleSaveChanges = () => {
    dispatch(saveChanges({
      roleId,
      name: roleState.editName,
    }))
  }

  const handleDelete = () => {
    dispatch(showConfirmDeleteDialog())
  }

  return (
    <Box mt={6}>
      <Container maxWidth="lg">
        {(idle || loading || saving) && (
          <>
            <Box mb={2}>
              <Grid
                container
                spacing={3}
                alignItems="center"
              >
                <Grid item>
                  <Tooltip title="Back">
                    <IconButton onClick={() => {
                      navigate(roleState.backUrl ?? '/')
                    }}
                    >
                      <ArrowBackIos/>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5">{loading
                    ? <Skeleton width={50}/>
                    : `Role ${roleState.name}`}</Typography>
                </Grid>
                <Grid
                  item
                  xs
                />
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    disabled={loading || saving}
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Paper>
              <Box p={2}>
                <Grid
                  container
                  direction="column"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="h3">
                      {loading
                        ? (
                          <Skeleton width={100}/>
                        )
                        : (
                          <TextField
                            variant="outlined"
                            label="Name"
                            error={isEmptyOrSpaces(roleState.editName)}
                            value={roleState.editName}
                            onChange={(e) => dispatch(setEditName(e.target.value))}
                          />
                        )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {(idle || saving) && (
                      <Button
                        variant="contained"
                        disabled={saving || cardError}
                        onClick={handleSaveChanges}
                      >
                        {saving
                          ? <CircularProgress size={20}/>
                          : 'Save changes'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            <Box mt={3}>
              <Grid
                container
                spacing={3}
                alignItems="stretch"
              >
                <Grid
                  item
                  xs={12}
                  lg={3}
                >
                  <RoleUsersCard/>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                >
                  <RoleRightsCard/>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={4}
                >
                  <RoleOptionCard/>
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        {failed && roleState.pageError && (
          <ErrorBox
            code={roleState.pageError.name}
            description={roleState.pageError.message}
            sx={{ minHeight: '90vh' }}
          >
            <Button
              variant="contained"
              onClick={async () => await dispatch(loadRole(roleId))}
            >
              Try again
            </Button>
          </ErrorBox>
        )}
      </Container>

      <ErrorDialog
        open={roleState.actionError !== undefined}
        title={roleState.actionError?.name || 'Error'}
        text={roleState.actionError?.message ?? ''}
        onOk={() => dispatch(hideActionError())}
      />

      <YesNoDialog
        open={roleState.openConfirmDeleteDialog}
        title="Delete role"
        onNo={() => dispatch(hideConfirmDeleteDialog())}
        onYes={() => {
          dispatch(hideConfirmDeleteDialog())
          dispatch(deleteRole(roleId))
            .unwrap()
            .then(() => {
              navigate('/administration/roles')
            })
            .catch(() => {
            })
        }}
      >
        {`Are you shure you want to delete role ${roleState.name}`}
      </YesNoDialog>
    </Box>
  )
}

export default RolePage

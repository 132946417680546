import GroupIcon from '@mui/icons-material/Group'
import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  Typography,
  Tooltip,
  Stack,
} from '@mui/material'
import _ from 'lodash'
import {
  type FunctionComponent,
  useEffect,
} from 'react'

import {
  addUser,
  hideSelectUserDialog,
  loadUsers,
  selectUsersList,
  setActionError,
  showSelectUserDialog,
} from 'app/store'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'
import { checkPermission } from 'app/store/user/userSlice'

import { SelectUserDialog } from 'features/elementConnect/users'

import { PermissionsAdministrationEdit } from 'shared/model/contracts/permissions'
import ErrorBox from 'shared/ui/components/ErrorBox'
import ErrorDialog from 'shared/ui/components/ErrorDialog'
import GraphAvatar from 'shared/ui/components/GraphAvatar'
import { StyledNavLink } from 'shared/ui/components/StyledNavLink'

import { setBackUrl } from '../../../app/store/administration/roleSlice'
import { type UsersListState } from '../../../app/store/administration/usersListSlice'
import { themeSpacingFromPixels } from '../../../shared/lib/workWithThemeSpacing'
import LoadingTableBody from '../../components/LoadingTableBody'

import EnableLoginSwitch from './components/EnableLoginSwitch'

const UsersListPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const canAdmin = useAppSelector((root) => checkPermission(root, PermissionsAdministrationEdit))

  const usersListState: UsersListState = useAppSelector(selectUsersList)

  useEffect(() => {
    dispatch(loadUsers())
  }, [dispatch])

  const handleAddUser = () => {
    dispatch(showSelectUserDialog())
  }

  return (
    <Container maxWidth="md">
      <Box
        mt={4}
        mb={4}
      >
        <Grid
          container
          direction="row"
          spacing={1}
        >
          <Grid
            item
            xs
          >
            <Typography variant="h4">Users</Typography>
          </Grid>
          <Grid item>
            {canAdmin && (
              <Button
                variant="outlined"
                onClick={handleAddUser}
              >
                Add user
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box mt={4}>
        {usersListState.status === 'loading' && (
          <Table>
            <TableBody>
              <LoadingTableBody
                rows={5}
                columns={2}
              />
            </TableBody>
          </Table>
        )}
        {usersListState.status === 'idle' && (
          <>
            <Grid
              container
              direction="row"
            >
              <Grid
                item
                xs
              ></Grid>
              <Grid
                item
                mr={5}
              >
                <Typography>Enable login</Typography>
              </Grid>
            </Grid>
            <List>
              {_.sortBy(usersListState.users, (u) => u.displayName ?? u.userPrincipalName).map((u) => {
                return (
                  <>
                    <ListItem
                      key={u.userId}
                      divider

                      secondaryAction={u.status !== 'loading' && (
                        <Stack
                          spacing={1}
                          direction={'row'}
                          alignItems={'center'}
                        >
                          <EnableLoginSwitch user={u}/>
                          {u.roles && <Tooltip
                            title={u.roles.length > 0 ?
                              <>
                                {_.sortBy(u.roles, (u) => u.name)?.map((r) => (
                                  <StyledNavLink
                                    key={r.roleId}
                                    onClick={() => {
                                      dispatch(setBackUrl('/administration/users'))
                                    }}
                                    style={{
                                      color: 'white',
                                      fontSize: themeSpacingFromPixels(15),
                                    }}
                                    to={`/administration/roles/${r.roleId}`}
                                  >
                                    {r.name}
                                  </StyledNavLink>
                                ))}
                              </> :
                              <Typography
                                color={'white'}
                                variant={'subtitle1'}
                              >
                                No roles
                              </Typography>
                            }
                          >
                            <GroupIcon/>
                          </Tooltip>}
                        </Stack>
                      )

                      }
                    >
                      <ListItemAvatar>
                        <GraphAvatar userId={u.userId}/>
                      </ListItemAvatar>
                      <ListItemText
                        primary={u.displayName ?? u.userPrincipalName}
                        secondary={u.userPrincipalName}
                        primaryTypographyProps={{ sx: { overflowWrap: 'anywhere' } }}
                        secondaryTypographyProps={{ sx: { overflowWrap: 'anywhere' } }}
                        sx={{ paddingRight: themeSpacingFromPixels(30) }}
                      ></ListItemText>
                    </ListItem>

                  </>
                )
              })}
            </List>
          </>
        )}
        {usersListState.status === 'failed' && usersListState.error && (
          <ErrorBox
            code={usersListState.error.name}
            description={usersListState.error.message}
            sx={{ minHeight: '90vh' }}
          >
            <Button
              variant="contained"
              onClick={async () => await dispatch(loadUsers())}
            >
              Try again
            </Button>
          </ErrorBox>
        )}
      </Box>
      {usersListState.selectUsersDialogOpened && (
        <SelectUserDialog
          open={usersListState.selectUsersDialogOpened}
          alreadyAddedUsersId={usersListState.users.map((u) => u.userId)}
          onCancel={() => dispatch(hideSelectUserDialog())}
          onOk={(userId) => {
            dispatch(hideSelectUserDialog())
            dispatch(addUser(userId))
          }}
        />
      )}
      <ErrorDialog
        open={usersListState.actionError !== undefined}
        title={usersListState.actionError?.name || 'Error'}
        text={usersListState.actionError?.message ?? ''}
        onOk={() => dispatch(setActionError())}
      />
    </Container>
  )
}

export default UsersListPage

import {
  Box,
  Divider,
  Stack,
} from '@mui/material'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import * as React from 'react'
import {
  type FC,
  type PropsWithChildren,
} from 'react'

import { useAppDispatch } from 'app/store/hooks'
import { setPreviewOpened } from 'app/store/units/initialsDialogSlice'

import DocumentPreview from '../preview/DocumentPreview'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

interface Props extends PropsWithChildren {
  open: boolean
}

export const ModalImagePreview: FC<Props> = (props) => {
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(setPreviewOpened(false))
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="ModalImagePreview-title"
        aria-describedby="ModalImagePreview-description"
      >
        <Box sx={{ ...style }}>
          <DocumentPreview />
          <Divider />
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
          >
            <Button
              sx={{ marginTop: 1 }}
              variant="outlined"
              color="primary"
              onClick={handleClose}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}

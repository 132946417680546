import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import usersApi from 'shared/api/administration/UsersApi'

import { RoleDto } from '../../../shared/model/contracts/administration/Roles'
import { type RootState } from '../store'

export interface UsersListState {
  status: 'idle' | 'loading' | 'failed'
  users: UserListItem[]
  selectUsersDialogOpened: boolean
  error?: SerializedError
  actionError?: SerializedError
}

export interface UserListItem {
  status: 'idle' | 'loading' | 'saving' | 'failed'
  userId: string
  userPrincipalName: string
  displayName: string
  enableLogin: boolean
  roles?: RoleDto[]
}

const initialState: UsersListState = {
  status: 'idle',
  selectUsersDialogOpened: false,
  users: [],
}

export const loadUsers = createAsyncThunk('users-list/load', async () => {
  return await usersApi.getUsers()
})

export const updateEnableLogin = createAsyncThunk(
  'users-list/update-enable-login',
  async (data: { userId: string, enableLogin: boolean }, thunkApi) => {
    const {
      userId,
      enableLogin,
    } = data
    await usersApi.updateEnableLogin(userId, enableLogin)
  },
)

export const addUser = createAsyncThunk('users-list/add-user', async (userId: string, thunkApi) => {
  return await usersApi.addUser(userId)
})

export const usersListSlice = createSlice({
  name: 'users-list',
  initialState,
  reducers: {
    setActionError: (state, action: PayloadAction<Error | undefined>) => {
      state.actionError = action.payload
    },
    showSelectUserDialog: (state) => {
      state.selectUsersDialogOpened = true
    },
    hideSelectUserDialog: (state) => {
      state.selectUsersDialogOpened = false
    },
  },
  extraReducers: (build) => {
    build.addCase(loadUsers.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(loadUsers.fulfilled, (state, action) => {
      state.status = 'idle'
      state.users = action.payload.map((u) => {
        return {
          status: 'idle',
          userId: u.userId,
          userPrincipalName: u.userPrincipalName,
          displayName: u.displayName,
          enableLogin: u.enableLogin,
          roles: u.roles,
        } as UserListItem
      })
    })

    build.addCase(loadUsers.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error
    })

    build.addCase(updateEnableLogin.pending, (state, action) => {
      const user = state.users.find((u) => u.userId === action.meta.arg.userId)
      if (user) {
        user.status = 'saving'
      }
    })

    build.addCase(updateEnableLogin.fulfilled, (state, action) => {
      const user = state.users.find((u) => u.userId === action.meta.arg.userId)
      if (user) {
        user.status = 'idle'
        user.enableLogin = action.meta.arg.enableLogin
      }
    })

    build.addCase(updateEnableLogin.rejected, (state, action) => {
      const user = state.users.find((u) => u.userId === action.meta.arg.userId)
      if (user) {
        user.status = 'failed'
        state.actionError = action.error
      }
    })

    build.addCase(addUser.pending, (state, action) => {
      state.users = [
        ...state.users,
        {
          userId: action.meta.arg,
          displayName: 'Adding...',
          status: 'loading',
        } as UserListItem,
      ]
    })

    build.addCase(addUser.fulfilled, (state, action) => {
      const user = state.users.find((u) => u.userId === action.meta.arg)
      if (user) {
        user.status = 'idle'
        user.displayName = action.payload.displayName
        user.userPrincipalName = action.payload.userPrincipalName
        user.enableLogin = action.payload.enableLogin
      }
    })

    build.addCase(addUser.rejected, (state, action) => {
      state.users = state.users.filter((u) => u.userId !== action.meta.arg)
      state.actionError = action.error
    })
  },
})

export const {
  setActionError,
  showSelectUserDialog,
  hideSelectUserDialog,
} = usersListSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsersList = (state: RootState) => state.administration.users.list

export default usersListSlice.reducer

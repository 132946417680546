import {Box, FormControlLabel, FormGroup} from '@mui/material'
import {type FC, useEffect, useState} from 'react'

import {QualityImageItem} from "features/logistics/openQAImageGallery";
import {SelectQaPreviewForLoading} from "features/logistics/selectQAPreviewForLoading";

import {QaImageListItem} from "entities/logistics/TechnicalCard";

import {
  ModuleName,
  useFeatureSlicedDebug,
} from 'shared/lib/useFeatureSlicedDebug'

interface QaImageListItemWidgetProps {
  sx?: Record<string, unknown>
  imageInfo:QualityImageItem
}

function getImageUrlPreview (token:string):string {
  return `${process.env.REACT_APP_QA_API}/api/documents/${token}/preview`
}

export const QaImageListItemWidget: FC<QaImageListItemWidgetProps> = ({
  sx,
  imageInfo,
}) => {

  const {
    token,
    documentId,
    purchaseOrder,
  } = imageInfo

  const [image, setImage] = useState<string>('')

  useEffect(() => {
    if (token){
      setImage(getImageUrlPreview(token))
    }
  }, [token]);

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('widget/QaImageListItemWidget' as ModuleName)

  return (
    <Box
      {...rootAttributes}
      sx={{...sx}}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <QaImageListItem
              previewUrl={image}
              key={documentId?.toString()}
              title={`${documentId?.toString() || ''} ${purchaseOrder?.number || ''}`}
              qaActions={[
                <SelectQaPreviewForLoading
                  key={SelectQaPreviewForLoading.displayName}
                  token={token || ''}
                  imageName={`${documentId?.toString() || ''} ${purchaseOrder?.number || ''}`}
                />,
              ]}
            />
          }
          label={``}
        />
      </FormGroup>
    </Box>
  )
}

QaImageListItemWidget.displayName = 'QaImageListItemWiget'

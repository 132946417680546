import {
  Box,
  Button,
} from '@mui/material'
import { type FC } from 'react'

import {
  TechnicalCardApproveState,
  technicalCardsApi,
  UnitTechnicalDescription,
} from 'entities/logistics/TechnicalCard'

import {
  useGetUnitApproveState,
  useIsUnitHasEmptyNecessaryFields,
} from 'shared/lib/logistics'
import {
  ModuleName,
  useFeatureSlicedDebug,
} from 'shared/lib/useFeatureSlicedDebug'

interface SetTechnicalDescriptionAcceptanceStatusProps {
  unitID:UnitTechnicalDescription['unitId']
  loading?: boolean
  sx?: Record<string, unknown>
}

const ButtonCaptionOnState: Record<TechnicalCardApproveState, string>={
  [TechnicalCardApproveState.Draft]:'Утвердить',
  [TechnicalCardApproveState.Rejected]:'Утвердить',
  [TechnicalCardApproveState.Approved]:'На согласование',
}

export const SetTechnicalDescriptionAcceptanceStatus: FC<SetTechnicalDescriptionAcceptanceStatusProps> = ({
  unitID,
  loading,
  sx,
}) => {

  const [
    updateApproveState,
  ] = technicalCardsApi.useUpdateApproveCardStateMutation()

  const isHasErrors = useIsUnitHasEmptyNecessaryFields(unitID)

  const unitState = useGetUnitApproveState(unitID)

  const setApproveStatus = ()=>{
    const nextState = unitState === TechnicalCardApproveState.Approved
      ? TechnicalCardApproveState.Rejected
      : TechnicalCardApproveState.Approved
    updateApproveState({
      unitId:unitID,
      state:nextState,
    })
  }

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('feature/ToggleTemplateActivity' as ModuleName)
  return (
    <Box
      {...rootAttributes}
      sx={
        Object.assign(
          {},
          sx,
        )
      }
    >
      <Button
        disabled={isHasErrors||!unitState||loading}
        variant={'contained'}
        onClick={setApproveStatus}
      >
        {!!unitState && ButtonCaptionOnState[unitState]}
      </Button>
    </Box>
  )
}

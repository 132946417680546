import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  Draft,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit'

import unitsApi from 'shared/api/UnitsApi'
import { type UnitDto } from 'shared/model/contracts/UnitDto'

import { type RootState } from '../store'

export enum ItemsOnPage {
  c10  = 10,
  c25  = 25,
  c50  = 50,
  c100 = 100,
  c200 = 200,
}

export interface UnitsListState {
  status: 'idle' | 'loading' | 'failed'
  page: number
  pages: number
  itemsOnPage: ItemsOnPage
  count: number
  matchCount: number
  units: UnitDto[]
  missedArticles: string[]
  internalErrors: string[]
  metaInformation?: Record<string, unknown>
  error?: SerializedError
}

const initialState: UnitsListState = {
  status: 'idle',
  count: 0,
  matchCount: 0,
  page: 0,
  pages: 0,
  itemsOnPage: ItemsOnPage.c10,
  units: [],
  missedArticles: [],
  internalErrors: [],
}

export const load = createAsyncThunk(
  'units-list/load',
  async (request: {
    filter?: string,
    page: number,
    startDate?: string,
    endDate?: string,
    pageSize?: number,
    sortOrder?: string,
    mfg: boolean,
  }) => {
    if (request.filter) {
      return await unitsApi.getUnits(
        request.filter,
        request.page,
        request.mfg,
        request.startDate,
        request.endDate,
        request.pageSize
          ? request.pageSize
          : 10,
        request.sortOrder,
      )
    }
  },
)

export const unitsListSlice = createSlice({
  name: 'units-list',
  initialState,
  reducers: {
    setPage: (
      state: Draft<UnitsListState>, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    clearError: (state: Draft<UnitsListState>) => {
      state.error = undefined
    },
    clearInternalErrors: (state: Draft<UnitsListState>) => {
      state.internalErrors = []
    },
    setItemCountOnPage: (
      state: Draft<UnitsListState>, action: PayloadAction<ItemsOnPage>) => {
      state.itemsOnPage = action.payload
    },
  },

  extraReducers: (build) => {
    build.addCase(load.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(load.fulfilled, (state, action) => {
      state.status = 'idle'

      if (action.payload) {
        state.matchCount = action.payload?.matchCount || 0
        state.count = action.payload?.count ||0
        state.page = action.payload?.page || 0
        state.pages = action.payload?.pages || 0
        state.units = action.payload?.items ||[]
        state.missedArticles = action.payload?.missedArticles||[]
        state.internalErrors = action.payload.internalErrors||[]
        state.metaInformation = action.payload.metaInformation||{}
      } else {
        state.page = 0
        state.pages = 0
        state.units = []
        state.internalErrors = []
      }
    })

    build.addCase(load.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error
    })
  },
})

export const {
  setPage,
  clearError,
  clearInternalErrors,
  setItemCountOnPage,
} = unitsListSlice.actions

const selectUnitsState = (state: RootState) => state.units.list
export const selectUnitsList = createDraftSafeSelector(
  selectUnitsState
  , (s): UnitsListState => s.items,
)
export const selectItemsCount = createDraftSafeSelector(
  selectUnitsList,
  (s: UnitsListState): number => s.itemsOnPage,
)
export const selectMissedArticles = createDraftSafeSelector(
  selectUnitsList,
  (s: UnitsListState): string[] => s.missedArticles,
)

export default unitsListSlice.reducer

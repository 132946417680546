import {Box, CircularProgress, ImageList, Typography} from '@mui/material'
import {TypedUseQueryHookResult} from "@reduxjs/toolkit/dist/query/react";
import {type FC } from 'react'

import {QualityImageItemPaginatedResponse} from "features/logistics/openQAImageGallery";
import {QualityImageItem} from "features/logistics/openQAImageGallery";
import {qaPreviewDataApi} from "features/logistics/openQAImageGallery/model/qaPreviewDataApi";

import {UnitTechnicalDescription} from "entities/logistics/TechnicalCard";

import {
  ModuleName,
  useFeatureSlicedDebug,
} from 'shared/lib/useFeatureSlicedDebug'

import {QaImageListItemWidget} from "./QaImageListItemWidget";

interface QaImageListProps {
  unit:UnitTechnicalDescription
  sx?: Record<string, unknown>
}

export const QaImageList: FC<QaImageListProps> = ({
  sx,
  unit,
}) => {

  const unitId = unit?.unitId || 0

  const {
    data,
    isLoading,
    isError,
  }: TypedUseQueryHookResult<QualityImageItemPaginatedResponse, any, any> = qaPreviewDataApi.endpoints.getPreviewData.useQuery({
    unitId,
    page: 0,
    pageSize:30,
  })

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('widget/QaImageList' as ModuleName)
  return (
    <Box
      {...rootAttributes}
      sx={{
        display:'flex',
        alignContent:'center',
        justifyContent:'center',
        ...sx,
      }}
    >
      {isError && <Typography>Error on loading images</Typography>}
      {isLoading
        ? <CircularProgress/> :
        <ImageList>
          { data?.items?.length ?
            data?.items?.map((item:QualityImageItem) => {
              return <QaImageListItemWidget imageInfo={item}/>||<></>}) || <></>
            : <Typography>No images found</Typography>
          }
        </ImageList>}
    </Box>
  )
}

QaImageList.displayName = 'QaImageList'

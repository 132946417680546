import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  type FC,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { RhmTextField } from '../ReactHookFormMuiComponents/RhmTextField'

import { EditableFieldTextData } from './EditableFieldTextData'

interface EditableTextStringFieldProps {
  fieldName: string
  value: string
  readonly?:boolean
  changeDataHandler: (data: FormData) => void
}

const formDataSchema = z.object({valueData: z.string().max(20).nullable()})

type FormData = z.infer<typeof formDataSchema>

export const EditableTextStringField: FC<EditableTextStringFieldProps> = ({
  fieldName,
  value,
  readonly,
  changeDataHandler,
}) => {

  const [isEditable, setIsEditable] = useState(false)

  const {
    handleSubmit,
    control,
    formState: {
      errors,
    },
    resetField,
  } = useForm<FormData>({ resolver: zodResolver(formDataSchema) })

  const onSubmitData = (data: FormData) => {
    changeDataHandler(data)
    setIsEditable(false)
  }

  if (!isEditable) {
    return (
      <EditableFieldTextData
        fieldName={fieldName}
        dataValue={value}
        readonly={readonly}
        onClick={() => {
          setIsEditable(true)
        }}
      />
    )
  }

  return (
    <Stack onBlur={handleSubmit(onSubmitData)}>
      {fieldName && <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          fontSize: '14px',
        }}
      >
        {fieldName}
      </Typography>}
      <form onSubmit={handleSubmit(onSubmitData)}>
        <RhmTextField
          name={'valueData'}
          control={control}
          value={value}
          resetField={resetField}
          setIsEditable={setIsEditable}
        />
        <p>{errors.valueData?.message}</p>

      </form>
    </Stack>
  )
}

import { ApplicationInformation } from '../model/contracts/ApplicationInformationDto'

import { API_ADDRESS } from './api'
import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

const GET_EC_APPLICATION_INFORMATION = `${API_ADDRESS}/api/info`
const GET_LOGISTICS_APPLICATION_INFORMATION = `${API_ADDRESS}/api/logistics/info`

export class ApplicationInformationApi {
  async getEcApplicationInfo () {
    const token = await authService.getIdentity()
    return await msalFetch()
      .get(GET_EC_APPLICATION_INFORMATION, token.accessToken)
      .then(handleErrors)
      .then<ApplicationInformation>(async (res) => await res.json())
  }

  async getLogisticsApplicationInfo () {
    const token = await authService.getIdentity()
    return await msalFetch()
      .get(GET_LOGISTICS_APPLICATION_INFORMATION, token.accessToken)
      .then(handleErrors)
      .then<ApplicationInformation>(async (res) => await res.json())
  }
}

const applicationInformationApi = new ApplicationInformationApi()

export default applicationInformationApi

import {
  useEffect,
  useState,
} from 'react'

export function getStorageValue (key: string, defaultValue: any): any {
  const saved = localStorage.getItem(key)
  if (saved) {
    return JSON.parse(saved)
  }
  return defaultValue
}

export const useLocalStorage = (key: string, defaultValue: any) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue)
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

export function clearPersistKeys (predicate: () => boolean, keys: string[]): void {
  if (predicate()) {
    keys.forEach((key) => {
      localStorage.removeItem(`persist:${key}`)
    })
  }
}

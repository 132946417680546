import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import {
  Box,
  Button,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  type FC,
  useState, useEffect,
} from 'react'

import {
  CatalogNames,
  CatalogRecord,
} from 'entities/logistics/Catalog'
import { UseCatalogCRUDApiHooks } from 'entities/logistics/Catalog/api/useCatalogCRUDApiHooks'

import {
  ModuleName,
  useFeatureSlicedDebug,
} from 'shared/lib/useFeatureSlicedDebug'
import { SelectMultipleItems } from 'shared/ui/components/SelectDialogs/SelectMultipleItems'

import { useGetUnitForecastValuesMutation } from '../../../../entities/logistics/TechnicalCard/model/technicalCardsApi'

interface SelectPurposesProps {
  unitId?: number
  currentContent: string
  setCurrentContent: React.Dispatch<React.SetStateAction<string>>
  sx?: Record<string, unknown>
}

export const SelectPurposes: FC<SelectPurposesProps> = ({
  unitId,
  currentContent,
  setCurrentContent,
  sx,

}) => {
  const [forecastValues, setforecastValues] = useState<CatalogRecord[] | undefined>(undefined)
  const [isOpenSelectPurposes, setIsOpenSelectPurposes] = useState(false)

  const {
    getRecords,
  } = UseCatalogCRUDApiHooks(CatalogNames.purposes)
  const showMultipleSelectPurposes = () => {
    setIsOpenSelectPurposes(true)
  }

  const [
    getForecast, {
      data,
      isLoading,
      error,
      isSuccess,
    },
  ] = useGetUnitForecastValuesMutation()

  useEffect(() => {
    if (data) {
      const forecastValues: CatalogRecord[] | undefined = data['purposes'] as CatalogRecord[] | undefined
      setforecastValues(forecastValues)
    }
  }, [data])

  const addSelectedValuesToText = (data: CatalogRecord[] | undefined) => {
    if (typeof data === 'undefined') {
      return
    }
    const newContent = currentContent.concat(data.reduce((acc: string, curRecord: CatalogRecord) => {
      return acc.concat(' ', curRecord.value)
    }, ''))

    setCurrentContent(newContent)
    setforecastValues(undefined)
    setIsOpenSelectPurposes(false)
  }

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('feature/SelectPurposes' as ModuleName)
  return (
    <Box
      {...rootAttributes}
      sx={{ ...sx }}
    >
      <Button
        fullWidth={true}
        component="label"
        variant="outlined"
        onClick={showMultipleSelectPurposes}
        startIcon={<ContentPasteSearchIcon/>}
      >
        <Typography>
          Выбрать из справочника
        </Typography>
      </Button>

      {isOpenSelectPurposes &&
        <SelectMultipleItems
          selectedValues={[]}
          forecastValues={forecastValues}
          catalogValues={getRecords.currentData}
          title={'Выбрать назначение'}
          onSaveAction={addSelectedValuesToText}
          onForecastAction={
            () => {
              getForecast({ unitId: unitId })
            }
          }
        />
      }

    </Box>
  )
}

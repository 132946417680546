import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { IconButton } from '@mui/material'
import {
  type FC,
  useState, useEffect,
} from 'react'

import { useAppDispatch } from 'app/store'

import {technicalCardCacheUpdate, isUpdateImageData, isUpdateSchemesData} from 'entities/logistics/TechnicalCard/model/technicalCardsApi'

import { unitPicturesApi } from 'shared/api/unitImagesApi'
import { type UnitDto } from 'shared/model/contracts/UnitDto'
import YesNoDialog from 'shared/ui/components/YesNoDialog'

interface DeletePictureProps {
  unitId: UnitDto['unitFileId']
  imageId: number
  imageType: string
  imageName: string
  isDisabled?:boolean
}

export const DeletePicture: FC<DeletePictureProps> = ({
  unitId,
  imageId,
  imageType,
  imageName,
  isDisabled,
}) => {

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const dispatch = useAppDispatch()
  const [deleteImage, {
    data,
    // eslint-disable-next-line no-unsafe-optional-chaining
  }] = unitPicturesApi?.endpoints?.deleteUnitPicture.useMutation()

  const handleClick = () => {
    setIsOpenDeleteDialog(true)
  }

  useEffect(() => {
    if (data?.unitId) {
      if(data){
        if (isUpdateImageData(data)) {
          technicalCardCacheUpdate(dispatch, data.unitId, {images: data.images})
        }
        if (isUpdateSchemesData(data)) {
          technicalCardCacheUpdate(dispatch, data.unitId, {schemes: data.schemes})
        }
      }
    }
  }, [data])

  return (
    <>
      <IconButton
        onClick={handleClick}
        disabled={isDisabled}
      >
        <ClearOutlinedIcon />
      </IconButton>
      <YesNoDialog
        open={isOpenDeleteDialog}
        title={`Do you really want to delete image "${imageName}"`}
        onNo={() => {
          setIsOpenDeleteDialog(false)
        }}
        onYes={() => {
          deleteImage({
            unitId,
            imageId,
            imageType,
          })

          setIsOpenDeleteDialog(false)
        }}
      />
    </>
  )
}

import { type Theme } from '@mui/material'
import {
  createTheme,
  type ThemeOptions,
} from '@mui/material/styles'

import { baseTheme } from './baseTheme'

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#7858A6',
      dark: '#694d91',
    },
    secondary: {
      main: '#f86a04',
      contrastText: '#fff',
    },
    divider: '#7858A6',
    warning: {main: '#f35253'},
    error: {main: '#d83027'},
    background: {default: '#fff'},
  },
  typography: {
    fontFamily: 'Inter, "Roboto", "Helvetica", "Arial", sans-serif',
    // заголовки блоков
    h1: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
      },
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '28px',
      },
    },
    // заголовки таблиц
    h2: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
      },
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
      },
    },
    body1: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
      },
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
      },
    },
    button: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
      },
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
      },
    },
    caption: {
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
      },
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '18px',
      },
    },

  },
  shape: {borderRadius: 8},
  spacing: 8,
  breakpoints: {
    values: {
      xs: 360,
      sm: 600,
      md: 1280,
      lg: 1440,
      xl: 1920,
      tablet: 1280,
      desktop: 1440,
    },
  },
  components: {
    MuiAccordion: {styleOverrides: {root: {borderRadius: 12}}},
    MuiTableCell: {
      styleOverrides: {
        root: (componentState) => {
          // @ts-ignore
          if (componentState?.as === 'th') {
            return {
              [baseTheme.breakpoints.up('md')]: {
                ...baseTheme.typography.body1,
                color: '#101828',

              },
              [baseTheme.breakpoints.down('md')]: {
                ...baseTheme.typography.body1,
                color: '#667085',
              },
            }
          }
          return {
            ...baseTheme.typography.body1,
            color: '#667085',
          }
        },
      },
    },
  },
}

export const logisticsTheme: Theme = createTheme(themeOptions)

import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { type Theme } from '@mui/material/styles'
import { type SxProps } from '@mui/system'
import { type FC } from 'react'

import { type UnitDto } from 'shared/model/contracts/UnitDto'

interface NavigateToElementProps {
  elementId: UnitDto['elementId']
  sx?: SxProps<Theme>
}

export const NavigateToElement: FC<NavigateToElementProps> = ({
  elementId,
  sx: sxOverwrite,
}) => {

  const elementLink = `/units?filter=${elementId}`
  return (
    <IconButton sx={{
      padding: '0px',
      color: 'white',
      ...sxOverwrite,
    }}
    >
      <Link
        href={elementLink}
        target="_blank"
        sx={{
          display: 'flex',
          color: 'white',
          ...sxOverwrite,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
        >
          <Typography variant="h1">{elementId}</Typography>
          <OpenInNewIcon fontSize="small" />
        </Stack>
      </Link>
    </IconButton>

  )
}

import {Box, Checkbox} from '@mui/material'
import {type FC, useEffect} from 'react'
import {useToggle} from "usehooks-ts";

import {
  ModuleName,
  useFeatureSlicedDebug,
} from 'shared/lib/useFeatureSlicedDebug'

import {useAppDispatch} from "../../../../app/store";
import {qaImageGallerySlice} from "../../openQAImageGallery";

interface SelectQaPreviewForLoadingProps {
  sx?: Record<string, unknown>,
  token:string,
  imageName:string
}

export const SelectQaPreviewForLoading: FC<SelectQaPreviewForLoadingProps> = ({
  sx, token,imageName,
}) => {

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('feature/SelectQaPreviewForLoading' as ModuleName)

  const [isImageSelected,toggleSelected] = useToggle(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isImageSelected && token) {
      dispatch(qaImageGallerySlice.actions.addTokenToSelectedImages({
        token,
        imageName,
      }))
    }
    if (!isImageSelected && token) {
      dispatch(qaImageGallerySlice.actions.deleteTokenFromSelectedImages(token))
    }
  }, [isImageSelected,dispatch]);

  function onChangeSelection (){
    toggleSelected()
  }

  return (
    <Box
      {...rootAttributes}
      sx = {
        {...sx}
      }
    >
      <Checkbox
        value={isImageSelected}
        onChange={onChangeSelection}
        color={'info'}
        sx={{color:'white'}}
      />
    </Box>
  )
}

SelectQaPreviewForLoading.displayName = 'SelectQaPreviewForLoading'

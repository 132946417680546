export const PermissionsDocumentationOutlineDownload = 'Permissions.Documentation.Outline.Download'
export const PermissionsDocumentationInspectionDownload = 'Permissions.Documentation.Inspection.Download'
export const PermissionsDocumentationWorkingDownload = 'Permissions.Documentation.Working.Download'
export const PermissionsClaimsView = 'Permissions.Claims.View'
export const PermissionsOneCView = 'Permissions.OneC.View'
export const PermissionsToolingView = 'Permissions.Tooling.View'
export const PermissionsAnalogsView = 'Permissions.Analogs.View'
export const PermissionsSubOemView = 'Permissions.SubOem.View'
export const PermissionsAdministrationEdit = 'Permissions.Administration.Edit'
export const PermissionsDocumentationPrint = 'Permissions.Documentation.Print'
export const PermissionsDocumentationPrintReplaceNumber = 'Permissions.Documentation.Print.ReplaceNumber'
export const PermissionsDocumentationPrintAddRequirements = 'Permissions.Documentation.Print.AddRequirements'
export const PermissionsDocumentationPrintReplaceLogo = 'Permissions.Documentation.Print.ReplaceLogo'
export const PermissionsDocumentationInitialsView = 'Permissions.Documentation.Initials.View'
export const PermissionsLogisticsUnitsReplicate = 'Permissions.Logistics.Units.Replicate'
export const PermissionsLogisticsUnitsMultiReplicate = 'Permissions.Logistics.Units.MultiReplicate'

export enum PermissionsModule {
  Logistics = 'Logistics',
}

export enum PermissionsLogisticSection {
  Guides = 'Guides',
  Units = 'Units',
}

export enum PermissionsLogisticActions {
  View = 'View',
  Print = 'Print',
  ChangeState = 'ChangeState',
}

export const AvailableEntitiesAction = {
  [PermissionsLogisticSection.Guides]: {
    [PermissionsLogisticActions.View]: PermissionsLogisticActions.View,
    [PermissionsLogisticActions.Print]: PermissionsLogisticActions.Print,
  },
  [PermissionsLogisticSection.Units]: {
    [PermissionsLogisticActions.View]: PermissionsLogisticActions.View,
    [PermissionsLogisticActions.Print]: PermissionsLogisticActions.Print,
    [PermissionsLogisticActions.ChangeState]: PermissionsLogisticActions.ChangeState,
  },
}

export const AvailableModuleSection = {[PermissionsModule.Logistics]: PermissionsLogisticSection}

export type PermissionSection = PermissionsLogisticSection
export type PermissionActions = PermissionsLogisticActions

import { CircularProgress } from '@mui/material'
import {

  type FunctionComponent,
  memo,
  useEffect,
  useState,
} from 'react'
import ReactPlayer from 'react-player'

import authService from 'shared/api/AuthService'

import ImageViewer from './ImageViewer'
import PdfViewer from './PdfViewer'

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  contenttype: string
}

const AuthorizedViewer: FunctionComponent<Props> = (props) => {
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    setLoading(true)
    setError(null)
    authService
      .getIdentity()
      .then((result) => {
        setToken(result.accessToken)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        setError(error)
      })
  }, [props.src])

  if (loading) {
    return <CircularProgress />
  }

  if (error != null) {
    return <></>
  }

  const urlWithToken = props.src
    ? `${props.src}?token=${token}`
    : ''

  if (props.contenttype === 'application/pdf') {
    return <PdfViewer url={urlWithToken} />
  }

  if (props.contenttype.match('image/*') != null) {
    return <ImageViewer
      urlwithtoken={urlWithToken}
      {...props}
    />
  }

  if (props.contenttype.match('video/*') != null) {
    return <ReactPlayer
      url={urlWithToken}
      playing
      controls
      width="100%"
      height="100%"
    />
  }

  return <></>
}

export default memo(AuthorizedViewer)

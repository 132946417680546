import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import rolesApi from 'shared/api/administration/RolesApi'
import { type OptionDto } from 'shared/model/contracts/administration/Options'
import { type RightDto } from 'shared/model/contracts/administration/Rights'

import { type RootState } from '../store'

export interface EditRoleState {
  status: 'idle' | 'loading' | 'saving' | 'failed'
  roleId?: number
  name: string
  editName: string
  users: UserInRoleItem[]
  rights: Right[]
  options: Option[]
  pageError?: SerializedError
  actionError?: SerializedError
  openAddUserDialog: boolean
  openEditRightsDialog: boolean
  openEditOptionsDialog: boolean
  openConfirmDeleteDialog: boolean
  backUrl?: string
}

export interface UserInRoleItem {
  status: 'idle' | 'saving'
  userId: string
  displayName: string
}

export interface Right {
  rightId: number
  name: string
  description: string
  grouping: string
}

export interface Option {
  optionId: number
  name: string
  description: string
  grouping: string
}

const initialState: EditRoleState = {
  status: 'loading',
  name: '',
  editName: '',
  users: [],
  rights: [],
  options: [],
  openAddUserDialog: false,
  openEditRightsDialog: false,
  openEditOptionsDialog: false,
  openConfirmDeleteDialog: false,
}

export const loadRole = createAsyncThunk('edit-role/load', async (roleId: number | string) => {
  return await rolesApi.getRole(roleId)
})

export const addUsers = createAsyncThunk(
  'edit-role/add-users',
  async (data: { roleId: number | string, users: string[] }) => {
    return await rolesApi.addUsers(data.roleId, data.users)
  },
)

export const removeUser = createAsyncThunk(
  'edit-role/remove-user',
  async (data: { roleId: number | string, userId: string }) => {
    await rolesApi.removeUser(data.roleId, data.userId)
  },
)

export const saveChanges = createAsyncThunk(
  'edit-role/save-changes',
  async (data: { roleId: number | string, name: string }) => {
    await rolesApi.updateRole(data.roleId, { name: data.name })
  },
)

export const deleteRole = createAsyncThunk('edit-role/delete-role', async (roleId: number | string) => {
  await rolesApi.deleteRole(roleId)
})

export const editRoleSlice = createSlice({
  name: 'edit-role',
  initialState,
  reducers: {
    setBackUrl: (state, action: PayloadAction<string | undefined>) => {
      state.backUrl = action.payload
    },
    showActionError: (state, action: PayloadAction<Error>) => {
      state.actionError = action.payload
    },
    hideActionError: (state) => {
      state.actionError = undefined
    },
    showAddUserDialog: (state) => {
      state.openAddUserDialog = true
    },
    hideAddUserDialog: (state) => {
      state.openAddUserDialog = false
    },
    showEditRightsDialog: (state) => {
      state.openEditRightsDialog = true
    },
    showEditOptionsDialog: (state) => {
      state.openEditOptionsDialog = true
    },
    hideEditRightsDialog: (state) => {
      state.openEditRightsDialog = false
    },
    hideEditOptionsDialog: (state) => {
      state.openEditOptionsDialog = false
    },
    setRights: (state, action: PayloadAction<RightDto[]>) => {
      state.rights = action.payload?.map((r) => {
        return {
          rightId: r.rightId,
          name: r.name,
          description: r.description,
          grouping: r.grouping,
        } as Right
      })
    },
    setOptions: (state, action: PayloadAction<OptionDto[]>) => {
      state.options = action.payload?.map((o) => {
        return {
          optionId: o.optionId,
          name: o.name,
          description: o.description,
          grouping: o.grouping,
        } as Option
      })
    },
    setEditName: (state, action: PayloadAction<string>) => {
      state.editName = action.payload
    },
    showConfirmDeleteDialog: (state) => {
      state.openConfirmDeleteDialog = true
    },
    hideConfirmDeleteDialog: (state) => {
      state.openConfirmDeleteDialog = false
    },
  },
  extraReducers: (build) => {
    build.addCase(loadRole.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(loadRole.fulfilled, (state, action) => {
      state.status = 'idle'
      state.roleId = action.payload.roleId
      state.name = action.payload.name
      state.editName = action.payload.name
      state.users = action.payload.users?.map((u) => {
        return {
          userId: u.userId,
          displayName: u.displayName,
        } as UserInRoleItem
      })
      state.rights = action.payload.rights?.map((r) => {
        return {
          rightId: r.rightId,
          name: r.name,
          description: r.description,
          grouping: r.grouping,
        } as Right
      })
      state.options = action.payload.options?.map((o) => {
        return {
          optionId: o.optionId,
          name: o.name,
          description: o.description,
          grouping: o.grouping,
        } as Option
      })
    })

    build.addCase(loadRole.rejected, (state, action) => {
      state.status = 'failed'
      state.pageError = action.error
    })

    build.addCase(addUsers.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(addUsers.fulfilled, (state, action) => {
      state.status = 'idle'

      const newUsers = action.payload.map((u) => {
        return {
          userId: u.userId,
          displayName: u.displayName,
          status: 'idle',
        } as UserInRoleItem
      })

      state.users = [...state.users, ...newUsers]
    })

    build.addCase(addUsers.rejected, (state, action) => {
      state.status = 'idle'
      state.actionError = action.error
    })

    build.addCase(removeUser.pending, (state, action) => {
      const user = state.users.find((u) => u.userId === action.meta.arg.userId)
      if (user) {
        user.status = 'saving'
      }
    })

    build.addCase(removeUser.fulfilled, (state, action) => {
      state.users = state.users.filter((u) => u.userId !== action.meta.arg.userId)
    })

    build.addCase(removeUser.rejected, (state, action) => {
      const user = state.users.find((u) => u.userId === action.meta.arg.userId)
      if (user) {
        user.status = 'idle'
      }
      state.actionError = action.error
    })

    build.addCase(saveChanges.pending, (state, action) => {
      state.status = 'saving'
    })

    build.addCase(saveChanges.fulfilled, (state, action) => {
      state.status = 'idle'
      state.name = state.editName
    })

    build.addCase(saveChanges.rejected, (state, action) => {
      state.status = 'idle'
      state.actionError = action.error
    })

    build.addCase(deleteRole.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(deleteRole.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(deleteRole.rejected, (state, action) => {
      state.status = 'idle'
      state.actionError = action.error
    })
  },
})

export const {
  setBackUrl,
  showActionError,
  hideActionError,
  showAddUserDialog,
  hideAddUserDialog,
  showEditRightsDialog,
  hideEditRightsDialog,
  showEditOptionsDialog,
  hideEditOptionsDialog,
  setRights,
  setOptions,
  setEditName,
  showConfirmDeleteDialog,
  hideConfirmDeleteDialog,
} = editRoleSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEditRole = (state: RootState) => state.administration.roles.edit.editRole

export default editRoleSlice.reducer

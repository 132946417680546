import React from 'react'

export class ErrorBoundary extends React.Component {
  constructor (props: unknown) {
    // @ts-expect-error
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (e: unknown) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true }
  }

  componentDidCatch (e: unknown, eInfo: unknown) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    // logErrorToMyService(error, errorInfo);
  }

  render () {
    // @ts-expect-error
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return <h1>Что-то пошло не так.</h1>
    }

    // @ts-expect-error
    return this.props.children
  }
}

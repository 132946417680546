import ImportExportIcon from '@mui/icons-material/ImportExport';
import { IconButton, type SxProps, type Theme, Tooltip } from '@mui/material'
import { type FC } from 'react'

import { themeSpacingFromPixels } from '../../shared/lib/workWithThemeSpacing'

import { SelectableDescription } from './units/searchUnts'

interface Props {
  description: SelectableDescription
  setDescription: (description: SelectableDescription) => void
  setOpen: (open: boolean) => void
  sx?: SxProps<Theme>
}

export const ImportDescriptionButton: FC<Props> = ({
  description,
  setDescription,
  setOpen,
  sx: sxOverwrite,
}) => {

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setOpen(true)
    setDescription(description)
  }

  return (
    <>
      <Tooltip
        arrow
        placement="bottom"
        title={'Импортировать из хранилища'}
      >
        <IconButton
          sx={{
            ml: 0.5,
            height: themeSpacingFromPixels(28),
            ...sxOverwrite,

          }}
          aria-label="import description"
          onClick={(e) => {
            handleClick(e)
          }}
          edge="start"
        >
          <ImportExportIcon sx={{
            pointerEvents: 'auto',
            width: '20px',
            height: '30px',
            ...sxOverwrite,
          }}
          />
        </IconButton>
      </Tooltip>
    </>
  )
}

export default ImportDescriptionButton

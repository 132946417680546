import { Typography } from '@mui/material'
import {
  type FC,
  useState,
} from 'react'

import { useAppSelector } from 'app/store/hooks'

import { type SelectableDescription } from 'features/logistics/units/searchUnts'
import { selectDescriptionsOnSearch } from 'features/logistics/units/searchUnts/model/selectableDescriptions'

import {
  type CatalogNames,
  type CatalogRecord,
} from 'entities/logistics/Catalog'
import { UseCatalogCRUDApiHooks } from 'entities/logistics/Catalog/api/useCatalogCRUDApiHooks'
import { AvailableUnitCatalogFields } from 'entities/logistics/Catalog/model/types'
import {
  type UnitTechnicalDescription,
  useGetUnitDescriptionsQuery,
} from 'entities/logistics/TechnicalCard'

import { SelectSingleItem } from '../SelectDialogs/SelectSingleItem'

import { EditableFieldTextData } from './EditableFieldTextData'

interface EditableSingleSelectItemsFieldProps {
  fieldName: string
  warning?: boolean
  readonly?: boolean,
  unitId: UnitTechnicalDescription['unitId']
  apiCatalogName: AvailableUnitCatalogFields
  changeDataHandler: (data:any ) => void
}

export const EditableSingleSelectItemsField: FC<EditableSingleSelectItemsFieldProps> = ({
  fieldName,
  unitId,
  readonly,
  warning = false,
  apiCatalogName,
  changeDataHandler,
}) => {

  const markedUnits: SelectableDescription[] | undefined = useAppSelector(selectDescriptionsOnSearch)
  const markedUnitsId = markedUnits?.map(unit => unit.unitId) || []
  // @ts-ignore
  const curUnit: UnitTechnicalDescription = useGetUnitDescriptionsQuery(markedUnitsId, {
    skip: !markedUnits,
    // @ts-ignore
    selectFromResult: ({
      data,
    }): UnitTechnicalDescription | undefined => {
      if (!data) {
        return undefined
      }
      return data?.find(description => description?.unitId === unitId)
    },
  })

  const {
    getRecords,
  } = UseCatalogCRUDApiHooks(apiCatalogName as CatalogNames)
  const [onEditing, setOnEditing] = useState(false)

  const selectedValue = curUnit[apiCatalogName] as CatalogRecord | undefined

  const selectedRecordsTextsPresentation = selectedValue?.value || ''

  return (
    <>
      {onEditing &&
        <SelectSingleItem
          title={fieldName}
          catalogValues={getRecords.currentData}
          selectedValue={selectedValue}
          onSaveAction={
            (data: CatalogRecord | undefined) => {
              if (data !== selectedValue) {
                changeDataHandler(data)
              }
              setOnEditing(false)
            }
          }
        />
      }
      <EditableFieldTextData
        warning={warning}
        readonly={readonly}
        fieldName={fieldName}
        dataValue={selectedRecordsTextsPresentation}
        onClick={
          () => {
            setOnEditing(true)
          }
        }
      />
    </>
  )
}

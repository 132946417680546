import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton,
  TextField,
} from '@mui/material'
import _ from 'lodash'
import {
  Fragment,
  type FunctionComponent,
  useEffect,
} from 'react'

import {
  clearActionError,
  type EditSettingsState,
  loadEditSettings,
  saveEditSettings,
  selectEditSettings,
  setSettingValue,
} from 'app/store/administration/editSettingsSlice'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

import {
  type AppSettingDto,
  type UpdateSettingDto,
} from 'shared/model/contracts/SettingsDto'
import ErrorBox from 'shared/ui/components/ErrorBox'
import ErrorDialog from 'shared/ui/components/ErrorDialog'

interface Props {
  open: boolean
  onCancel: () => void
  onOk: (settings: AppSettingDto[]) => void
}

const SettingsEditor: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()
  const editSettingsState: EditSettingsState = useAppSelector(selectEditSettings)
  const {
    status,
    settings,
    actionError,
  } = editSettingsState

  const failed = status === 'failed'
  const saving = status === 'saving'
  const loading = status === 'loading'
  const idle = status === 'idle'

  useEffect(() => {
    dispatch(loadEditSettings())
  }, [props.open, dispatch])

  const handleSave = () => {
    const updateSettings = settings.map((s) => {
      return {
        settingId: s.settingId,
        value: s.value,
      } as UpdateSettingDto
    })

    dispatch(saveEditSettings(updateSettings)).then((request) => {
      request.meta.requestStatus === 'fulfilled' && props.onOk(request.payload as AppSettingDto[])
    })
  }

  const orderedItesm = _.orderBy(settings, ['grouping', 'description'])
  const groups = _.groupBy(orderedItesm, 'grouping')

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>Edit settings</DialogTitle>
      <DialogContent>
        {loading && (
          <List>
            {Array.from(Array(5).keys()).map((i) => {
              return (
                <ListItem
                  key={i}
                  divider
                >
                  <Box sx={{ width: '100%' }}>
                    <Skeleton />
                  </Box>
                </ListItem>
              )
            })}
          </List>
        )}
        {failed && actionError && (
          <ErrorBox
            code={actionError.name}
            description={actionError.message}
          >
            <Button
              variant="contained"
              onClick={async () => await dispatch(loadEditSettings())}
            >
              Try again
            </Button>
          </ErrorBox>
        )}
        {(idle || saving) && (
          <List>
            {_.map(groups, (group, name) => (
              <Fragment key={name}>
                <ListSubheader
                  color="primary"
                  disableSticky
                  key={name}
                >
                  {name}
                </ListSubheader>
                {group.map((s) => {
                  return (
                    <ListItem
                      key={`editor-list-item-${s.settingId}`}
                      sx={{flexWrap:'wrap'}}
                    >
                      <ListItemText
                        primary={s.description}
                        secondary={s.name}
                      />
                      <TextField
                        id={`editor-textfeild-${s.settingId}`}
                        defaultValue={s.value}
                        variant="outlined"
                        onChange={(event) => {
                          dispatch(setSettingValue({
                            id: s.settingId,
                            value: event.target.value,
                          }))
                        }}
                      />
                    </ListItem>
                  )
                })}
              </Fragment>
            ))}
          </List>
        )}
        <ErrorDialog
          open={actionError !== undefined}
          title={actionError?.name || 'Error'}
          text={actionError?.message ?? ''}
          onOk={() => dispatch(clearActionError())}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading || saving}
          onClick={props.onCancel}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          disabled={loading || saving || failed}
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          {saving
            ? <CircularProgress size={20} />
            : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SettingsEditor

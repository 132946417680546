import {
  Fragment,
  type FunctionComponent,
  memo,
} from 'react'

import { useAppSelector } from 'app/store'
import {
  selectInitialsDialogState,
  type UnitsInitailsDialogState,
} from 'app/store/units/initialsDialogSlice'

import { API_ADDRESS } from 'shared/api/api'
import { type InitialsFile } from 'shared/model/contracts/InitialsFile'

import AuthorizedViewer from './AuthorizedViewer'

const DocumentPreview: FunctionComponent = () => {
  const dialogState: UnitsInitailsDialogState = useAppSelector(selectInitialsDialogState)

  if (!dialogState.previewId) {
    return <></>
  }

  const document: InitialsFile | undefined = dialogState.fileList?.find((f) => f.id === dialogState.previewId)

  return (
    <>
      {document && (
        <AuthorizedViewer
          contenttype={document.mimeType ?? ''}
          src={API_ADDRESS
            ? `${API_ADDRESS}/api/units/${dialogState.unit.unitFileId}/files/initials/${document.id}/file`
            : ''}
          style={{
            width: '100%',
            height: '80%',
          }}
        />
      )}
    </>
  )
}

export default memo(DocumentPreview)

import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'

import configurationsApi from 'shared/api/ConfigurationsApi'

import { type RootState } from '../store'

export interface UnitsSearchOptionsState {
  status: 'idle' | 'loading' | 'failed'
  maxQueryUnits: number
}

const sliceName = 'units-search-options'
const initialState: UnitsSearchOptionsState = {
  status: 'idle',
  maxQueryUnits: 1,
}

export const loadSearchOptions = createAsyncThunk(`${sliceName}/load`, async () => {
  return await configurationsApi.getSearchOptions()
})

const unitsSearchOptionsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(loadSearchOptions.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(loadSearchOptions.fulfilled, (state, action) => {
      state.status = 'idle'
      state.maxQueryUnits = action.payload.maxQueryUnits
    })

    build.addCase(loadSearchOptions.rejected, (state, action) => {
      state.status = 'failed'
    })
  },
})

export const selectSearchUnitsOptions = (state: RootState) => state.units.list.options

export default unitsSearchOptionsSlice.reducer

import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import z from 'zod'

import {type UnitTechnicalDescription} from 'entities/logistics/TechnicalCard/model/types'

import { setAuthTokenToHeaders } from 'shared/api/wretch/authorization'

import { UpdateImageData} from '../../entities/logistics/TechnicalCard/model/technicalCardsApi'

const unitPictureIDsScheme = z.number().gt(0)

export type UnitPictureID = z.infer<typeof unitPictureIDsScheme>

export const unitPicturesApi = createApi({
  reducerPath: 'unitPicturesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ADDRESS}/api/logistics/units`,
    prepareHeaders: async (headers: Headers) => await setAuthTokenToHeaders(headers),
  }),
  tagTypes: ['Picture'],
  endpoints: (builder) => ({
    getUnitPicture: builder.query<UnitPictureID[], {
      unitId: UnitTechnicalDescription['unitId']
      imageId: UnitPictureID
    }>({
      query: ({
        unitId,
        imageId,
      }) => {
        return `/${unitId}/images/${imageId}`
      },
      transformResponse: (response: UnitPictureID[]) => {
        unitPictureIDsScheme.parse(response)
        return response
      },
    }),
    deleteUnitPicture: builder.mutation <UpdateImageData, {
      unitId: UnitTechnicalDescription['unitId']
      imageId: UnitPictureID
      imageType: string
    }>({
      query: ({
        unitId,
        imageId,
        imageType,
      }) => ({
        url: `${unitId}/${imageType}/${imageId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

import {Box, Button, Tooltip } from '@mui/material'
import {useCallback, type FC} from 'react'

import {UnitTechnicalDescription} from "entities/logistics/TechnicalCard";

import {
  ModuleName,
  useFeatureSlicedDebug,
} from 'shared/lib/useFeatureSlicedDebug'
import {useMobileBreakpoint} from "shared/lib/useMobileBreakpoint";
import {themeSpacingFromPixels} from "shared/lib/workWithThemeSpacing";

import {useAppDispatch, useAppSelector} from "../../../../app/store";
import {currentGalleryElementId, isQaImageGalleryOpen, qaImageGallerySlice} from "../model/QaImageGallerySlice";

interface OpenQaImageGalleryProps {
  unit:UnitTechnicalDescription
  sx?: Record<string, unknown>
}

export const OpenQaImageGallery: FC<OpenQaImageGalleryProps> = ({
  sx,
  unit,
}) => {
  const {
    rootAttributes,
  } = useFeatureSlicedDebug('feature/OpenQaImageGallery' as ModuleName)

  const mobileBreakpoint = useMobileBreakpoint()
  const isGalleryOpen = useAppSelector(isQaImageGalleryOpen)
  const currentElementId = useAppSelector(currentGalleryElementId)
  const dispatch = useAppDispatch()

  const toggleGalleryView = useCallback(()=>{
    if (!isGalleryOpen) {
      dispatch(qaImageGallerySlice.actions.setIsOpen(unit))
    }
    if (isGalleryOpen && currentElementId === unit.elementId) {
      dispatch(qaImageGallerySlice.actions.setIsClosed())
    }

    if (isGalleryOpen) {
      dispatch(qaImageGallerySlice.actions.setIsClosed())
    }
  },[isGalleryOpen,dispatch])

  return (
    <Box
      {...rootAttributes}
      sx = {
        {...sx}
      }
    >
      <Tooltip title={'Get QA images'}>
        <Button
          onClick={toggleGalleryView}
          variant={'outlined'}
          sx={{
            width: mobileBreakpoint
              ? '100%'
              : themeSpacingFromPixels(100) ,
            height: themeSpacingFromPixels(40),
          }}
        >
          QA
        </Button>
      </Tooltip>
    </Box>
  )
}

OpenQaImageGallery.displayName = 'OpenQaImageGallery'

import {
  Delete,
  Edit,
} from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material'
import {
  MaterialReactTable,
  type MaterialReactTableProps,
  type MRT_Cell,
  type MRT_ColumnDef,
  type MRT_Row,
} from 'material-react-table'
import { useSnackbar } from 'notistack'
import {
  type FC,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { useAppDispatch } from 'app/store'

import {
  CreateNewCatalogRecord,
  type CreateRecordElement,
} from 'features/logistics/createNewCatalogRecord'
import { isNoDuplicates } from 'features/logistics/createNewCatalogRecord/lib/checkDuplicates'

import YesNoDialog from 'shared/ui/components/YesNoDialog'

import {
  UseCatalogCacheUpdater,
  UseCatalogCRUDApiHooks,
} from '../api/useCatalogCRUDApiHooks'
import {
  CatalogNames,
  type CatalogRecord,
} from '../model/types'

interface CatalogTableProps {
  name: CatalogNames
}

// TODO удалить все игнорирования тайпскрипт
export const CatalogTable: FC<CatalogTableProps> = ({
  name, 
}) => {
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [tableData, setTableData] = useState<CatalogRecord[]>(() => [])
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({})

  const cacheUpdater = UseCatalogCacheUpdater(name)

  const {
    enqueueSnackbar, 
  } = useSnackbar()

  const {
    getRecords,
    updateRecord,
    deleteRecord,
    createRecord,
  } = UseCatalogCRUDApiHooks(name)

  const dispatch = useAppDispatch()

  const {
    currentData,
    isSuccess,
    error,
  } = getRecords

  const [dialogDeleteElementOpen, setDialogDeleteElementOpen] = useState(false)
  const [dialogDeleteElementTitle, setDialogDeleteElementTitle] = useState('')
  const [rowToDelete, setRowToDelete] = useState<MRT_Row<CatalogRecord> | undefined>(undefined)
  const closeDialogDeleteElement = () => {
    setRowToDelete(undefined)
    setDialogDeleteElementOpen(false)
    setDialogDeleteElementTitle('')
  }

  const openDialogDeleteElement = () => {
    setDialogDeleteElementOpen(true)
    setDialogDeleteElementTitle('Catalog element removing')
  }

  if (error) {
    enqueueSnackbar('Connection problems', {
      variant: 'error',
      preventDuplicate: true,
    })
  }

  const isCanToSetStartData = isSuccess &&
    currentData &&
    currentData.length > 0 &&
    tableData.length === 0

  if (isCanToSetStartData) {
    setTableData(currentData)
  }

  const handleSaveRowEdits: MaterialReactTableProps<CatalogRecord>['onEditingRowSave'] =
    async ({
      exitEditingMode,
      row,
      values,
    }) => {
      if (!Object.keys(validationErrors).length) {

        if (!isNoDuplicates(currentData, values, isSuccess)) {
          enqueueSnackbar('Can`t save similar value in catalog', {
            variant: 'warning',
            preventDuplicate: true,
          })
          return
        }

        const trimmedValue = values.value.trim()
        // @ts-ignore
        updateRecord({
          ...values,
          value: trimmedValue,
        }).unwrap().then((result: unknown) => {
          // @ts-ignore
          if (result?.error) {
            enqueueSnackbar('Error on updating data', {
              variant: 'error',
              preventDuplicate: true,
            })
          }
          // @ts-ignore
          if (result) {
            // @ts-ignore
            dispatch(cacheUpdater((draft) => {
              draft.splice(row.index, 1, values)
            }))
            // @ts-expect-error
            setTableData(tableData.toSpliced(row.index, 1, values))
            exitEditingMode()
            enqueueSnackbar('Successful updated', {
              variant: 'success',
              preventDuplicate: true,
            })
          }
        })
          .catch(() => {
            enqueueSnackbar('Error on updating data', {
              variant: 'error',
              preventDuplicate: true,
            })
          })
      }
    }

  const handleCancelRowEdits = () => {
    setValidationErrors({})
  }

  const handleCreateNewRow = (data: CreateRecordElement) => {
    // @ts-ignore
    createRecord(data).unwrap().then((result: unknown) => {
      // @ts-ignore
      if (result?.error) {
        enqueueSnackbar('Error on create data', {
          variant: 'error',
          preventDuplicate: true,
        })
      }
      // @ts-ignore
      if (result) {
        // @ts-ignore
        dispatch(cacheUpdater((draft) => {
          draft.push(result)
        }))
        // @ts-ignore
        setTableData([...tableData, result])
        enqueueSnackbar('Successful create new record', {
          variant: 'success',
          preventDuplicate: true,
        })
      }
    }).catch((e: unknown) => {
      enqueueSnackbar('Error on create data', {
        variant: 'error',
        preventDuplicate: true,
      })
    })
  }

  const handleDeleteRow = useCallback(() => {
    const row: MRT_Row<CatalogRecord> | undefined = rowToDelete
    if (typeof row === 'undefined') return

    // @ts-ignore
    deleteRecord(row.getValue('id')).unwrap().then(result => {
      if (result?.error) {
        enqueueSnackbar('Error on data remove', {
          variant: 'error',
          preventDuplicate: true,
        })
      }
      // @ts-ignore
      if (result) {
        // @ts-ignore
        dispatch(cacheUpdater((draft) => {
          draft.splice(row.index, 1)
        }))
        // @ts-expect-error
        setTableData(tableData.toSpliced(row.index, 1))
        enqueueSnackbar('Successful removed', {
          variant: 'success',
          preventDuplicate: true,
        })
      }
    }).catch(() => {
      enqueueSnackbar('Error on data remove', {
        variant: 'error',
        preventDuplicate: true,
      })
    })
  },
  [tableData, rowToDelete],
  )

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<CatalogRecord>,
    ): MRT_ColumnDef<CatalogRecord>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value)
          if (!isValid) {
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            })
          } else {
            delete validationErrors[cell.id]
            setValidationErrors({...validationErrors})
          }
        },
      }
    },
    [validationErrors],
  )

  const columns = useMemo<Array<MRT_ColumnDef<CatalogRecord>>>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, // disable editing on this column
        enableSorting: false,
      },
      {
        accessorKey: 'value',
        header: 'Value',
        muiTableBodyCellEditTextFieldProps: ({
          cell, 
        }) => ({...getCommonEditTextFieldProps(cell)}),
      },
    ],
    [getCommonEditTextFieldProps],
  )

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {align: 'right'},
            size: 1,
          },
        }}
        initialState={{ columnVisibility: { id: false } }}
        positionActionsColumn="last"
        columns={columns}
        data={tableData}
        editingMode="modal" // default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({
          row,
          table,
        }) => (
          <Box sx={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'flex-end',
          }}
          >
            <Tooltip
              arrow
              placement="left"
              title="Edit"
            >
              <IconButton onClick={() => {
                table.setEditingRow(row)
              }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip
              arrow
              placement="right"
              title="Delete"
            >
              <IconButton
                color="error"
                onClick={() => {
                  setRowToDelete(row)
                  openDialogDeleteElement()
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            onClick={() => {
              setCreateModalOpen(true)
            }}
            variant="contained"
          >
            Create new catalog record
          </Button>
        )}
      />
      <CreateNewCatalogRecord
        columns={columns}
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false)
        }}
        onSubmit={handleCreateNewRow}
        catalogName={CatalogNames[name]}
      />
      <YesNoDialog
        open={dialogDeleteElementOpen}
        title={dialogDeleteElementTitle}
        onNo={closeDialogDeleteElement}
        onYes={() => {
          handleDeleteRow()
          setDialogDeleteElementOpen(false)
        }}
      >
        {'Are you sure you want to delete this item?'}
      </YesNoDialog>
    </>
  )
}

const validateRequired = (value: string) => !!value.length

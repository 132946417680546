import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import {
  type FC,
  type ReactNode,
} from 'react'

import { logisticsTheme } from 'app/config/theme/logisticsTheme'
import { useAppSelector } from 'app/store/hooks'

import {
  CheckFields,
  descriptionFieldChecksSelectors,
  type DocumentFieldCheck,
} from 'features/logistics/downloadTechnicalDescriptions'
import {
  type ImageAction,
  ImageActionMenu,
} from 'features/logistics/unitDescription/editImageList'
import { DeletePicture } from 'features/logistics/units/deletePicture/ui/DeletePicture'

import { useGetUnitApproveState } from 'shared/lib/logistics'

import {
  TechnicalCardApproveState,
  type UnitTechnicalDescription,
} from '../index'

interface ImagesListProps {
  listName: string
  unitId: UnitTechnicalDescription['unitId']
  images: UnitTechnicalDescription['images']
  imageMenuActions?: ImageAction[]
  titleActionsSlot?: ReactNode[]
  isDisabled?: boolean
}

function useIsNeedImage (unitId: number, listName: string): boolean {
  const errorInFields: DocumentFieldCheck | undefined = useAppSelector(state => descriptionFieldChecksSelectors.selectById(state, unitId))
  const isImageContentCorrect = Boolean(errorInFields?.fieldCheckCorrect[CheckFields.images])
  return !isImageContentCorrect && listName.toLowerCase() === String(CheckFields.images).toLowerCase()
}

export const ImageList: FC<ImagesListProps> = ({
  unitId,
  listName,
  images,
  imageMenuActions,
  titleActionsSlot,
  isDisabled,
}) => {

  const isNeedImage = useIsNeedImage(unitId||0, listName||'')
  const approvalState = useGetUnitApproveState(unitId||0)
  const isApproved = approvalState === TechnicalCardApproveState.Approved
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        alignItems="center"
      >
        {titleActionsSlot}
        {isNeedImage &&
          <ErrorOutlineIcon htmlColor={logisticsTheme.palette.warning.main} />}
      </Stack>

      {images?.map((image) => (
        <Stack
          key={image.id}
          direction="row"
          alignItems={"space-between"}
        >
          <Typography variant="body1">{image.name}</Typography>
          <DeletePicture
            unitId={unitId}
            imageId={image?.id||0}
            imageType={listName}
            imageName={image?.name||''}
            isDisabled={isApproved || isDisabled}
          />
          {imageMenuActions && <ImageActionMenu imageActions={imageMenuActions} />}
        </Stack>
      ))||[]}
    </Stack>
  )
}

import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import settingsApi from 'shared/api/SettingsApi'
import { type AppSettingDto } from 'shared/model/contracts/SettingsDto'

import { type RootState } from '../store'

export interface AppSettingsState {
  status: 'idle' | 'loading' | 'saving' | 'failed'
  settings: Setting[]
  pageError?: SerializedError
  actionError?: SerializedError
  openEditSettingsDialog: boolean
}

export interface EditSettingsState {
  status: 'idle' | 'loading' | 'saving' | 'failed'
  settings: Setting[]
  pageError?: SerializedError
  actionError?: SerializedError
}

export interface Setting {
  settingId: number
  name: string
  value: string
  description: string
  grouping: string
}

const initialState: AppSettingsState = {
  status: 'loading',
  settings: [],
  openEditSettingsDialog: false,
}

export const loadSettings = createAsyncThunk('settings/load', async () => {
  return await settingsApi.getSettings()
})

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    showActionError: (state, action: PayloadAction<Error>) => {
      state.actionError = action.payload
    },
    hideActionError: (state) => {
      state.actionError = undefined
    },
    showEditSettingsDialog: (state) => {
      state.openEditSettingsDialog = true
    },
    hideEditSettingsDialog: (state) => {
      state.openEditSettingsDialog = false
    },
    setSettings: (state, action: PayloadAction<AppSettingDto[]>) => {
      state.settings = action.payload?.map((s) => {
        return {
          settingId: s.settingId,
          name: s.name,
          value: s.value,
          description: s.description,
          grouping: s.grouping,
        } as Setting
      })
    },
    setActionError: (state, action: PayloadAction<Error>) => {
      state.actionError = action.payload
    },
    clearActionError: (state) => {
      state.actionError = undefined
    },
  },
  extraReducers: (build) => {
    build.addCase(loadSettings.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(loadSettings.fulfilled, (state, action) => {
      state.status = 'idle'
      state.settings = action.payload.map((s) => {
        return {
          settingId: s.settingId,
          name: s.name,
          value: s.value,
          description: s.description,
          grouping: s.grouping,
        } as AppSettingDto
      })
    })

    build.addCase(loadSettings.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })
  },
})

export const {
  showActionError,
  hideActionError,
  showEditSettingsDialog,
  hideEditSettingsDialog,
  setSettings,
  setActionError,
  clearActionError,
} = settingsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSettings = (state: RootState) => state.administration.settings.settings

export default settingsSlice.reducer

import {
  createAsyncThunk,
  createSlice,
  type SerializedError,
} from '@reduxjs/toolkit'

import meApi from 'shared/api/MeApi'
import { type MeDto } from 'shared/model/contracts/MeDto'

import { type RootState } from '../store'

export interface MeState {
  status: 'idle' | 'loading' | 'failed'
  me?: MeDto
  error?: SerializedError
}

const initialState: MeState = {status: 'idle'}

export const loadMe = createAsyncThunk('users/load', async () => {
  return await meApi.getUser()
})

export const meSlice = createSlice({
  name: 'me-slice',
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(loadMe.pending, (state, action) => {
      state.status = 'loading'
    })
    build.addCase(loadMe.fulfilled, (state, action) => {
      state.status = 'idle'
      state.me = action.payload
    })
    build.addCase(loadMe.rejected, (state, action) => {
      state.status = 'failed'
      state.me = undefined
      state.error = action.error
    })
  },
})

// export const {} = userSlice.actions;

export const selectMe = (state: RootState) => state.user
export const checkPermission = (state: RootState, permission: string) => {
  const me = state.user.me
  if ((me == null) || !me.rights) {
    return false
  }

  return me.rights.includes(permission)
}

export const checkOptions = (state: RootState, option: string) => {
  const me = state.user.me
  if ((me == null) || !me.options) {
    return false
  }

  return me.options.includes(option)
}

export default meSlice.reducer

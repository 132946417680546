import {
  Grid,
  Typography,
} from '@mui/material'
import { type FunctionComponent } from 'react'

import { selectSettings, type AppSettingsState } from 'app/store/administration/settingsSlice'
import { useAppSelector } from 'app/store/hooks'

import { SettingsMaintenanceWarningText } from 'shared/api/settings'

const MaintenancePage: FunctionComponent = () => {
  const settingsState: AppSettingsState = useAppSelector(selectSettings)

  let isUnderMaintenanceText = 'Service is under maintenance'

  if (settingsState) {
    const underMaintenanceSettings = settingsState.settings.filter(
      (s) => s.name === SettingsMaintenanceWarningText,
    )[0]
    if (underMaintenanceSettings) {
      isUnderMaintenanceText = underMaintenanceSettings.value
    }
  }

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '90vh' }}
    >
      <Grid item>
        <Typography
          variant="h1"
          align="center"
        >
          403
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h4"
          align="center"
        >
          {isUnderMaintenanceText}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default MaintenancePage

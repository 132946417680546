import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import optionsApi from 'shared/api/administration/OptionsApi'
import rolesApi from 'shared/api/administration/RolesApi'
import { type OptionDto } from 'shared/model/contracts/administration/Options'

import { type RootState } from '../store'

import { type Option } from './roleSlice'

export interface EditRoleOptionsState {
  status: 'idle' | 'loading' | 'saving' | 'failed'
  systemOptions: OptionDto[]
  roleOptions: number[]
  dialogError?: SerializedError
  actionError?: SerializedError
}

const initialState: EditRoleOptionsState = {
  status: 'idle',
  systemOptions: [],
  roleOptions: [],
}

export const loadOptions = createAsyncThunk('edit-role-options/load', async () => {
  return await optionsApi.getOptions()
})

export const saveRoleOptions = createAsyncThunk(
  'edit-role-options/save',
  async (data: { roleId: number | string, options: number[] }) => {
    return await rolesApi.saveOptions(data.roleId, data.options)
  },
)

export const editRoleOptionsSlice = createSlice({
  name: 'edit-role-options',
  initialState,
  reducers: {
    setActionError: (state, action: PayloadAction<Error>) => {
      state.actionError = action.payload
    },
    clearActionError: (state) => {
      state.actionError = undefined
    },
    setRoleOptions: (state, action: PayloadAction<number[]>) => {
      state.roleOptions = [...action.payload]
    },
    toggleRoleOptions: (state, action: PayloadAction<number>) => {
      const currentIndex = state.roleOptions.indexOf(action.payload) ?? -1
      const newChecked = [...state.roleOptions]
      if (currentIndex === -1) {
        newChecked.push(action.payload)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      state.roleOptions = [...newChecked]
    },
  },
  extraReducers: (build) => {
    build.addCase(loadOptions.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(loadOptions.fulfilled, (state, action) => {
      state.status = 'idle'
      state.systemOptions = action.payload.map((o) => {
        return {
          optionId: o.optionId,
          name: o.name,
          description: o.description,
          grouping: o.grouping,
        } as Option
      })
    })

    build.addCase(loadOptions.rejected, (state, action) => {
      state.status = 'failed'
      state.dialogError = action.error
    })

    build.addCase(saveRoleOptions.pending, (state, action) => {
      state.status = 'saving'
    })

    build.addCase(saveRoleOptions.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(saveRoleOptions.rejected, (state, action) => {
      state.status = 'idle'
      state.actionError = action.error
    })
  },
})

export const {
  setActionError,
  setRoleOptions,
  toggleRoleOptions,
  clearActionError,
} = editRoleOptionsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEditRoleOptionsDialog = (state: RootState) => state.administration.roles.edit.editOptions

export default editRoleOptionsSlice.reducer

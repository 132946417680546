import {
  Button,
  Dialog,
} from '@mui/material'
import Uppy, { type UppyOptions } from '@uppy/core'
// @ts-ignore
import en_US from '@uppy/locales/lib/en_US'
import { Dashboard } from '@uppy/react'
import XHR, { type XHRUploadOptions } from '@uppy/xhr-upload'
import { useSnackbar } from 'notistack'
import type React from 'react'
import {
  type FC,
  useId,
  useMemo,
} from 'react'

import '@uppy/react/lib/FileInput.js'
import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import { useAppDispatch } from 'app/store'

import {
  isUpdateImageData,
  isUpdateSchemesData,
  technicalCardCacheUpdate,
} from 'entities/logistics/TechnicalCard'

import authService from 'shared/api/AuthService'

interface DropzoneProps {
  UppyInOptions?: UppyOptions
  XHRInOptions?: XHRUploadOptions
  isOpen: boolean
  setIsOpen: React.Dispatch<boolean>
  dropzoneId: string
  invalidateData: () => void
}

const authHeaders = async () => {
  const token = await authService.getIdentity()
  return {Authorization: `Bearer ${token.accessToken}`}
}

// @ts-ignore
export const DropzoneUnitPictures: FC<DropzoneProps> = ({
  UppyInOptions,
  XHRInOptions,
  setIsOpen,
  isOpen,
  dropzoneId,
  invalidateData,
}) => {

  const dispatch = useAppDispatch();
  const uppyInstanceID = useId()
  const {
    enqueueSnackbar,
  } = useSnackbar()

  const uppy = useMemo(() => {

    if (!XHRInOptions?.endpoint) {
      throw new Error('Endpoint is required')
    }

    const uppyInstanse = new Uppy(
      {
        id: uppyInstanceID,
        restrictions: {
          allowedFileTypes: [
            '.jpg',
            '.jpeg',
            '.png',
            '.tif',
            '.tiff',
            '.bmp',
            '.gif',
          ],
          maxFileSize: 5000000,
        },
        locale: {
          strings: {
            ...en_US.strings,
            dropPasteFiles: 'Drop files here or %{browseFiles}, maximum allowed size of file - 5Mb ',
          },
        },
      }).use(XHR, {
      endpoint: XHRInOptions?.endpoint ?? '',
      getResponseData (responseText,response) {
        const parsed = JSON.parse(responseText);
        if (isUpdateImageData(parsed)) {

          technicalCardCacheUpdate(dispatch, parsed.unitId, {images: parsed.images})
        }
        if (isUpdateSchemesData(parsed)) {
          technicalCardCacheUpdate(dispatch, parsed.unitId, {schemes: parsed.schemes})
        }
        else {
          // todo handle parse error
        }
        setIsOpen(false)
        return response
      },
    })

    if (UppyInOptions) {
      uppyInstanse.setOptions(UppyInOptions)
    }

    if (XHRInOptions) {
      authHeaders().then(curHeaders => {
        // @ts-ignore
        uppyInstanse.getPlugin('XHRUpload').setOptions({
          headers: curHeaders,
          ...XHRInOptions,
        })
      })
    }

    return uppyInstanse
  }, [UppyInOptions, XHRInOptions])

  if (!uppy) {
    enqueueSnackbar('Error on creating dropzone for files', {
      variant: 'error',
      preventDuplicate: true,
    })
    return (
      <>
        Error on creating dropzone for files
      </>
    )
  }

  if (uppy) {
    return (
      <Dialog open={isOpen}>
        <Dashboard
          id={dropzoneId}
          closeModalOnClickOutside={true}
          doneButtonHandler={() => {
            setIsOpen(false)
            invalidateData()
          }}
          uppy={uppy}
          plugins={['XHR']}
        />
        <Button onClick={() => {
          setIsOpen(false)
        }}
        >Close</Button>
      </Dialog>
    )
  }
}

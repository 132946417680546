import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import {
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { type FC } from 'react'
import {
  Controller,
  type UseFormResetField,
} from 'react-hook-form'

interface RhmTextFieldProps {
  name: string
  control: any
  value: any
  resetField: UseFormResetField<any>
  setIsEditable: (arg: boolean) => void
}

export const RhmTextField: FC<RhmTextFieldProps> = (props) => {

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.value}
      render={({
        field: {
          onChange,
          value,
        },
      }) => (
        <>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    props.setIsEditable(false)
                    props.resetField('valueData')
                  }}
                >
                  <ClearOutlinedIcon fontSize="small" />
                </IconButton>
              </InputAdornment>,
            }}
            size="small"
            sx={{
              height: '14px',
              paddingLeft: '12px',
            }}
            onChange={onChange}
            value={value}
          />

        </>)}
    />
  )
}

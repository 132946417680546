import { combineReducers } from '@reduxjs/toolkit'

import editOptions from './editRoleOptionsSlice'
import editRights from './editRoleRightsSlice'
import editSettings from './editSettingsSlice'
import editRole from './roleSlice'
import rolesList from './rolesListSlice'
import selectUsers from './selectRoleUsersSlice'
import settings from './settingsSlice'
import usersList from './usersListSlice'

export const administration = combineReducers({
  users: combineReducers({list: usersList}),
  roles: combineReducers({
    list: rolesList,
    edit: combineReducers({
      editRole,
      selectUsers,
      editRights,
      editOptions,
    }),
  }),
  settings: combineReducers({
    settings,
    editSettings,
  }),
})

import {
  ApiNames,
  CatalogApiMethods,
  CatalogApis,
} from '../model/types'

export const UseCatalogCRUDApiHooks = (apiName: ApiNames) => {
  const {
    currentData,
    isSuccess,
    error,
  } = CatalogApis[apiName].endpoints[CatalogApiMethods[apiName].getMany].useQuery(undefined)

  const [updateRecord] = CatalogApis[apiName].endpoints[CatalogApiMethods[apiName].update].useMutation()
  const [deleteRecord] = CatalogApis[apiName].endpoints[CatalogApiMethods[apiName].delete].useMutation()
  const [createRecord] = CatalogApis[apiName].endpoints[CatalogApiMethods[apiName].create].useMutation()

  return {
    getRecords: {
      currentData,
      isSuccess,
      error,
    },
    updateRecord,
    deleteRecord,
    createRecord,
  }
}

export const UseCatalogCacheUpdater = (apiName: ApiNames) => {
  // @ts-ignore
  return (recipe) => CatalogApis[apiName].util.updateQueryData(CatalogApiMethods[apiName].getMany, undefined, recipe)
}

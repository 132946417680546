import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from '../store'

export interface UnitsErrorsState {
  errorsQueue: ErrorMessage[]
}

export interface ErrorMessage {
  message: string
  type?: string
}

const initialState: UnitsErrorsState = { errorsQueue: [] }

export const unitsErrorsSlice = createSlice({
  name: 'units-errors',
  initialState,
  reducers: {
    addError: (state, action: PayloadAction<ErrorMessage>) => {
      const error = action.payload

      const errorsArr = state.errorsQueue.map((e) => e.message)
      if (!errorsArr.includes(error.message)) {
        state.errorsQueue.push(error)
      }
    },
    removeError: (state, action: PayloadAction<string>) => {
      const errorMessage = action.payload

      const errorsArr = state.errorsQueue.map((e) => e.message)
      if (!errorsArr.includes(errorMessage)) {
        state.errorsQueue.shift()
      }
    },
    clearErrorsQueue: (state) => {
      state.errorsQueue = []
    },
  },
})

export const {
  addError, clearErrorsQueue, 
} = unitsErrorsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUnitsErrors = (state: RootState) => state.units.list.errors

export default unitsErrorsSlice.reducer
